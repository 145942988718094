import UserConversation from "components/chat/UserConversation";
import PageComponent from "components/common/PageComponent";

const ChatPage = () => {
  return (
    <PageComponent>
      <PageComponent.Header>
        <></>
      </PageComponent.Header>
      <PageComponent.Content>
        <UserConversation />
      </PageComponent.Content>
    </PageComponent>
  );
};

export default ChatPage;
