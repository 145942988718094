import PageComponent from "components/common/PageComponent";
import useSession from "hooks/useSession";

const PageNotFound = () => {
  const session = useSession();
  return (
    <PageComponent>
      <PageComponent.Content>
        <div className="text-center py-24 px-8 h-full">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href={session.isAuthenticated ? "/overview" : "/login"}
              className="text-sm font-semibold text-gray-900"
            >
              Go to Home <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default PageNotFound;
