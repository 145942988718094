//NOTE: please keep this up-to-date with admin's admin/src/constants/enums/sjs-custom-question.ts file
export enum SjsCustomQuestion {
  coauthors = "coauthors",
  cospeakers = "cospeakers",
  degree_picker = "degree_picker",
  events_attended = "events_attended",
  login_details = "login_details",
  profile_search = "profile_search",
  profile_type = "profile_type",
  profileinstitutiontype = "profileinstitutiontype",
  multiple_choice_products = "multiple_choice_products",
  single_choice_products = "single_choice_products",
  profileaddresscontactinformation = "profileaddresscontactinformation",
}
