import { ApiResponse, TokenView } from "@parthenon-management/pillar-types";
import { ApiQueryConfig } from "api/types";
const VerifyToken: ApiQueryConfig<
  ApiResponse<TokenView>,
  { societyId: string; tokenValue: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/token/{tokenValue}",
  queryKey: {
    baseQueryKey: ["VerifyToken"],
    dynamicQueryKey: ["tokenValue"],
  },
};

export { VerifyToken };
