//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const combineDateAndTime = (dateStr: any, timeStr: any) => {
  const date = dateStr.split("-");

  if (typeof timeStr !== "string") {
    timeStr = timeStr.toFixed(2).toString();
  }
  const time = timeStr.replace(".", ":");

  date.push(time);
  const test = new Date(date);

  return test;
};
