import { ComponentPropsWithoutRef, ReactNode } from "react";

type MainContentWrapperProps = ComponentPropsWithoutRef<"div"> & {
  children: ReactNode;
};

const MainContentWrapper = ({
  children,
  ...props
}: MainContentWrapperProps) => {
  return <div {...props}>{children}</div>;
};

export default MainContentWrapper;
