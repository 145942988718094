import { useState } from "react";
import { PillarPDF } from "types/PDF";
import { createPDFDownloadLink } from "utils/pdf/createPDFDownloadLink";
import { useHistory } from "react-router";
import useDeviceInfo from "./useDeviceInfo";

//eslint-disable-next-line @typescript-eslint/no-explicit-any
type PDFFetchCallbackFn<T extends any[]> = (...args: T) => Promise<PillarPDF>;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePDFDownload = <T extends any[]>(
  pdfFetchCallbackFn: PDFFetchCallbackFn<T>,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const device = useDeviceInfo();

  const downloadPDF = async (...args: T) => {
    try {
      setIsLoading(true);
      const resp = await pdfFetchCallbackFn(...args);

      if (device.isNativeApp) {
        // For native app, don't download, just show on the page
        // Convert BufferJson to base64 string
        const base64String = btoa(String.fromCharCode(...resp.pdfBuffer.data));
        const dataUrl = `data:application/pdf;base64,${base64String}`;

        history.push(`/pdf-viewer/${encodeURIComponent(dataUrl ?? "")}`);
        return;
      }

      createPDFDownloadLink(resp);
    } catch (error) {
      console.error(error);
      setError("There was an error downloading the PDF");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    downloadPDF,
    isLoading,
    error,
  };
};

export default usePDFDownload;
