import axios, { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { ToastStyle, showToast } from "utils/toast";

let apiClient: AxiosInstance | null = null;

let requestInterceptor: number | null = null;

let responseInterceptor: number | null = null;

const buildApiClient = () => {
  if (!apiClient) {
    apiClient = axios.create();
    apiClient.defaults.baseURL = process.env.REACT_APP_PILLAR_API_URL;
    apiClient.defaults.withCredentials = true;

    if (!requestInterceptor) {
      requestInterceptor = apiClient.interceptors.request.use(
        (config) => config,
        (error) => {
          showToast(
            "An Error Occurred. Please Check Your Internet Connection and Try Again.",
            ToastStyle.Error,
          );
          return Promise.reject(error);
        },
      );
    }

    if (!responseInterceptor) {
      responseInterceptor = apiClient.interceptors.response.use(
        (response) => response,
        (error) => {
          switch (error.response.status) {
            case 401:
              showToast(
                "Your authentication has expired. Please log in again.",
                ToastStyle.Error,
              );
              break;
            case 403:
              showToast(
                "You are not authorized for that action. Contact your administrator.",
                ToastStyle.Error,
              );
              break;
            default:
              showToast(
                "An Error Occurred. Please Check Your Internet Connection and Try Again.",
                ToastStyle.Error,
              );
              break;
          }
          return Promise.reject(error);
        },
      );
    }
  }
};

const useApiClient = () => {
  const [statedApiClient, setApiClient] = useState<AxiosInstance | null>(
    apiClient,
  );

  useEffect(() => {
    buildApiClient();
    setApiClient(apiClient);

    return () => {
      if (requestInterceptor && responseInterceptor) {
        apiClient?.interceptors.request.eject(requestInterceptor);
        apiClient?.interceptors.response.eject(responseInterceptor);
      }
    };
    // We do not need exhaustive deps. This should run once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return statedApiClient;
};

export { apiClient, buildApiClient, useApiClient };
