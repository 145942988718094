import { ApiMutationConfig, ApiQueryConfig } from "@api";
import {
  ApiResponse,
  InvoiceLiteView,
  InvoiceView,
  PaginatedSumResults,
} from "@parthenon-management/pillar-types";
import { NewInvoice } from "types/Invoice";

const GetInvoices: ApiQueryConfig<
  ApiResponse<PaginatedSumResults<InvoiceLiteView>>,
  {
    societyId: string;
    profileId: string;
  },
  {
    status?: string[];
    productType?: number[];
  }
> = {
  queryKey: {
    baseQueryKey: ["GetInvoices"],
    dynamicQueryKey: ["status", "productType", "profileId"],
  },
  apiPath: "/api/v1/society/{societyId}/profiles/{profileId}/invoices",
};

const CreateInvoice: ApiMutationConfig<
  ApiResponse<InvoiceView>,
  { societyId: string; profileId: string },
  NewInvoice
> = {
  apiPath: "api/v1/society/{societyId}/accounting/invoices/{profileId}/new",
  method: "POST",
  invalidates: [{ baseQueryKey: GetInvoices.queryKey.baseQueryKey }],
};

export { GetInvoices, CreateInvoice };
