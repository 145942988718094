import "survey-core/defaultV2.min.css";
import {
  ChoicesRestful,
  Serializer,
  SurveyModel,
  ChoicesLazyLoadEvent,
} from "survey-core";
import { SocietyView } from "@parthenon-management/pillar-types";
import { fetchApiQuery } from "@api";
import { GetSocietyCustomChoices } from "api/routes/custom-choices";
import { getSocietyId } from "utils/getSocietyId";
import { debounce } from "lodash";

export const setupChoicesFromPillar = (
  society: SocietyView,
  surveyModel: SurveyModel,
): void => {
  const eagerLoadDropdownConfig = {
    name: "pillar_choices_question_name",
    displayName: "Choice Name",
    type: "dropdown",
    choices: society.choiceCategory.map(
      (category) => category.question_name ?? "",
    ),
    category: "Choices from Pillar",
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSetValue: (surveyElement: any, value: any) => {
      const result = {
        url: `${process.env.REACT_APP_PILLAR_API_URL}/api/v1/society/${society.id}/choices/${value}`, // A RESTful service's URL.
        valueName: "text", // Specifies which field contains choice values.
        titleName: "text", // Specifies which field contains display texts for choice values.
        path: "body,results",
      };
      surveyElement.setPropertyValue("pillar_choices_question_name", value);

      const choicesByUrl: ChoicesRestful =
        surveyElement.getPropertyValue("choicesByUrl");

      choicesByUrl.url = result.url;
      choicesByUrl.valueName = result.valueName;
      choicesByUrl.titleName = result.titleName;
      choicesByUrl.path = result.path;
    },
  };
  const lazyLoadDropdownConfig = {
    name: "pillar_choices_question_name",
    displayName: "Choice Name",
    type: "dropdown",
    choices: society.choiceCategory.map(
      (category) => category.question_name ?? "",
    ),
    category: "Choices from Pillar",
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSetValue: (surveyElement: any, value: any) => {
      surveyElement.setPropertyValue("pillar_choices_question_name", value);
      surveyElement.setPropertyValue("choicesLazyLoadEnabled", true);
    },
  };

  Serializer.addProperty("checkbox", eagerLoadDropdownConfig);
  Serializer.addProperty("tagbox", eagerLoadDropdownConfig);
  Serializer.addProperty("radiogroup", eagerLoadDropdownConfig);
  Serializer.addProperty("rating", eagerLoadDropdownConfig);
  Serializer.addProperty("dropdown", lazyLoadDropdownConfig);
  Serializer.addProperty("matrixdropdowncolumn", lazyLoadDropdownConfig);

  const setChoices = async (
    options: ChoicesLazyLoadEvent,
    choiceCategory: string,
  ) => {
    const choices = await fetchApiQuery(
      GetSocietyCustomChoices,
      {
        societyId: getSocietyId().toString(),
        choiceQuestionName: choiceCategory,
      },
      {
        pageSize: String(options.take),
        page: String(options.skip / options.take + 1), // adding one here since Pillar starts page counts at 1 and sjs keeps track of rows, not pages
        text: options.filter,
      },
    );

    const items = choices.data.body?.results.map((choice) => choice.text) ?? [];
    options.setItems(items, choices.data.body?.totalResults ?? 0);
  };

  const _debouncedSetChoices = debounce(setChoices, 1000);

  surveyModel?.onChoicesLazyLoad.add(async (sender, options) => {
    const choiceCategory = options.question.getPropertyValue(
      "pillar_choices_question_name",
    );
    if (!choiceCategory) {
      //Not a pillar choice, so we don't need to do anything
      return;
    }
    options.setItems([], 0);
    _debouncedSetChoices(options, choiceCategory);
  });

  ChoicesRestful.onBeforeSendRequest = (sender, options) => {
    options.request.withCredentials = true;
  };
};
