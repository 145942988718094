import useSession from "hooks/useSession";
import { ComponentPropsWithoutRef } from "react";

type SocietyLogoProps = {
  type?: "login" | "banner";
} & ComponentPropsWithoutRef<"div">;

const SocietyLogo = ({ className, type, ...props }: SocietyLogoProps) => {
  const session = useSession();

  return (
    <div
      className={`society-image w-4.5 h-4.5 ${className ?? ""}`}
      style={{
        background:
          type === "banner"
            ? `url("${session.society?.societySettingsPublic?.societyLogoBannerUrl}")`
            : `url("${session.society?.societySettingsPublic?.societyLogoLoginUrl}")`,
        backgroundSize: "cover",
      }}
      {...props}
    />
  );
};

export { SocietyLogo };
