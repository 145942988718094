import {
  ApiResponse,
  DirectoryWithProfilesView,
} from "@parthenon-management/pillar-types";
import { PaginationRequest } from "@parthenon-management/pillar-types/dist/src/ui/types/common/general-types";

import { FiltersRequest } from "@parthenon-management/pillar-types/dist/src/utils/helpers/filter-where-clause";
import { ApiQueryConfig } from "api/types";

const GetDirectory: ApiQueryConfig<
  ApiResponse<DirectoryWithProfilesView>,
  { societyId: string; directoryId: string },
  FiltersRequest & PaginationRequest
> = {
  apiPath: "/api/v1/society/{societyId}/directory/{directoryId}",
  queryKey: {
    baseQueryKey: ["GetDirectory"],
    dynamicQueryKey: ["societyId", "directoryId"],
  },
};

export { GetDirectory };
