/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  ItemValue,
  Question,
  QuestionCompositeModel,
  QuestionRadiogroupModel,
  Serializer,
  SurveyModel,
} from "survey-core";

import { IProfileProductQuestion, selectedProducts } from "./iquestion";
import {
  ProductView,
  SessionLiteView,
} from "@parthenon-management/pillar-types";
import { SjsCustomQuestion } from "@components/surveyJS/types/sjs-custom-question";

export const single_choice_products = async (
  products?: ProductView[],
  tags?: string[],
  session?: SessionLiteView,
) => {
  const products_list =
    products && products.length > 0
      ? products.map((product) => ({
          value: product.id,
          text: product.name,
          price: product.price,
          name: product.name,
        }))
      : [];

  const question_name = SjsCustomQuestion.single_choice_products;
  const products_question_object: ICustomQuestionTypeConfiguration &
    IProfileProductQuestion &
    any = {
    name: question_name,
    title: "Single Choice Products",
    elementsJSON: [
      {
        type: "radiogroup",
        name: "products",
        title: " ",
        items: [],
      },
      {
        type: "text",
        name: "price_override",
        title: "Enter Price",
        visible: false,
      },
    ],
    onLoaded(question: Question) {
      question.getPropertyByName("product_choices").setChoices(products_list);
      Serializer.getProperty(question_name, "name").visible = false;
      const selectedProducts =
        products_list?.filter((product) =>
          Serializer.getObjPropertyValue(question, "product_choices").includes(
            product.value,
          ),
        ) ?? [];

      question.contentPanel.getQuestionByName("products").choices =
        selectedProducts;
      // console.log(question.contentPanel.getQuestionByName("products").value);
      this.addCustomPriceQuestion(question, selectedProducts);
      this.showProductPrice(question, selectedProducts);
      this.addProductDescription(question, selectedProducts);
      this.setProductProperties(selectedProducts);
      this.setProductChoices(question, selectedProducts);
    },
    onAfterRender(question: Question) {
      const selectedProducts =
        products_list?.filter((product) =>
          Serializer.getObjPropertyValue(question, "product_choices").includes(
            product.value,
          ),
        ) ?? [];
      this.setProductChoices(question, selectedProducts);
    },
    onInit() {
      Serializer.addProperty(question_name, {
        name: "product_choices",
        type: "multiplevalues",
        visible: true,
        category: "Products",
      });
      Serializer.addProperty(question_name, {
        name: "filterChoicesByGeocode",
        displayName: "Filter Choices By Postal Code?",
        type: "boolean",
        visible: true,
        default: false,
        category: "Products",
      });
      for (const product of products_list ?? []) {
        Serializer.addProperty(question_name, {
          name: `${product.value}_price_override`,
          type: "boolean",
          default: false,
          displayName: "Override Price",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_show_price`,
          type: "boolean",
          default: product.price,
          displayName: "Show Price",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_add_description`,
          type: "text",
          displayName: "Description",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_change_price`,
          type: "number",
          default: product.price,
          displayName: "Change Price",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_change_price`,
          type: "number",
          default: product.price,
          displayName: "Change Price",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_tags`,
          type: "multiplevalues",
          displayName: "Tag Conditions",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
          choices: tags,
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_disable_on_edit`,
          type: "boolean",
          default: false,
          displayName: "Disable on Edit",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_visibility_start_date`,
          displayName: "Visibility Start Date (yyyy-mm-dd)",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_visibility_end_date`,
          displayName: "Visibility End Date (yyyy-mm-dd)",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
      }
    },
    setProductChoices: function (
      question: QuestionCompositeModel,
      selectedProducts: {
        [key: string]: string | number | boolean | undefined;
      }[],
    ) {
      const values = question.contentPanel.getQuestionByName("products").value;
      const isEdit = !!values && !session?.societyAdminId;
      const editEnabledLogic = (
        isEdit: boolean,
        productDisabledOnEdit: boolean,
        product: ProductView,
      ): string => {
        // console.log(
        //   `editEnabledLogic: isEdit:${isEdit}, productDisabledOnEdit:${productDisabledOnEdit}, product:${product.name}`,
        // );
        // //todo use date ranges....
        if (!isEdit) {
          console.log("isEdit is false");
          return "true";
        }
        if (productDisabledOnEdit) {
          console.log("productDisabledOnEdit is true");
          return "false";
        }
        if (
          isVisibleLogic(
            isEdit,
            question[product.id + "_visibility_start_date"],
            question[product.id + "_visibility_end_date"],
          ) == "false" //TODO: Add Check to see if you are an admin, or have permission to edit expired products
        ) {
          return "false";
        }
        return "true";
      };

      const isVisibleLogic = (
        isEdit: boolean,
        start_date: string | undefined,
        end_date: string | undefined,
      ) => {
        if (isEdit) {
          return "true";
        }
        if (!start_date && !end_date) {
          return "true";
        }
        if (!start_date && end_date) {
          return `${new Date() <= new Date(end_date)}`;
        }
        if (start_date && !end_date) {
          return `${new Date() >= new Date(start_date)}`;
        }
        if (start_date && end_date) {
          return `${
            new Date() >= new Date(start_date) &&
            new Date() <= new Date(end_date)
          }`;
        }

        return "false";
      };

      selectedProducts = selectedProducts.map((product) => {
        const productView = products!.find((p) => p.id == product.value)!;
        const disable_on_edit = question.getPropertyValue(
          product.value + "_disable_on_edit",
        );
        const visibility_start_date = question.getPropertyValue(
          product.value + "_visibility_start_date",
        );
        const visibility_end_date = question.getPropertyValue(
          product.value + "_visibility_end_date",
        );
        const tags = question.getPropertyValue(product.value + "_tags");
        return {
          ...product,
          visibleIf: `tag_includes(${tags}) and ${isVisibleLogic(
            isEdit,
            visibility_start_date,
            visibility_end_date,
          )}`,
          enableIf: editEnabledLogic(isEdit, disable_on_edit, productView),
        };
      });
      (
        question.contentPanel.getQuestionByName(
          "products",
        ) as QuestionRadiogroupModel
      ).choices = selectedProducts;
    },
    showProductPrice(question: any, selectedProducts: selectedProducts[]) {
      for (const product of selectedProducts) {
        if (question[`${product.value}_show_price`] === true) {
          if (!product.text.includes(` - $${product.price}`)) {
            if (!product.text.endsWith(" -")) {
              product.text = `${product.text} -`;
            }
            product.text = `${product.text} $${product.price}`;
          }
        } else {
          product.text = product.text.replace(` $${product.price}`, "");
          product.text = product.text.replace(" -", "");
        }
      }
    },
    addProductDescription(
      propertyName: string,
      newValue: ItemValue,
      selectedProducts: selectedProducts[],
    ) {
      selectedProducts?.forEach((product) => {
        const productName = product.name;
        if (product.value + "_add_description" === propertyName) {
          product.text = `${productName} - : ${newValue}`;
        }
        if (String(newValue) === "null" || String(newValue) === "") {
          product.text = productName;
        }
      });
    },
    setChangePrice(
      selectedProducts: selectedProducts[],
      newValue: ItemValue,
      propertyName: string,
    ) {
      selectedProducts?.forEach((product) => {
        if (product.value + "_change_price" === propertyName) {
          product.price = String(newValue);
        }
      });
    },
    addCustomPriceQuestion(
      question: Question & any,
      selectedProducts: selectedProducts[],
    ) {
      selectedProducts?.forEach((product) => {
        question.contentPanel.getQuestionByName("price_override").visible =
          question[`${product.value}_price_override`] === true;
      });
    },
    onValueChanged(question: QuestionCompositeModel) {
      console.log("onValueChangedProducts");
      this.onValueChangedProducts(question);
    },
    onValueChangedProducts(question: QuestionCompositeModel) {
      const surveyModel = question.getSurvey() as unknown as SurveyModel;
      const surveyData = surveyModel.getSurveyData();

      const invoiceItems = Object.values(surveyData.getAllValues())
        .filter((x: any) => x.products)
        .flatMap((val: any) => val.products);
      surveyData.setValue("aggregateProducts", invoiceItems, false, false);
    },
    setProductProperties(selectedProducts: selectedProducts[]) {
      selectedProducts?.forEach((product) => {
        Serializer.addProperty(question_name, {
          name: `${product.value}_price_override`,
          type: "boolean",
          default: false,
          displayName: "Override Price",
          category: product.name,
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_show_price`,
          type: "boolean",
          default: product.price,
          displayName: "Show Price",
          category: product.name,
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_add_description`,
          type: "text",
          displayName: "Description",
          category: product.name,
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_change_price`,
          type: "number",
          default: product.price,
          displayName: "Change Price",
          category: product.name,
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_visibility_start_date`,
          displayName: "Visibility Start Date (yyyy-mm-dd)",
          category: product.name,
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_visibility_end_date`,
          displayName: "Visibility End Date (yyyy-mm-dd)",
          category: product.name,
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_tags`,
          type: "multiplevalues",
          displayName: "Tag Conditions",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
          choices: tags,
        });
        Serializer.addProperty(question_name, {
          name: `${product.value}_disable_on_edit`,
          type: "boolean",
          default: false,
          displayName: "Disable on Edit",
          category: product.name,
          dependsOn: ["product_choices"],
          visibleIf: function (obj: QuestionCompositeModel) {
            return obj
              .getPropertyValue("product_choices")
              .includes(product.value);
          },
        });
      });
    },
    onPropertyChanged(
      question: Question,
      propertyName: string,
      newValue: ItemValue,
    ) {
      if (propertyName === "product_choices") {
        const selectedProducts =
          products_list?.filter((product) =>
            Serializer.getObjPropertyValue(
              question,
              "product_choices",
            ).includes(product.value),
          ) ?? [];

        this.setChangePrice(selectedProducts, newValue, propertyName);
        this.addProductDescription(propertyName, newValue, selectedProducts);
        this.showProductPrice(question, selectedProducts);
        this.setProductChoices(question, selectedProducts);
        this.setProductProperties(selectedProducts);
        this.addCustomPriceQuestion(question, selectedProducts);
      }
    },
  };
  if (!ComponentCollection.Instance.getCustomQuestionByName(question_name)) {
    ComponentCollection.Instance.add(products_question_object);
  }
};
