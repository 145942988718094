import { FormikValues, useFormikContext } from "formik";

import React from "react";

import NewAppInput from "./NewAppInput";
import { NewAppInputProps } from "types/newAppForm";

export type NewAppUSDInputProps = NewAppInputProps & {
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const NewAppUSDInput = (props: NewAppUSDInputProps) => {
  const { values, errors, setFieldValue } = useFormikContext<FormikValues>();
  return (
    <NewAppInput
      name={props.name}
      labelClassName={props.labelClassName}
      label={props.label}
    >
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          type="number"
          required={props.required}
          className={`h-10 mt-2 pl-3 py-2 block w-full rounded border-slate-200 text-base font-light leading-norma text-slate-900 placeholder:text-slate-400  ${
            errors[props.name] &&
            "border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500"
          }`}
          value={values[props.name]}
          name={props.name}
          id={props.name}
          onChange={(e) => {
            setFieldValue(props.name, e.target.value);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            USD
          </span>
        </div>
      </div>
    </NewAppInput>
  );
};

export default NewAppUSDInput;
