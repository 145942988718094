import { ApiQueryConfig } from "@api";
import {
  ApiResponse,
  SubmissionWorkflowView,
} from "@parthenon-management/pillar-types";

const GetSubmissionWorkflows: ApiQueryConfig<
  ApiResponse<SubmissionWorkflowView[]>,
  { societyId: string },
  {
    profileId: number;
    type:
      | "Membership"
      | "Submission"
      | "Simple"
      | "Registration"
      | "Conference"
      | "Group";
  }
> = {
  queryKey: { baseQueryKey: ["GetSubmissionWorkflows"] },
  apiPath: "/api/v1/society/{societyId}/workflow/submissions",
};

const GetSubmissionWorkflow: ApiQueryConfig<
  ApiResponse<SubmissionWorkflowView>,
  { societyId: string; submissionWorkflowId: string },
  { profileId: number }
> = {
  queryKey: {
    baseQueryKey: ["GetSubmissionWorkflow"],
    dynamicQueryKey: ["submissionWorkflowId"],
  },
  apiPath:
    "/api/v1/society/{societyId}/workflow/submissions/{submissionWorkflowId}",
};

export { GetSubmissionWorkflows, GetSubmissionWorkflow };
