import { PillarPDF } from "types/PDF";

export const createPDFDownloadLink = async (pillarPDF: PillarPDF) => {
  console.log("pdf", pillarPDF);
  try {
    const uint8Array = new Uint8Array(pillarPDF.pdfBuffer.data);

    const blob = new Blob([uint8Array], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = pillarPDF?.name ?? "File.pdf";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};

export const createIcsDownloadLink = async (icsData: string) => {
  try {
    const u8encoder = new TextEncoder();
    const uint8Array = new Uint8Array(u8encoder.encode(icsData));

    const blob = new Blob([uint8Array], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "event.ics";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};
