import { ComponentPropsWithoutRef } from "react";

type ProfileAvatarProps = ComponentPropsWithoutRef<"div"> & {
  pictureUrl?: string;
  firstName: string;
  lastName: string;
};

export const ProfileAvatar = ({
  pictureUrl,
  firstName,
  lastName,
  className,
  ...props
}: ProfileAvatarProps) => {
  const initials = `${firstName?.[0] ?? "A"}${
    lastName?.[0] ?? ""
  }`.toUpperCase();

  return (
    <>
      {pictureUrl ? (
        <div
          className={`profile-avatar-container flex justify-center h-6 w-6 ${
            className ?? ""
          }`}
          {...props}
          style={{
            backgroundImage: `url(${pictureUrl})`,
          }}
        ></div>
      ) : (
        <div
          className={`profile-avatar-container flex justify-center items-center h-6 w-6 bg-society-500 text-neutral-light "
          } ${className ?? ""}`}
          {...props}
        >
          {initials}
        </div>
      )}
    </>
  );
};
