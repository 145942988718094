import { IonSpinner } from "@ionic/react";
import { FormikValues, useFormikContext } from "formik";
import { ComponentPropsWithoutRef } from "react";

// TODO: Remove fullWidth in favor of passing through props
type NewAppSubmitButtonProps = Omit<
  ComponentPropsWithoutRef<"button">,
  "type" | "onClick"
> & {
  label: string;
  fullWidth?: boolean;
  isLoading?: boolean;
};

const NewAppSubmitButton = ({
  label,
  fullWidth,
  isLoading,
  className,
  disabled,
  ...props
}: NewAppSubmitButtonProps) => {
  const { handleSubmit, validateForm } = useFormikContext<FormikValues>();

  return (
    <div className={fullWidth ? "w-full" : ""}>
      <button
        type="button"
        className={`button-regular-general-filled h-6 ${
          fullWidth ? "w-full" : ""
        } ${className ?? className}`}
        disabled={!!disabled || isLoading}
        onClick={(e) => {
          e.preventDefault();
          validateForm();
          handleSubmit();
        }}
        {...props}
      >
        {isLoading ? <IonSpinner className="text-white" /> : label}
      </button>
    </div>
  );
};

export default NewAppSubmitButton;
