/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
} from "survey-core";
import { SjsCustomQuestion } from "../types/sjs-custom-question";

export const degree_question = async () => {
  const question_name = SjsCustomQuestion.degree_picker;
  const matrix_question_name = "degree_picker_matrix";

  const degree_question_object: ICustomQuestionTypeConfiguration & any = {
    name: question_name,
    elementsJSON: [
      {
        name: matrix_question_name,
        type: "matrixdynamic",
        titleLocation: "hidden",
        columns: [
          {
            name: "degree", //this must equal the pillar_choices_question_name
            cellType: "dropdown",
            title: "Degree",
            showOtherItem: true,
            choicesLazyLoadEnabled: true,
            pillar_choices_question_name: "degree", ///note, this is unused since this is not a property on this cell (doesn't support adding it via the serializer.addProperty api), we use name instead
          },
          {
            name: "graduationDate",
            cellType: "text",
            inputType: "date",
            title: "Graduation Date",
          },
          {
            name: "university", //this must equal the pillar_choices_question_name
            cellType: "dropdown",
            title: "University",
            showOtherItem: true,
            choicesLazyLoadEnabled: true,
            pillar_choices_question_name: "university", ///note, this is unused since this is not a property on this cell (doesn't support adding it via the serializer.addProperty api), we use name instead
          },
        ],
        rowCount: 1,
      },
    ],
  };
  if (!ComponentCollection.Instance.getCustomQuestionByName(question_name)) {
    ComponentCollection.Instance.add(degree_question_object);
  }
};
