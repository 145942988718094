/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ApiQueryConfig,
  QueryPathParamsType,
  QueryReturnType,
  QueryUrlParamsType,
  apiClient,
  buildApiClient,
} from "@api";
import {
  FetchQueryOptions,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useMemo } from "react";
import { queryClient } from "./query-client";
import { generateQueryKey, interpolateApiPath } from "./utils";

const axiosConfigNonOverridable = (urlParams: Record<string, string>) => {
  return { params: urlParams };
};

const axiosConfigOverridable: AxiosRequestConfig = {
  timeout: 30 * 1000,
};

const tanstackConfigNonOverridable = (
  enabled: boolean,
): UseQueryOptions<AxiosResponse<any>, Error, AxiosResponse<any>, QueryKey> => {
  return { enabled: enabled };
};
const tanstackConfigOverridable: UseQueryOptions<
  AxiosResponse<any>,
  Error,
  AxiosResponse<any>,
  QueryKey
> = {
  networkMode: "online",
  retry: 2,
  retryOnMount: true,
  staleTime: Infinity,
  cacheTime: 10 * 60 * 1000,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
};

/**
 *
 * @param apiQueryConfig
 * @param pathParams
 * @param urlParams
 * @param axiosRequestConfig
 * @param tanstackConfig
 * @returns
 */
const useApiQuery = <
  T extends ApiQueryConfig<any, Record<string, any>, Record<string, any>>,
  ReturnType extends QueryReturnType<T>,
  PathParamsType extends QueryPathParamsType<T>,
  UrlParamsType extends QueryUrlParamsType<T>,
>(
  apiQueryConfig: ApiQueryConfig<ReturnType, PathParamsType, UrlParamsType>,
  pathParams?: PathParamsType,
  urlParams?: UrlParamsType,
  axiosRequestConfig?: AxiosRequestConfig,
  tanstackConfig?: UseQueryOptions<
    AxiosResponse<ReturnType>,
    Error,
    AxiosResponse<ReturnType>,
    QueryKey
  >,
): UseQueryResult<AxiosResponse<ReturnType, any>, Error> => {
  buildApiClient();

  const path = interpolateApiPath(apiQueryConfig.apiPath, pathParams);

  const mergedAxiosRequestConfig = useMemo<AxiosRequestConfig>(() => {
    return {
      ...axiosConfigOverridable,
      ...(axiosRequestConfig ?? {}),
      ...axiosConfigNonOverridable(urlParams ?? {}),
    };
  }, [urlParams, axiosRequestConfig]);

  const mergedTanstackConfig: typeof tanstackConfig = useMemo<
    typeof tanstackConfig
  >(() => {
    return {
      ...tanstackConfigOverridable,
      ...(tanstackConfig ?? {}),
      ...tanstackConfigNonOverridable(tanstackConfig?.enabled ?? true),
      meta: {
        mergedAxiosRequestConfig: mergedAxiosRequestConfig,
      },
    };
  }, [mergedAxiosRequestConfig, tanstackConfig]);

  const queryKey = generateQueryKey(
    apiQueryConfig.queryKey.baseQueryKey,
    pathParams,
    urlParams,
    undefined,
    apiQueryConfig.queryKey.dynamicQueryKey,
  );

  return useQuery<AxiosResponse<ReturnType>, Error>(
    queryKey,
    async (context) => {
      const { mergedAxiosRequestConfig } = context.meta as {
        mergedAxiosRequestConfig: AxiosRequestConfig;
      };
      return await apiClient!.get(path, mergedAxiosRequestConfig);
    },
    mergedTanstackConfig,
  );
};

const fetchApiQuery = <
  T extends ApiQueryConfig<any, Record<string, any>, Record<string, any>>,
  ReturnType extends QueryReturnType<T>,
  PathParamsType extends QueryPathParamsType<T>,
  UrlParamsType extends QueryUrlParamsType<T>,
>(
  apiQueryConfig: ApiQueryConfig<ReturnType, PathParamsType, UrlParamsType>,
  pathParams?: PathParamsType,
  urlParams?: UrlParamsType,
  axiosRequestConfig?: AxiosRequestConfig,
  tanstackConfig?: FetchQueryOptions<
    AxiosResponse<ReturnType>,
    Error,
    AxiosResponse<ReturnType>,
    QueryKey
  >,
): Promise<AxiosResponse<ReturnType, any>> => {
  buildApiClient();

  const mergedAxiosRequestConfig: AxiosRequestConfig = {
    ...axiosConfigOverridable,
    ...(axiosRequestConfig || {}),
    ...axiosConfigNonOverridable(urlParams || {}),
  };

  const tanstackFetchConfigOverridable = tanstackConfigOverridable;
  delete tanstackConfigOverridable.enabled;
  const tanstackFetchConfigNonOverridable = tanstackConfigNonOverridable(false);

  const mergedTanstackConfig: typeof tanstackConfig = {
    ...tanstackFetchConfigOverridable,
    ...(tanstackConfig || {}),
    ...tanstackFetchConfigNonOverridable,
  };

  const path = interpolateApiPath(apiQueryConfig.apiPath, pathParams);

  const queryKey = generateQueryKey(
    apiQueryConfig.queryKey.baseQueryKey,
    pathParams,
    urlParams,
    undefined,
    apiQueryConfig.queryKey.dynamicQueryKey,
  );

  const queryFn = () => apiClient!.get(path, mergedAxiosRequestConfig);

  return queryClient.fetchQuery(queryKey, queryFn, mergedTanstackConfig);
};

export { fetchApiQuery, useApiQuery };
