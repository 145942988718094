import { FormikValues, useFormikContext } from "formik";

import { ComponentPropsWithoutRef } from "react";

import React from "react";
import NewAppInput, { NewAppInputProps } from "./NewAppInput";

export type NewAppDateInputProps = NewAppInputProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

//TODO: The only difference between this and NewAppTextInput is the `type="date"` property.
//We should just use text input and allow a type to be passed to avoid code duplication.
const NewAppExpirationDateInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  className,
  onChange,
  ...props
}: NewAppDateInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^0-9]/g, "");

    if (sanitizedInput.length <= 4) {
      setFieldValue(
        name,
        sanitizedInput.replace(/(\d{2})(\d{0,2})/, "$1/$2").substring(0, 5),
      );
    }
  };

  return (
    <NewAppInput
      name={name}
      labelClassName={labelClassName}
      label={label}
      additionalClasses={additionalClasses ?? ""}
    >
      <input
        type="text"
        className={className ?? ""}
        value={values[name]}
        name={name}
        id={name}
        maxLength={5}
        onChange={(e) => handleDateChange(e)}
        {...props}
      />
    </NewAppInput>
  );
};

export default NewAppExpirationDateInput;
