import React, { ComponentPropsWithoutRef } from "react";

//TODO: Remove label and just pass through children.
type NewAppButtonProps = ComponentPropsWithoutRef<"button"> & {
  label?: string;
  icon?: React.ReactElement;
  testId?: string;
};

const NewAppButton = ({
  label,
  icon,
  className,
  testId,
  ...props
}: NewAppButtonProps) => {
  return (
    <button
      type="button"
      className={`${className ? className : "button-regular-general-filled"}`}
      data-testid={testId}
      {...props}
    >
      {icon && icon}
      {label ? label : props.children}
    </button>
  );
};

export default NewAppButton;
