import { ProfileView, SessionView } from "@parthenon-management/pillar-types";
import { checkUsernameExists } from "api/identity";
import _debounce from "lodash/debounce";
import { DateTime } from "luxon";
import {
  ComponentCollection,
  Question,
  Serializer,
  SurveyModel,
} from "survey-core";
import { SjsCustomQuestion } from "../types/sjs-custom-question";

//value to limit the number of profile questions, only used to optimize the loop in onValueChanged
const debouncedCheckUsername = _debounce(
  async (
    value: string,
    question: Question,
    callback: (question: Question, isUnique: boolean) => void,
  ) => {
    const result = await checkUsernameExists(value);
    callback(question, !result);
  },
  1000,
);
const question_name = SjsCustomQuestion.login_details;
export const login_details_question = async (
  session: SessionView,
  profile?: ProfileView,
) => {
  const login_details_object = {
    name: question_name,
    elementsJSON: [
      {
        type: "html",
        name: "recovery_link",
        html: "",
        visible: false,
      },
      {
        type: "text",
        name: "user_name",
        title: "E-Mail",
        inputType: "text",
        textUpdateMode: "onTyping",
        isRequired: !session.societyAdmin && !session.societyUser,
      },
      {
        type: "text",
        name: "old_password",
        title: "Old Password",
        inputType: "password",
        visible:
          !!session?.societyUser &&
          session.iat < DateTime.now().minus({ days: 1 }).toSeconds() &&
          !session.societyAdmin,
      },
      {
        type: "text",
        name: "password",
        autocomplete: "new-password",
        title: "Password",
        isRequired: profile === undefined,
        textUpdateMode: "onTyping",
        inputType: "password",
      },
      {
        type: "text",
        name: "password_confirm",
        startWithNewLine: false,
        title: "Confirm Password",
        inputType: "password",
        textUpdateMode: "onTyping",
        isRequired: profile === undefined,
        autocomplete: "new-password",
      },
      {
        type: "html",
        name: "password_complexity_html",
        html: "",
        visible: false,
      },
      {
        type: "html",
        name: "password_confirm_html",
        html: "",
        startWithNewLine: false,
        visible: false,
      },
    ],
    onLoaded(question: Question) {
      question.name = question_name;
      Serializer.addProperty(question_name, {
        name: "changePasswordTitle",
        type: "text",
        default: "Change Password",
        category: "general",
      });

      Serializer.getProperty(question_name, "name").visible = false;
      Serializer.getProperty(question_name, "visible").visible = false;
      Serializer.getProperty(question_name, "isRequired").visible = false;
      Serializer.getProperty(question_name, "readOnly").visible = false;

      Serializer.getProperty(question_name, "visibleIf").visible = false;

      Serializer.getProperty(question_name, "enableIf").visible = false;
      Serializer.getProperty(question_name, "requiredIf").visible = false;
      Serializer.getProperty(question_name, "clearIfInvisible").visible = false;
      Serializer.getProperty(question_name, "defaultValueExpression").visible =
        false;
      Serializer.getProperty(question_name, "defaultValue").visible = false;
      Serializer.getProperty(question_name, "startWithNewLine").visible = false;
      Serializer.getProperty(question_name, "valueName").visible = false;
      Serializer.getProperty(question_name, "useDisplayValuesInTitle").visible =
        false;
      Serializer.getProperty(question_name, "correctAnswer").visible = false;

      Serializer.getProperty(question_name, "requiredErrorText").visible =
        false;

      Serializer.getProperty(question_name, "validators").visible = false;
      if (profile && profile.institution) {
        question.visible = false;
        return;
      }
      if (profile) {
        question.title = Serializer.getProperty(
          question_name,
          "changePasswordTitle",
        ).value;
      }
      // throw new Error("Function not implemented.");
    },
    handlePasswordConfirmVerification(question: Question, password: string) {
      const password_confirm = question.contentPanel.getQuestionByName(
        "password_confirm_html",
      );
      if (
        password !== question.contentPanel.getQuestionByName("password").value
      ) {
        const errorMessageHTML = `
        <p style="font-size: 16px; padding: 10px; border-radius: 4px; font-weight: bold; color: red;">
       Passwords do not match.</p>`;
        password_confirm.visible = true;
        password_confirm.html = errorMessageHTML;
      } else {
        password_confirm.visible = false;
        password_confirm.html = "";
      }
    },
    handlePasswordVerification(question: Question, password: string) {
      const password_complexity = question.contentPanel.getQuestionByName(
        "password_complexity_html",
      );
      const password_confirm =
        question.contentPanel.getQuestionByName("password_confirm");
      const conditions = [
        password.length < 8 &&
          "Your password must be at least 8 characters long.",
        !/(?=.*[A-Z])/.test(password) &&
          "Your password must contain an uppercase letter.",
        !/(?=.*[a-z])/.test(password) &&
          "Your password must contain a lowercase letter.",
        !/(?=.*\d)/.test(password) && "Your password must contain a number.",
        !/[!@#$%^&*(),.?":{}|<>]/.test(password) &&
          "Your password must contain a symbol.",
      ];

      const errorMessageHTML = `
        <div >
          ${conditions
            .filter(Boolean)
            .map(
              (message) =>
                `<p style="color: red !important; font-size : 13px; font-weight : bold;">${message}</p>`,
            )
            .join("")}
        </div>`;
      password_complexity.visible = true;
      password_complexity.html = errorMessageHTML;
      if (conditions.filter(Boolean).length > 0) {
        password_confirm.value = "";
        password_confirm.readOnly = true;
      } else {
        password_complexity.visible = false;
        password_confirm.readOnly = false;
      }
    },

    handleUsernameExists(question: Question, isUnique: boolean) {
      const survey = question.getSurvey() as SurveyModel;
      survey.showNavigationButtons = isUnique;
      question.contentPanel.getQuestionByName("recovery_link").visible =
        !isUnique;
      survey.getAllQuestions().forEach((q: Question) => {
        if (q.name !== question_name) {
          q.readOnly = !isUnique;
        }
        question.contentPanel.getQuestionByName("password").readOnly =
          !isUnique;
        question.contentPanel.getQuestionByName("password_confirm").readOnly =
          !isUnique;
      });
      if (isUnique) {
        question.contentPanel.getQuestionByName("recovery_link").html = "";
      } else {
        question.contentPanel.getQuestionByName("recovery_link").html = `
        <p style="background-color: #FCE9EE; color: #E60A3E; font-size: 13px; padding: 10px; border-radius: 4px; font-weight: bold;">
          This username is not available. If you believe you may already have a profile, please use this 
          <a href='${session.society?.societySettingsPublic?.userHubUrl}/account/recover' style="color: blue; text-decoration: underline;">link</a> 
          to recover your account.
        </p>
      `;
      }
    },

    onInit() {
      //Override titleLocation property attributes for "shippingaddress" class by making it invisible in property grid and change its default value
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCreated(question: Question) {
      // throw new Error("Function not implemented.");
    },
    onAfterRender: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
    onAfterRenderContentElement: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      element: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      htmlElement: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onPropertyChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      newValue: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onValueChanging: function (
      question: Question,
      name: string,
      newValue: any,
    ) {
      if (name === "password") {
        this.handlePasswordVerification(question, newValue);
      }
      if (name === "password_confirm") {
        this.handlePasswordConfirmVerification(question, newValue);
      }

      if (name === "user_name") {
        debouncedCheckUsername(newValue, question, this.handleUsernameExists);
        //the convention we established is to have the multiple address questions named appended with their arbitrary index/sequence number

        //Get all survey questions
        const allProfileAddressContactInformationQuestions = (
          question.survey as SurveyModel
        )
          .getAllQuestions()
          .filter((q) => q.getType() === "profileaddresscontactinformation");
        //Loop through all the profile address contact information questions
        for (const profileAddressContactInformationQuestion of allProfileAddressContactInformationQuestions) {
          if (
            profileAddressContactInformationQuestion.value.email === null ||
            profileAddressContactInformationQuestion.value.email ===
              undefined ||
            profileAddressContactInformationQuestion.value.email ===
              question.value.user_name
          ) {
            profileAddressContactInformationQuestion.updateValueFromSurvey({
              ...profileAddressContactInformationQuestion.value,
              email: newValue,
            });
          }
        }
      }
      return newValue;
      // throw new Error("Function not implemented.");
    },
    onItemValuePropertyChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      options: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
  };
  if (!ComponentCollection.Instance.getCustomQuestionByName(question_name)) {
    ComponentCollection.Instance.add(login_details_object);
  }
};
