import NewAppForm from "@components/common/newform/NewAppForm";
import NewAppTextInput from "@components/common/newform/NewAppTextInput";
import { Spinner } from "@components/common/Spinner";
import { AddressView } from "@parthenon-management/pillar-types";
import { FormikValues } from "formik";
import { useEffect, useState } from "react";

type Props = {
  addressType: string;
  sameAsBilling?: boolean;
  handleAddressDetails: (details: AddressView, addressType: string) => void;
  addressDetails?: AddressView;
  handleSubmit?: () => void;
};

export const AddressForm = (props: Props) => {
  const [formValues, setFormValues] = useState<FormikValues>({
    line1: props.addressDetails?.line1,
    city: props.addressDetails?.city,
    state: props.addressDetails?.state,
    postalCode: props.addressDetails?.postalCode,
  });

  useEffect(() => {
    props.handleAddressDetails(formValues as AddressView, props.addressType);
  }, [formValues]);

  return (
    <NewAppForm
      handleSubmit={() => {
        //void
      }}
      handleChanged={setFormValues}
      overrideInitialValues={formValues}
    >
      <h4 className="text-subheader">{props.addressType} Address</h4>
      {!props.sameAsBilling && (
        <div className="pt-1">
          {!props.addressDetails ? (
            <Spinner />
          ) : (
            <>
              <div>
                <NewAppTextInput
                  additionalClasses="w-full rounded-md "
                  name="line1"
                  label="Address"
                  placeholder="Enter Address"
                />
              </div>
              <div className="grid grid-cols-2 gap-2 pt-2">
                <div className="pt-1">
                  <NewAppTextInput
                    additionalClasses="rounded-md"
                    name="city"
                    label="City"
                    placeholder="Enter City"
                  />
                </div>
                <div className="pt-1 ">
                  <NewAppTextInput
                    additionalClasses="rounded-md "
                    name="state"
                    label="State"
                    placeholder="Enter State"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </NewAppForm>
  );
};
