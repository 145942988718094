import { ApiResponse, FormView } from "@parthenon-management/pillar-types";

import { ApiQueryConfig } from "api/types";

const GetSocietyDisclosures: ApiQueryConfig<
  ApiResponse<FormView[]>,
  { societyId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/disclosure",
  queryKey: {
    baseQueryKey: ["GetSocietyDisclosures"],
    dynamicQueryKey: ["societyId"],
  },
};

export { GetSocietyDisclosures };
