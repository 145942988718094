import {
  GetAvailableChatProfiles,
  GetChatParticipants,
  StartChat,
  useApiMutation,
  useApiQuery,
} from "@api";
import UserIcon from "@components/icons/UserIcon";
import { useState } from "react";
import { useHistory } from "react-router";
import { getSocietyId } from "utils/getSocietyId";
import { getProfileId } from "utils/sessionStorage/user";
import { getParticipantInfo } from "./chat-shared-logic";
import NewAppButton from "@components/common/newform/NewAppButton";

type Props = {
  chatId: number;
  showListOfUser: boolean;
};

export const ParticipantsBar = ({ chatId, showListOfUser }: Props) => {
  const history = useHistory();

  const [showNewRecipient, setShowNewRecipient] = useState(showListOfUser);
  const [searchRecipient, setSearchRecipient] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const { data: getParticipantsResponse } = useApiQuery(
    GetChatParticipants,
    { societyId: getSocietyId().toString(), chatId: chatId?.toString() },
    {},
  );

  const { data: getProfilesResponse, isLoading: loadingUser } = useApiQuery(
    GetAvailableChatProfiles,
    {
      societyId: getSocietyId().toString(),
    },
  );

  const { mutateAsync: startChatAsync, isLoading: savingUsersInProgress } =
    useApiMutation(StartChat, {
      societyId: getSocietyId().toString(),
    });

  const getChatParticipantsFirstName = () => {
    const firstNames: string[] = [];
    getParticipantsResponse?.data?.body?.forEach((user, index) => {
      const userInfo = getParticipantInfo(user);

      // If the participant is the current user,
      // don't return  the first name.
      if (user.profile?.id === getProfileId()) {
        return;
      }

      if (userInfo && userInfo.firstName) {
        firstNames.push(userInfo.firstName);
      }
    });

    return firstNames.join(", ");
  };

  const handleAddUsers = async () => {
    const resp = await startChatAsync({
      participantsProfileId: [...selectedUserIds, getProfileId()],
    });
    history.replace(`/chats/${resp.data.body?.id}`);
  };

  const filteredUsers = getProfilesResponse?.data?.body?.filter((profile) => {
    if (profile?.id === getProfileId()) {
      // Don't include the current user in the list
      return false;
    }

    return `${profile?.societyUser?.firstName} ${profile?.societyUser?.lastName}`
      .toLowerCase()
      .includes(searchRecipient.toLowerCase());
  });

  const handleUserSelection = (newUserId: number) => {
    const isSelected = selectedUserIds.some(
      (selectedUserId) => selectedUserId === newUserId,
    );

    const updatedUsers = isSelected
      ? selectedUserIds.filter((selectedUserId) => selectedUserId !== newUserId)
      : [...selectedUserIds, newUserId];

    setSelectedUserIds(updatedUsers);
  };

  const isLoadingData = savingUsersInProgress;

  const isAddButtonDisabled = selectedUserIds.length === 0;

  if (isLoadingData || loadingUser) {
    return (
      <div className="flex-none -mx-4 -mt-4 p-4 h-12 shadow-sm border-t border-b border-solid border-neutral-mid-100" />
    );
  }

  return (
    <>
      <div className="px-4.5 pb-2 pt-2 tablet:p-2 shadow border-b border-t border-solid border-neutral-mid-100 min-h-sm">
        {!showNewRecipient && (
          <div className="flex justify-between ">
            <div className="self-center text-subheader line-clamp-1 hover:line-clamp-none hover:z-10 focus:line-clamp-none focus:z-10">
              {getChatParticipantsFirstName()}
            </div>

            {/* TODO: Add this back when we want to allow the user to 
                      add or remove participants after the chat is created. */}
            {/* <div
              className="text-slate-500 self-start hover:cursor-pointer"
              onClick={() => setShowNewRecipient(true)}
            >
              <DotsVerticalIcon />
            </div> */}
          </div>
        )}

        {/* This is the header. It has the ADD button that adds all selected users for chat */}

        {showNewRecipient && (
          <div className="flex flex-row px-4.5 tablet:px-2">
            <input
              type="text"
              className="w-full !bg-neutral-light resize-none !text-base !phone:text-sm leading-5 font-normal pr-10 border-none outline-none ring-0 ring-none focus:outline-none focus:ring-none focus:border-transparent focus:ring-0"
              placeholder="Search recipients..."
              onChange={(e) => setSearchRecipient(e.target.value)}
            />
            <div className="h-full flex items-center text-slate-400">
              <NewAppButton
                className="button-regular-general-unfilled text-lg"
                disabled={isAddButtonDisabled}
                onClick={
                  !isAddButtonDisabled
                    ? () => {
                        handleAddUsers();
                        setShowNewRecipient(false);
                      }
                    : undefined
                }
              >
                Add
              </NewAppButton>
            </div>
          </div>
        )}
      </div>
      <>
        {/* This is the list of all available profiles for chat
         */}
        <div className="bg-neutral-light-400">
          {showNewRecipient ? (
            <div className="flex-shrink max-h-30 mx-3.5 pb-1 pt-1 mb-1 px-1 tablet:p-2 tablet:mx-0 overflow-y-auto bg-neutral-light rounded-b-md shadow">
              {filteredUsers?.length === 0 ? (
                <p className="flex text-subtle">No Users Available</p>
              ) : (
                <ul>
                  {filteredUsers?.map((profile, index) => (
                    <li key={index}>
                      <NewAppButton
                        className="flex justify-between w-full pb-0.5 items-center hover:opacity-70"
                        onClick={() => handleUserSelection(profile!.id)}
                      >
                        <div className="inline-flex flex-row gap-x-2.5 text-base font-medium text-neutral-mid-600">
                          <span className="text-neutral-mid-300 mt-0.5">
                            <UserIcon size={20} />
                          </span>
                          <div className="flex flex-wrap tablet:flex-col justify-start items-center gap-x-2.5  tablet:items-start">
                            <span className="whitespace-nowrap">
                              {profile?.societyUser?.firstName}{" "}
                              {profile?.societyUser?.lastName}
                            </span>
                            <span className="text-sm whitespace-nowrap leading-5 font-normal">
                              {profile?.societyUser?.affiliation}
                            </span>
                          </div>
                        </div>
                        <input
                          className="rounded mr-1"
                          type="checkbox"
                          checked={selectedUserIds.some(
                            (selectedUserId) => selectedUserId === profile?.id,
                          )}
                        />
                      </NewAppButton>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : null}
        </div>
      </>
    </>
  );
};
