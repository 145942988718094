import { combineDateAndTime } from "./combine-date-time";
import * as getDate from "./get-date";
import { formatLuxonDate as format } from "./luxon";

export { LuxonDateFormat } from "./luxon";
export const DateTime = {
  combineDateAndTime,
  ...getDate,
  format,
};
