import { GetConferenceEvents, fetchApiQuery } from "@api";
import { DateTime } from "luxon";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  ItemValue,
  Question,
  Serializer,
  SurveyModel,
} from "survey-core";
import { LuxonDateFormat } from "utils/datetime";
import { getSocietyId } from "utils/getSocietyId";
import { SjsCustomQuestion } from "../types/sjs-custom-question";
export const speakerColumnName = "Speaker";

export const events = async () => {
  const questionName = SjsCustomQuestion.events_attended;

  const eventsObject: ICustomQuestionTypeConfiguration = {
    name: questionName,
    // title: questionTitle,

    // questionJSON: {
    //   name: questionName,
    //   type: "tagbox",
    // },
    elementsJSON: [
      {
        type: "tagbox",
        name: "in_person_events_attended",
        title: "In Person events attended",
        required: true,
      },
      {
        type: "tagbox",
        name: "virtual_events_attended",
        title: "Virtual Events Attended",
      },
    ],

    onCreated: function (question: Question): void {
      // question.title = questionTitle;
      //   throw new Error("Function not implemented.");
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onLoaded: async function (question: Question): Promise<void> {
      //   throw new Error("Function not implemented.");

      const conferenceId = Serializer.getObjPropertyValue(
        question.survey,
        "conferenceId",
      );

      const eventsResults = await fetchApiQuery(
        GetConferenceEvents,
        {
          societyId: getSocietyId().toString(),
          conferenceId: conferenceId.toString(),
        },
        {},
      );

      const inPersonChoices = eventsResults.data.body?.results.filter(
        (myEvent) => {
          // return myEvent.eventFormatType !== "Virtual";
          return true;
        },
      );

      const virtualChoices = eventsResults.data.body?.results.filter(
        (myEvent) => {
          // return myEvent.eventFormatType !== "In_Person";
          return true;
        },
      );
      if (!inPersonChoices || !virtualChoices) return;

      const mappedInPersonEvents = inPersonChoices.map((event) => {
        return new ItemValue(
          event.id,
          String(event.eventName) +
            "\tDay #" +
            event.dayNumber +
            "\t" +
            DateTime.fromISO(event.date.toLocaleString()).toFormat(
              LuxonDateFormat.t,
            ),
        );
      });

      const mappedVirtualEvents = virtualChoices.map((event) => {
        return new ItemValue(event.id, String(event.eventName));
      });
      // question.getPropertyByName("events").setChoices(inPersonChoices);

      question.getQuestionByName("in_person_events_attended").choices =
        mappedInPersonEvents;

      question.getQuestionByName("virtual_events_attended").choices =
        mappedVirtualEvents;
    },
    onInit() {
      // Serializer.addProperty(questionName, {
      //   name: "events",
      //   type: "panel",
      //   visible: true,
      //   category: "Events",
      // });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onAfterRender: function (question: Question, htmlElement: unknown): void {
      //   throw new Error("Function not implemented.");

      const onChoicesLazyLoad = async (sender: any, options: any) => {
        console.log("onChoicesLazyLoad", sender, options);
        const conferenceId = 3;

        const eventsResults = await fetchApiQuery(
          GetConferenceEvents,
          {
            societyId: getSocietyId().toString(),
            conferenceId: conferenceId.toString(),
          },
          {},
        );

        const choices = eventsResults.data.body?.results;
        if (!choices) return;

        const mappedEvents = choices.map((event) => {
          return new ItemValue(event.id, String(event.eventName));
        });
        question.getPropertyByName("events").setChoices(choices);

        question.getQuestionByName(questionName).choices = mappedEvents;
      };

      (question.getSurvey() as SurveyModel).onChoicesLazyLoad.add(
        (sender, options) => {
          onChoicesLazyLoad;
        },
      );
    },
    onAfterRenderContentElement: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      question: Question & any,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      element: Question,
    ): void {
      //   throw new Error("Function not implemented.");
    },

    onValueChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      newValue: number[],
    ): void {
      // empty
    },
  };
  if (!ComponentCollection.Instance.getCustomQuestionByName(questionName)) {
    ComponentCollection.Instance.add(eventsObject);
  }
};
