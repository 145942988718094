import { getSocietyId } from "utils/getSocietyId";
import { apiClient, buildApiClient } from "@api";
import { getProfileId } from "utils/sessionStorage/user";
import { Chat, NewParticipant, NewUserChat, ReadMessage } from "types/chat";

export const getChats = async (): Promise<Chat[]> => {
  try {
    buildApiClient();
    const response = await apiClient!.get(
      `/api/v1/society/${getSocietyId()}/chat?profileId=${getProfileId()}`,
    );

    const { body } = response!.data;

    return body;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const saveChat = async (
  chatName?: string,
  chatId = 0,
): Promise<Chat> => {
  try {
    buildApiClient();
    const response = await apiClient!.post(
      `api/v1/society/${getSocietyId()}/chat`,
      {
        id: chatId,
        name: chatName ? chatName : "",
        societyId: getSocietyId(),
        createdByProfileId: getProfileId(),
      },
    );

    const { body } = response!.data;

    return body;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createParticipants = async (
  participants: NewParticipant[],
): Promise<number> => {
  try {
    buildApiClient();
    const response = await apiClient!.post(
      `/api/v1/society/${getSocietyId()}/chat/participant`,
      participants,
    );

    const { body } = response!.data;

    return body?.count;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addChatParticipants = async (chat: NewUserChat) => {
  try {
    buildApiClient();
    const savedChat = await saveChat(chat.name, chat.id);
    chat.id = savedChat.id ?? 0;

    await createParticipants([
      ...chat.participants.map((participant) => ({
        ...participant,
        chat_id: chat.id,
      })),
      {
        societyId: getSocietyId(),
        profileId: getProfileId(),
        societyAdminId: null,
        chatId: chat.id,
      },
    ]);

    return chat.id;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUserHasReadMsg = async (
  messages: ReadMessage[],
): Promise<void> => {
  try {
    buildApiClient();
    await apiClient?.post(
      `/api/v1/society/${getSocietyId()}/chat/message/updateReadMsg`,
      messages,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};
