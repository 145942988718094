import {
  GetSubmissionWorkflow,
  useApiMutation,
  useApiQuery,
  GetMostRecentSubmissionResponse,
  GetSubmissionResponse,
  CreateSubmissionResponse,
  UpdateWorkflowInstance,
} from "@api";
import LoadingPage from "components/common/LoadingPage";
import PageComponent from "components/common/PageComponent";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { NewInvoice } from "types/Invoice";
import { getSocietyId } from "utils/getSocietyId";
import { getProfileId } from "utils/sessionStorage/user";
import NewAppButton from "@components/common/newform/NewAppButton";
import { CreateInvoice } from "api/routes/invoices";

interface RouteParams {
  id: string;
}

export interface SubmissionResponseInfo {
  profileId?: number;
  submissionProcessId?: number;
  submissionWorkflowId?: number;
  workflowComplexId?: number;
  workflowInstanceId?: number;
}

const ProductPage = () => {
  //TODO:  This page is probably beign replace with the SurveyJS products selector.
  const history = useHistory();

  const { id } = useParams<RouteParams>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const createSubmissionResponseMutation = useApiMutation(
    CreateSubmissionResponse,
    { societyId: getSocietyId().toString() },
  );

  const createInvoiceMutation = useApiMutation(CreateInvoice, {
    societyId: getSocietyId().toString(),
    profileId: getProfileId().toString(),
  });

  const {
    data: getSubmissionWorkflowResponse,
    isLoading: getSubmissionWorkflowIsLoading,
  } = useApiQuery(
    GetSubmissionWorkflow,
    { societyId: getSocietyId().toString(), submissionWorkflowId: id },
    { profileId: getProfileId() },
  );

  const {
    data: getRecentSubmissionResponseData,
    isLoading: getRecentSubmissionResponseIsLoading,
  } = useApiQuery(
    GetMostRecentSubmissionResponse,
    {
      societyId: getSocietyId().toString(),
      profileId: getProfileId().toString(),
      submissionProcessId:
        getSubmissionWorkflowResponse?.data.body?.submissionProcess?.id.toString(),
    },
    {},
    {},
    {
      enabled:
        !!getSubmissionWorkflowResponse?.data?.body?.submissionProcess &&
        !searchParams.get("submissionResponseId"),
    },
  );

  const {
    data: getSubmissionResponseData,
    isLoading: getSubmissionResponseIsLoading,
  } = useApiQuery(
    GetSubmissionResponse,
    {
      societyId: getSocietyId().toString(),
      submissionResponseId: searchParams.get("submissionResponseId"),
    },
    {},
    {},
    { enabled: !!searchParams.get("submissionResponseId") },
  );

  const updateWorkflowInstanceMutation = useApiMutation(
    UpdateWorkflowInstance,
    {
      societyId: getSocietyId().toString(),
      workflowInstanceId:
        getSubmissionResponseData?.data.body?.workflowInstanceId.toString() ??
        "",
    },
  );

  const [additionalInvoiceInfo, setAdditionalInvoiceInfo] = useState<
    { quantity: number; include: boolean }[]
  >(
    getSubmissionWorkflowResponse?.data?.body!.workflowComplex!.workflowProducts!.map(
      () => {
        return { quantity: 1, include: true };
      },
    ) ?? [],
  );

  const createInvoice = async () => {
    const submissionResponse =
      getSubmissionResponseData?.data ?? getRecentSubmissionResponseData?.data;

    const newInvoice: NewInvoice = {
      InvoiceItem:
        getSubmissionWorkflowResponse?.data.body?.workflowComplex
          ?.workflowProducts!.filter((workflowProduct, index) => {
            return additionalInvoiceInfo[index].include ? true : false;
          })
          .map((workflowProduct, index) => {
            return {
              product_id: workflowProduct!.product!.id,
              quantity: additionalInvoiceInfo[index].quantity,
            };
          }) ?? [],
    };

    //TODO: This produces a potentially brittle section of code
    //as the invoice could succeed and the instance fail. May need a route
    //to handle these both explicitly within a transaction.
    await createInvoiceMutation.mutate(newInvoice);

    const payload = JSON.parse(
      JSON.stringify({
        profile_id: getProfileId(),
        society_id: getSocietyId(),
        submissionResponse: submissionResponse?.body?.responseData,
        submission_process_id:
          getSubmissionWorkflowResponse?.data.body?.submissionProcess?.id,
        invoice: createInvoiceMutation.data?.data.body,
      }),
    );

    if (submissionResponse!.body!.workflowInstanceId) {
      updateWorkflowInstanceMutation.mutate({
        payload: payload,
      });
    } else {
      const newWorkflowInstance = {
        workflowComplexId:
          getSubmissionWorkflowResponse!.data!.body!.workflowComplex!.id,
        payload: payload,
      };
      await createSubmissionResponseMutation.mutateAsync({
        newWorkflowInstance: newWorkflowInstance,
      });
    }
  };

  useEffect(() => {
    if (getSubmissionWorkflowResponse?.data) {
      setAdditionalInvoiceInfo(
        getSubmissionWorkflowResponse!.data!.body!.workflowComplex!.workflowProducts!.map(
          (item, index) => {
            return {
              quantity: additionalInvoiceInfo[index]?.quantity ?? 1,
              include: additionalInvoiceInfo[index]?.include ?? true,
            };
          },
        ),
      );
    }
  }, [getSubmissionWorkflowResponse?.data]);

  if (
    getSubmissionWorkflowIsLoading ||
    (!searchParams.get("submissionResponseId") &&
      getRecentSubmissionResponseIsLoading) ||
    (!!searchParams.get("submissionResponse") && getSubmissionResponseIsLoading)
  ) {
    return <LoadingPage />;
  }

  return (
    <PageComponent>
      <PageComponent.Header />
      <PageComponent.Content>
        <div className="container-light-card ml-3 mr-3 mt-3">
          <h1 className="text-title mb-3 mt-3">Shopping Cart</h1>
          <form className="flex flex-col gap-y-1">
            {/* ANCHOR: Products */}
            <section aria-labelledby="cart-heading">
              <h2 id="cart-heading" className="sr-only">
                Items in your shopping cart
              </h2>
              <div className="">
                <h1 className="sr-only">Order Information</h1>
              </div>
              <h1 className="sr-only">Order information</h1>
              {getSubmissionWorkflowResponse?.data &&
                additionalInvoiceInfo.length > 0 &&
                getSubmissionWorkflowResponse.data?.body!.workflowComplex!.workflowProducts!.map(
                  (workflowProduct, index) => {
                    if (!workflowProduct.product?.inventory) return;
                    return (
                      <div
                        key={`product-${workflowProduct.id}`}
                        className="container-divider-border pb-3 flex space-x-3"
                      >
                        <div className="flex flex-col space-y-1">
                          <div className="text-detailblock-title">
                            {workflowProduct!.product!.name}
                          </div>
                          <div className="text-detailblock-base">
                            $ {workflowProduct!.product!.price}
                          </div>
                          {workflowProduct.product.inventory.id && (
                            <>
                              <label
                                htmlFor={`quantity-product-${workflowProduct.product.id}`}
                                className="sr-only"
                              >
                                {`Quantity ${workflowProduct.product.name}`}
                              </label>
                              <input
                                type="number"
                                id={`quantity-${workflowProduct.product.id}`}
                                name="quantity"
                                autoComplete="cc-name"
                                value={additionalInvoiceInfo[index]?.quantity}
                                onChange={(event) => {
                                  let val = Number(event.target.value);
                                  // TODO: We should use Yup for this, but the form needs refactored to use our NewAppForm first.
                                  if (val < 0) {
                                    val = 0;
                                    (
                                      document.getElementById(
                                        `quantity-${
                                          workflowProduct!.product!.id
                                        }`,
                                      ) as HTMLInputElement
                                    ).value = val.toString();
                                  }
                                  const values = [...additionalInvoiceInfo];
                                  values[index].quantity = Number(val);
                                  setAdditionalInvoiceInfo(values);
                                }}
                              />
                            </>
                          )}
                        </div>
                        {workflowProduct.isOptional && (
                          <div>
                            <span className="sr-only">Include?</span>
                            <input
                              type="checkbox"
                              id="include"
                              name="include"
                              checked={additionalInvoiceInfo[index]?.include}
                              onChange={() => {
                                const values = [...additionalInvoiceInfo];
                                values[index].include = !values[index].include;
                                setAdditionalInvoiceInfo(values);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  },
                )}
            </section>

            {/* ANCHOR: Summary */}
            <section aria-labelledby="summary-heading">
              <h2 id="summary-heading" className="text-title pb-2 pt-2">
                Order summary
              </h2>
              <div className="flex flex-col space-y-2">
                {getSubmissionWorkflowResponse?.data?.body!.workflowComplex!.workflowProducts!.map(
                  (workflowProduct, index) => (
                    <>
                      {additionalInvoiceInfo[index]?.include && (
                        <div
                          key={`summary-product-${workflowProduct.id}`}
                          className="flex flex-col"
                        >
                          <div className="text-detailblock-title">
                            {workflowProduct.product?.name}
                          </div>
                          <div className="flex space-x-2">
                            <div>
                              <span className="text-detailblock-base">
                                Quantity:&nbsp;
                              </span>
                              <span className="text-detailblock-subtle">
                                {additionalInvoiceInfo[index]?.quantity}
                              </span>
                            </div>
                            <div>
                              <span className="text-detailblock-base">
                                Total Price:&nbsp;
                              </span>
                              <span className="text-detailblock-subtle">
                                {workflowProduct!.product!.price &&
                                additionalInvoiceInfo[index]?.include
                                  ? `$${
                                      workflowProduct!.product!.price *
                                      additionalInvoiceInfo[index]?.quantity
                                    }`
                                  : "$0"}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ),
                )}
              </div>

              {/* ANCHOR: Checkout Button Bank */}
              {getSubmissionWorkflowResponse?.data &&
                additionalInvoiceInfo.length > 0 && (
                  <NewAppButton
                    className="button-regular-general-filled mt-3"
                    onClick={async () => {
                      await createInvoice();
                      const response = createSubmissionResponseMutation.data;
                      if (response) {
                        history.push(
                          `/payment/${createInvoiceMutation.data?.data.body
                            ?.id}${`?instance=${createSubmissionResponseMutation.data?.data.body?.workflowInstance?.id}`}`,
                        );
                      }
                    }}
                  >
                    Proceed to Checkout
                  </NewAppButton>
                )}
            </section>
          </form>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default ProductPage;
