import { Menu, Transition } from "@headlessui/react";
import DotsVerticalIcon from "components/icons/DotsVerticalIcon";
import { ComponentPropsWithoutRef, Fragment } from "react";
import { AppMenuItem } from "types/appMenu";

type MenuComponentProps = ComponentPropsWithoutRef<"div"> & {
  buttonIcon?: React.ReactNode;
  menuItems: AppMenuItem[];
};

const MenuComponent = ({
  buttonIcon,
  menuItems,
  className,
  ...props
}: MenuComponentProps) => {
  return (
    <Menu
      as="div"
      className={`inline-block justify-end h-8 relative text-left ${
        className ?? ""
      }`}
      {...props}
    >
      <Menu.Button
        as="div"
        className="button-regular-neutral-unfilled pt-0 hover:cursor-pointer"
      >
        {buttonIcon || (
          <DotsVerticalIcon className="text-neutral-mid-550 w-3.5 h-3.5" />
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="popovermenu-container absolute right-2.5 -bottom-2 z-10 min-w-30 origin-top-right flex flex-col">
          {menuItems.map((item, index) => (
            <Menu.Item key={index}>
              {() => (
                <div
                  className="popovermenu-item flex gap-0.5 items-center pt-1 pb-1 first:pt-0 last:pb-0"
                  onClick={(e) => {
                    e.preventDefault();
                    item?.onSelect && item.onSelect();
                  }}
                >
                  <span>{item?.icon}</span>
                  <span>{item.label}</span>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MenuComponent;
