import { Form, Formik, FormikProps, FormikValues } from "formik";
import * as yup from "yup";

import React, {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from "react";

import { flattenChildComponents } from "utils/flatten-child-components";

import NewAppCheckbox from "./NewAppCheckbox";
import NewAppCombobox from "./NewAppCombobox";
import NewAppNumberInput from "./NewAppNumberInput";
import NewAppSelectMenu from "./NewAppSelectMenu";
import NewAppSwitch from "./NewAppSwitch";
import NewAppTextInput from "./NewAppTextInput";
import NewAppUSDInput from "./NewAppUSDInput";
export declare type yupAnyObject = Record<string, any>;
type NewAppFormProps = ComponentPropsWithoutRef<"form"> & {
  children: React.ReactNode; // Change the type of children to React.ReactNode
  handleSubmit: (values: FormikValues) => void;
  handleChanged: (values: FormikValues) => void;
  overrideInitialValues?: FormikValues | null;
  yupValidation?: yupAnyObject;
};

const NewAppForm = forwardRef(
  (
    {
      children,
      handleSubmit,
      handleChanged,
      overrideInitialValues,
      yupValidation,
      ...props
    }: NewAppFormProps,
    ref: ForwardedRef<FormikProps<FormikValues> | undefined>,
  ) => {
    const [formValues, setFormValues] = useState<FormikValues | undefined>();
    useEffect(() => {
      if (!formValues) return;
      handleChanged(formValues);
    }, [formValues, handleChanged]);

    useEffect(() => {
      if (overrideInitialValues) {
        setFormValues(overrideInitialValues);
      }
    }, [overrideInitialValues]);

    // todo: this should just pass through initialValues as a prop
    const initialValues: FormikValues = overrideInitialValues ?? {};
    const flattenedChildren = flattenChildComponents(children);
    const yupSchema = yupValidation ?? yup.object({});

    flattenedChildren.forEach((child: any) => {
      if (!overrideInitialValues) {
        if (
          child.type === NewAppTextInput ||
          child.type === NewAppNumberInput ||
          child.type === NewAppUSDInput
        ) {
          initialValues[child.props.name] = child.props.initalValue ?? "";
        }
        if (child.type === NewAppSwitch || child.type === NewAppCheckbox) {
          initialValues[child.props.name] = child.props.initalValue ?? false;
        }
        if (child.type === NewAppCombobox || child.type === NewAppSelectMenu) {
          initialValues[child.props.name] =
            child.props.defaultOption?.value ?? undefined;
        }
      }
    });

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={(formikActions) => {
          if (formikActions) {
            setFormValues(formikActions.values);
          }
          if (ref) {
            if (typeof ref === "function") {
              ref(formikActions);
            } else {
              ref.current = formikActions;
            }
          }
        }}
        validationSchema={yupSchema}
      >
        <Form {...props}>{children}</Form>
      </Formik>
    );
  },
);
NewAppForm.displayName = "NewAppForm";
export default NewAppForm;
