import {
  CreateSubmissionResponse,
  GetProfiles,
  fetchApiQuery,
  useApiQuery,
} from "@api";
import { IonSpinner } from "@ionic/react";
import {
  ApiResponse,
  ProfileLiteView,
  ProfileView,
  SessionView,
  SubmissionResponseInput,
  SubmissionWorkflowInstanceView,
} from "@parthenon-management/pillar-types";
import { FormDesign } from "@parthenon-management/pillar-types/dist/src/ui/components/surveyJS/types/data";
import { useApiMutation } from "api/client/api-mutation";
// import { GeocodeResponse } from "api/routes/geocode";
import { GetSocietyDisclosures } from "api/routes/disclosure";
import { GetCustomQuestion } from "api/routes/surveyJS";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import {
  ChoicesRestful,
  FunctionFactory,
  Model,
  StylesManager,
  SurveyModel,
} from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import { defaultEmailFromProfile } from "utils/defaultEmailFromProfile";
import { getSocietyId } from "utils/getSocietyId";
import { getProfileId } from "utils/sessionStorage/user";
import { SurveyJSViewMode } from "../../constants";
import { coauthors } from "./shared/coauthors";
import { cospeakers } from "./shared/cospeakers";
import { degree_question } from "./shared/degree_question";
import { buildDisclosureQuestions } from "./shared/disclosure_questions";
import { events } from "./shared/events";
import { profileInstitutionTypeQuestion } from "./shared/institution-type/question";
import { login_details_question } from "./shared/login_details_question";
import { multiple_choice_products } from "./shared/products/multiple-choice-question";
import { single_choice_products } from "./shared/products/single-choice-question";
import { profileAddressContactInformationQuestion } from "./shared/profile-address-contact-information/question";
import { profile_search } from "./shared/profile_search";
import { profile_type_question } from "./shared/profile_type";
import { custom_properties } from "./shared/properties";
import { setupChoicesFromPillar } from "./shared/setupChoicesFromPillar";

StylesManager.applyTheme("defaultV2");

enum PartialSaveState {
  RUNNING,
  QUEUED,
  IDLE,
}

export interface AdditionalInfoSubmission {
  profileId?: number;
  submissionProcessId?: number;
  submissionResponseId?: number;
  submissionWorkflowId?: number;
  workflowComplexId?: number;
  workflowInstanceId?: number;
}

//TODO: Type this by the onComplete event's parameters for SurveyJS. Do not manually build this type.
export type SurveyJSOnComplete = (
  sender: SurveyModel,
  options: {
    clearSaveMessages: (text?: string) => void;
    showSaveInProgress: (text?: string) => void;
    showSaveSuccess: (text?: string) => void;
    showSaveError: (text?: string) => void;
  },
) => void;

export type SurveyPreviewOnComplete = (
  sender: SurveyModel,
  options: {
    clearSaveMessages: (text?: string) => void;
    showSaveInProgress: (text?: string) => void;
    showSaveSuccess: (text?: string) => void;
    showSaveError: (text?: string) => void;
  },

  apiResponse: AxiosResponse<
    ApiResponse<SubmissionWorkflowInstanceView>,
    unknown
  >,
) => Promise<void> | void;

export interface SurveyPreviewProps
  extends React.ComponentProps<typeof Survey> {
  formDesign: FormDesign;
  formData?: JSON;
  formId?: number;
  assignmentId?: number;
  isReadOnly?: boolean;
  invoiceId?: string | undefined;
  additionalInfo?: AdditionalInfoSubmission;
  onComplete?: SurveyPreviewOnComplete;
  session: SessionView;
  profile?: ProfileView;
}

export const SurveyPreview = ({
  profile,
  session,
  formDesign,
  formData,
  formId,
  assignmentId,
  isReadOnly,
  invoiceId,
  onComplete,
  additionalInfo,
  ...props
}: SurveyPreviewProps) => {
  //We do not use a stated value here because this does not need linked to the render cycle.
  //In fact, using state here produces race condition problems.
  let localAdditionalInfo = additionalInfo;
  let partialSaveSurvey:
    | { formDesign: FormDesign; formData: JSON }
    | undefined = undefined;
  let partialSaveState: PartialSaveState = PartialSaveState.IDLE;
  const queryParams = new URLSearchParams(location.search);
  let submissionResponseId =
    Number(queryParams.get("submissionResponseId")) ?? undefined;

  const [model, setModel] = useState<SurveyModel | undefined>(undefined);
  const disclosureQuery = useApiQuery(
    GetSocietyDisclosures,
    {
      societyId: session.societyId!.toString(),
    },
    undefined,
    undefined,
    { enabled: !!session.societyId },
  );

  // const [localGeocode, setLocalGeocode] = useState<GeocodeView>();

  const createWorkflowSubmissionMutation = useApiMutation(
    CreateSubmissionResponse,
    {
      societyId: session.society!.id!.toString(),
    },
    { timeout: 30 * 1000 },
  );

  const tag_includes = (tags: string[]): boolean => {
    if (session.societyAdmin) {
      return true;
    }
    if (tags.length === 0) {
      return true;
    }
    if (
      profile?.tags
        ?.map((userTags: any) => userTags.name)
        .some((userTags: any) => tags.includes(userTags))
    ) {
      return true;
    }
    return false;
  };

  // THIS NEEDS TO BE ABSTRACTED OUT AND REFACTORED IF THE SOCIETY DOES NOT HAVE IT ENABLED
  // sjs supports async for visible if it isn't a choice
  // https://surveyjs.answerdesk.io/ticket/details/t5425/call-external-api-based-on-form-control-value
  // const geo_code = async function (this: any, params: any) {
  //   const postalCode = params[0];
  //   const questionName = params[1];

  //   const results = await fetchApiQuery(
  //     GeocodeResponse,
  //     {
  //       societyId: getSocietyId().toString(),
  //     },
  //     { postal_code: postalCode },
  //   );
  //   setLocalGeocode(results.data.body);
  //   this.returnResult(true);

  //   //note, sjs will ignore this value and only use the returnResult value
  //   return;
  // };
  // //last param here says it is async
  // FunctionFactory.Instance.register("geo_code", geo_code, true);
  ChoicesRestful.onBeforeSendRequest = (sender, options) => {
    //TODO: I belive everywhere we are rewriting the choices url,  could be doing it here instead more consistently for less variation per qeustion between hub/admin. ~Gary
    options.request.withCredentials = true;
  };

  // THIS NEEDS TO BE ABSTRACTED OUT AND REFACTORED IF THE SOCIETY DOES NOT HAVE IT ENABLED
  // useEffect(() => {
  //   if (model?.getQuestionByName("chapter_products") != undefined) {
  //     const question = model.getQuestionByName(
  //       "chapter_products",
  //     ) as QuestionCompositeModel;
  //     const minDistance = question["distance_filter"];

  //     if (minDistance != undefined && minDistance > 0) {
  //       let visibleCount = 0;
  //       for (const choice of (
  //         question.findQuestionByName("products") as QuestionCheckboxModel
  //       ).choices as ItemValue[]) {
  //         const questionView = session.society?.products?.find(
  //           (q) => q.id == choice.value,
  //         );
  //         const localDistance = haversine_distance(localGeocode!, {
  //           lat: (questionView!.additionalInfo! as any).lat,
  //           lng: (questionView!.additionalInfo! as any).lng,
  //         });

  //         const isWithinDistance = localDistance <= question["distance_filter"];

  //         if (isWithinDistance) {
  //           visibleCount++;
  //         }
  //         question.setPropertyValue(
  //           choice.value + "_geocode_visible",
  //           isWithinDistance,
  //         );
  //       }

  //       (model.getQuestionByName("localGeocode") as QuestionTextModel).value =
  //         visibleCount;
  //     }
  //   }
  // }, [localGeocode]);

  //params [location or Panel string, cospekersContent[] ]
  const get_speaker_permissions = function (this: any, params: any) {
    const panelName = params[0];
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cospeakersContent = params[1] as any[];
    const selectedProfileId = params[2] as any;
    const selectedRole = params[3] as any;

    const isFormCreator = (formData as any).profile_id === profile?.id;
    const designatedRole = cospeakersContent?.find(
      (cospeaker: any) => cospeaker?.ProfileSearch?.ProfileId === profile?.id,
    )?.Role;

    //simplify the if logic for future maintainers:
    // no-cospeakers, return true
    // if you made the form, return true
    // if you are assigned the role of chair or co-chair in the cospeaker content, return true
    if (
      !cospeakersContent ||
      cospeakersContent.length === 0 ||
      (formData as any).profile_id === undefined ||
      isFormCreator ||
      designatedRole === "Chair" ||
      designatedRole === "Co-Chair"
    ) {
      return true;
    }

    // chair and co-chair handled above for being able to edit "speaker" fields
    switch (panelName) {
      case "top":
      case "bottom":
        return false;
        break;
      case "speaker":
        //only speakers can edit their panels
        return selectedProfileId === profile?.id;
      default:
        return false;
    }
  };
  //Register the custom function
  FunctionFactory.Instance.register(
    "get_speaker_permissions",
    get_speaker_permissions,
  );

  const email_speaker_string = function (this: any, params: any) {
    const stringifyProfileView = params[0];
    const symposiaTitle = params[1];
    const symposiaRole = params[2];
    const profileView = JSON.parse(stringifyProfileView) as ProfileLiteView;

    const address = defaultEmailFromProfile(
      JSON.stringify(stringifyProfileView) as unknown as ProfileLiteView,
    );
    return `mailto:${address}?subject=${profileView?.societyUser?.firstName} - ${symposiaTitle} - ${symposiaRole}`;
  };
  //Register the custom function
  FunctionFactory.Instance.register(
    "email_speaker_string",
    email_speaker_string,
  );

  const get_profile_view_name = function (this: any, params: any) {
    const stringifyProfileView = params[0];
    const profileView = JSON.parse(stringifyProfileView) as ProfileLiteView;

    return profileView
      ? `${profileView?.societyUser?.firstName} ${profileView?.societyUser?.lastName}`
      : "";
  };

  //Register the custom function
  FunctionFactory.Instance.register(
    "get_profile_view_name",
    get_profile_view_name,
  );

  FunctionFactory.Instance.register("tag_includes", tag_includes);

  const savePartialSubmissionResponse = async (
    survey: SurveyModel,
    sender: any,
  ) => {
    // Set the survey we intend to save. This ensures the next run will have the most up
    // to date data.
    partialSaveSurvey = { formDesign: survey.toJSON(), formData: survey.data };

    // Run depends on the current state.
    switch (partialSaveState) {
      //If idle, set to running and run the save. After, set back to idle.
      case PartialSaveState.IDLE:
        partialSaveState = PartialSaveState.RUNNING;
        await runPartialSave(sender);
        partialSaveState = PartialSaveState.IDLE;
        break;
      //If actively running, queue another run on a timeout of 2 seconds and set to queued.
      case PartialSaveState.RUNNING:
        partialSaveState = PartialSaveState.QUEUED;
        console.log("Running queued partial save");
        setTimeout(() => {
          savePartialSubmissionResponse(survey, sender);
        }, 2000);
        break;
      //If already queued, do nothing. The partialSaveSurvey is already updated for the next run.
      case PartialSaveState.QUEUED:
        break;
    }
  };

  const runPartialSave = async (sender: any) => {
    let newWorkflowInstance:
      | {
          workflowComplexId: number;
          payload?: JSON;
        }
      | undefined = undefined;
    if (!localAdditionalInfo?.workflowInstanceId) {
      newWorkflowInstance = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        workflowComplexId:
          localAdditionalInfo?.workflowComplexId ?? sender.workflowComplexId, //TODO: That localAdditionalInfo should be removed and prefer the workflowcomplex in sender. Left in for now to support legacy code.
        // payload: {} as JSON,
      };
    }

    const newSubmissionResponse: SubmissionResponseInput = {
      id: submissionResponseId,
      societyId: session.societyId!,
      profileId: profile?.id,
      submissionProcessId: localAdditionalInfo?.submissionProcessId,
      workflowInstanceId: localAdditionalInfo?.workflowInstanceId,
      formDesign: partialSaveSurvey!.formDesign,
      formId: formId,
      responseData: partialSaveSurvey!.formData,
      isPartial: true,
    };

    const workflowSubmissionInstanceData =
      await createWorkflowSubmissionMutation.mutateAsync({
        newSubmissionResponse: newSubmissionResponse,
        newWorkflowInstance: newWorkflowInstance,
      });

    if (
      !createWorkflowSubmissionMutation.isError &&
      workflowSubmissionInstanceData.data.body?.submissionResponse
    ) {
      const workflowSubmissionData =
        workflowSubmissionInstanceData.data?.body?.submissionResponse;
      localAdditionalInfo = {
        ...localAdditionalInfo,
        submissionResponseId: workflowSubmissionData?.id,
        profileId: workflowSubmissionData?.profileId,
      };
      submissionResponseId = workflowSubmissionData?.id;
    }
  };

  const onValueChangedMethod = (survey: SurveyModel, sender: any) => {
    const profileId = getProfileId();
    if (profileId) {
      // TODO: make sure this is a new submission, not after it has been submitted
      savePartialSubmissionResponse(survey, sender);
    }
  };

  const saveWorkflow = async (sender: Model) => {
    let newWorkflowInstance:
      | {
          workflowComplexId: number;
          payload?: JSON;
          submissionAssignmentId: number | undefined;
        }
      | undefined = undefined;
    const activeSubmissionAssignments =
      session.profile?.submissionAssignments?.filter(
        (submissionAssignment) => submissionAssignment.isActive,
      );
    const isActiveSubmissionAssignment = activeSubmissionAssignments?.find(
      (submissionAssignment) => submissionAssignment.form?.id === formId,
    );
    if (!localAdditionalInfo?.workflowInstanceId) {
      newWorkflowInstance = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        workflowComplexId:
          localAdditionalInfo?.workflowComplexId ?? sender.workflowComplexId, //TODO: That localAdditionalInfo should be removed and prefer the workflowcomplex in sender. Left in for now to support legacy code.
        payload: {
          ...sender.data,
          submissionProcessId: localAdditionalInfo?.submissionProcessId,
          formId,
        },
        submissionAssignmentId:
          assignmentId ?? isActiveSubmissionAssignment?.id ?? undefined,
      };
    }

    const newSubmissionResponse: SubmissionResponseInput = {
      id: localAdditionalInfo?.submissionResponseId,
      societyId: session.societyId!,
      profileId: profile?.id,
      submissionProcessId: localAdditionalInfo?.submissionProcessId,
      workflowInstanceId: isActiveSubmissionAssignment
        ? isActiveSubmissionAssignment.workflowInstance?.id
        : localAdditionalInfo?.workflowInstanceId,
      formDesign: sender.toJSON(),
      formId: formId,
      responseData: sender.data,
    };

    const workflowResponse = await createWorkflowSubmissionMutation.mutateAsync(
      {
        newWorkflowInstance: newWorkflowInstance,
        newSubmissionResponse: newSubmissionResponse,
      },
    );

    if (
      createWorkflowSubmissionMutation.data?.data.body?.workflowInstance
        ?.payload &&
      "profile_id" in
        createWorkflowSubmissionMutation.data.data.body.workflowInstance
          .payload &&
      createWorkflowSubmissionMutation.data.data.body.workflowInstance.payload
    ) {
      model?.setValue(
        "profile_id",
        createWorkflowSubmissionMutation.data.data.body.workflowInstance
          .payload,
      );
    }
    return workflowResponse;
  };

  //TODO: We should compose this type from the params of the onComplete event from SurveyJS
  //combined with the needed additional data and different return type
  const defaultCompleteMethod = async (
    sender: SurveyModel,
    options: {
      clearSaveMessages: (text?: string) => void;
      showSaveInProgress: (text?: string) => void;
      showSaveSuccess: (text?: string) => void;
      showSaveError: (text?: string) => void;
    },
    afterComplete?: SurveyPreviewOnComplete,
  ) => {
    options.clearSaveMessages();
    options.showSaveInProgress("Saving your response...");
    try {
      if (!sender.data.profile_id && session.profileId) {
        sender?.setValue("profile_id", session.profileId);
      }
      const result = await saveWorkflow(sender);
      if (afterComplete) {
        afterComplete(sender, options, result);
      } else {
        options.showSaveSuccess();
      }
    } catch (error) {
      console.error(error);
      //TODO: When this errors, the message shows but the try again button does nothing.
      //Should re-display form or retry request. On error, it just show the "thank you" page now.
      if (error instanceof Error) {
        options.showSaveError(error.message);
      }
    }
  };

  const completeMethod: SurveyJSOnComplete = async (sender, options) => {
    await defaultCompleteMethod(sender, options, onComplete);
  };

  //chatgpt generated to update any {societyId} in the form_design
  // const pointRelativeURLtoPillar = (obj: any, id: string): any => {
  //   if (obj && typeof obj === "object") {
  //     if (Array.isArray(obj)) {
  //       // If it's an array, apply the function to each element
  //       return obj.map((o) => pointRelativeURLtoPillar(o, id));
  //     } else {
  //       // If it's an object, apply the function to each property
  //       for (const key in obj) {
  //         if (Object.prototype.hasOwnProperty.call(obj, key)) {
  //           obj[key] = pointRelativeURLtoPillar(obj[key], id);
  //         }
  //       }
  //     }
  //   } else if (
  //     typeof obj === "string" &&
  //     obj.match(`^/api/v1/society/${session.societyId}`)
  //   ) {
  //     return (obj = process.env.REACT_APP_PILLAR_API_URL + obj);
  //   }

  //   // Return the updated object
  //   return obj;
  // };

  const setupSurveyModel = async () => {
    custom_properties(session.society!);

    await Promise.all([
      degree_question(),
      login_details_question(session, session.profile!),
      single_choice_products(session.society?.products, undefined, session),
      multiple_choice_products(
        session.society?.products,
        session?.society?.tags.map((tag) => tag.name ?? ""),
        session,
      ),
      profileInstitutionTypeQuestion(session.society?.institutionType ?? []),
      profile_type_question(),
      cospeakers(),
      coauthors(),
      events(),
      profile_search(),
      buildDisclosureQuestions(disclosureQuery.data?.data.body ?? []),
      degree_question(),
    ]);

    await profileAddressContactInformationQuestion(
      session.society ?? undefined,
    );

    console.log("formDesign when making the model?", formDesign);
    const newModel = new Model(formDesign);
    setupChoicesFromPillar(session.society!, newModel);

    newModel.onAfterRenderQuestion.add(function (survey, options) {
      if (
        options.question.getType() === "paneldynamic" &&
        options.question.name === "CospeakersContent"
      ) {
        const isPermitted = get_speaker_permissions([
          "top",
          options.question.getAllValues().CospeakersContent,
        ]);
        options.question.allowAddPanel = isPermitted;
        options.question.allowRemovePanel = isPermitted;
      }
    });

    newModel.onChoicesLazyLoad.add(async (sender, options) => {
      //Cospeaker question
      if (options.question.name === "ProfileIdSearch") {
        //TODO: This should be decoupled out to a seperate function specific to that question instead of sitting here.
        const profilesResult = await fetchApiQuery(
          GetProfiles,
          {
            societyId: getSocietyId().toString(),
          },
          { text: options.filter, type: ["Society_User"] },
        );
        const choices = profilesResult.data.body?.results;
        if (!choices) return;
        const mappedProfiles = choices.map((profile: ProfileLiteView) => ({
          value: profile.id,
          text: `${profile.societyUser?.firstName} ${profile.societyUser?.lastName}`,
          imageLink: profile.picture ?? undefined,
          profile: profile,
        }));
        options.setItems(mappedProfiles, profilesResult.data.body?.pageSize!);
      } else if (options.question.name === "email_option") {
        //TODO: This should be decoupled out to a seperate function specific to that question instead of sitting here.
        const questionResult = await fetchApiQuery(GetCustomQuestion, {
          societyId: getSocietyId().toString(),
          questionName: options.question.name,
        });
        if (questionResult.data.body) {
          options.setItems(
            Object.values(questionResult.data.body.choices).map((choice: any) =>
              choice.value.toString(),
            ),
            questionResult.data.body.choices.length,
          );
        }
      }
    });
    newModel.onGetChoiceDisplayValue.add(async (sender, options) => {
      //Cospeaker question
      if (options.question.name === "Speaker") {
        //TODO: This should be decoupled out to a seperate function specific to that question instead of sitting here.
        const profilesResult = await fetchApiQuery(
          GetProfiles,
          {
            societyId: getSocietyId().toString(),
          },
          { "profile-id": options.values, type: ["Society_User"] },
        );
        const choices = profilesResult.data.body?.results;
        if (!choices) return;
        const mappedProfiles = choices.map(
          (profile: ProfileLiteView) =>
            `${profile.societyUser?.firstName} ${profile.societyUser?.lastName} - ${profile.societyUser?.affiliation}`,
        );
        options.setItems(mappedProfiles);
      }
    });

    newModel.clearInvisibleValues = "none";
    if (isReadOnly) {
      newModel.mode = SurveyJSViewMode.Read_Only;
    }
    if (invoiceId !== undefined) {
      newModel.completeText =
        session.society?.societySettingsPublic
          ?.outdatedProfileNavigateToInvoiceText ?? "";
    }
    if (formData && Object.keys(formData as object).length > 0) {
      newModel.data = formData;
    }
    newModel.onValueChanged.add(onValueChangedMethod);
    newModel.onComplete.add(completeMethod);
    newModel.completedHtml =
      "<h6>Please wait while we process your form....</h6>";
    setModel(newModel);
  };

  useEffect(() => {
    // We rely on asynchronous methods to build custom questions, therefore we
    // must set the state inside a useEffect to allow for awaiting the async methods.
    if (!model && disclosureQuery.data) {
      setupSurveyModel();
      console.log("form", formDesign, "hello");
    }
    // We don't want this to rerun when params change because
    // it causes a render loop. In fact, we really only want this to run once.
    // This is why there are no watched deps and the check for model existance before setting up.
  }, [disclosureQuery.data]);

  return model ? (
    <Survey className="survey-js" model={model} {...props} />
  ) : (
    <IonSpinner />
  );
};
