import { SjsCustomQuestion } from "@components/surveyJS/types/sjs-custom-question";
import { InstitutionTypeView } from "@parthenon-management/pillar-types";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Question,
  QuestionCustomModel,
  Serializer,
  SurveyModel,
  ItemValue,
} from "survey-core";
export const questionName = SjsCustomQuestion.profileinstitutiontype;

export const profileInstitutionTypeQuestion = async (
  institionTypes: InstitutionTypeView[],
) => {
  const ProfileInstitutionTypeQuestion: ICustomQuestionTypeConfiguration = {
    name: questionName,
    questionJSON: {
      name: questionName,
      type: "dropdown",
      placeholder: "Select an institution type...",
      choices: institionTypes.map((type) => {
        return type.name;
      }),
    },
    onItemValuePropertyChanged: () => {
      //null
    },
    onCreated(): void {
      //null
    },
    onInit() {
      //
    },
    onLoaded(question: QuestionCustomModel) {
      if (!question.getSurvey()) {
        return;
      }
      if (institionTypes) {
        const type_list = institionTypes.map((value: InstitutionTypeView) => {
          return new ItemValue(value.id, value.name);
        });
        Serializer.setObjPropertyValue(question, "institutionTypes", type_list);
      }
      const surveyModel = question.getSurvey() as unknown as SurveyModel;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      surveyModel.onValueChanged.add((sender: SurveyModel, options: any) => {
        const surveyData = sender.getSurveyData();
        if (
          surveyData.getValue("profile_type") === "Institution" &&
          surveyData.getValue(questionName)
        ) {
          surveyData.setValue(
            "institution_type",
            surveyData.getValue(questionName),
            false,
            false,
          );
        }
      });

      const choices = Serializer.getObjPropertyValue(
        question,
        "institutionTypes",
      );
      question.choices = choices;
      const defaultValueProperty = Serializer.findProperty(
        questionName,
        "defaultValue",
      );
      defaultValueProperty.setChoices(choices);
      const defaultValue = Serializer.getObjPropertyValue(
        question,
        "defaultValue",
      );
      if (defaultValue) {
        question.value = defaultValue;
      }
      Serializer.setObjPropertyValue(
        question,
        "clearIfInvisible",
        "onComplete",
      );
      question.getPropertyByName("clearIfInvisible").visible = false;
    },
    onAfterRender: function (): void {
      //null
    },
    onAfterRenderContentElement: function (): void {
      //null
    },
    onValueChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: QuestionCustomModel,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      newValue: string,
    ): void {
      //null
    },
    onPropertyChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      //null
    },
  };

  if (!ComponentCollection.Instance.getCustomQuestionByName(questionName)) {
    ComponentCollection.Instance.add(ProfileInstitutionTypeQuestion);
  }
  return ProfileInstitutionTypeQuestion;
};
