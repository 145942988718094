import { DateTime } from "luxon";

/**
 * Predefined formats for Luxon date formatting.
 *
 * @file https://moment.github.io/luxon/#/formatting?id=standalone-vs-format-tokens
 */
export const enum LuxonDateFormat {
  /**
   * 'Wed' // day of the week, as an abbreviate localized string.
   */
  ccc = "ccc",
  /**
   * 'Wednesday' // day of the week, as an unabbreviated localized string.
   */
  cccc = "cccc",
  /**
   * 'Aug' // month as an abbreviated localized string.
   */
  LLL = "LLL",
  /**
   * 'August' // month as an unabbreviated localized string.
   */
  LLLL = "LLLL",
  /**
   * '2014' // year, unpadded.
   */
  y = "y",
  /**
   * '14' // year, padded to 2 digits.
   */
  yy = "yy",
  /**
   * '2014' // four- to six- digit year, pads to 4.
   */
  yyyy = "yyyy",
  /**
   * '9/4/2017' // localized numeric date.
   */
  D = "D",
  /**
   * 'Aug 6, 2014' // localized date with abbreviated month.
   */
  DD = "DD",
  /**
   * 'Aug 6, 2014' will be '06' // day of the month, not padded.
   */
  d = "d",
  /**
   * 'Aug 6, 2014' will be '06' // day of the month, padded to 2
   */
  dd = "dd",
  /**
   * 'August 6, 2014' // localized date with full month.
   */
  DDD = "DDD",
  /**
   * 'Wednesday, August 6, 2014' // localized date with full month and weekday.
   */
  DDDD = "DDDD",
  /**
   * '9:07 AM' // localized time.
   */
  t = "t",
  /**
   * '1:07:04 PM' // localized time with seconds.
   */
  tt = "tt",
  /**
   * '8/6/2014, 1:07 PM' short localized date and time.
   */
  f = "f",
  /**
   * 'Aug 6, 2014, 1:07 PM' // less short localized date and time.
   */
  ff = "ff",
  /**
   * 'August 6, 2014, 1:07 PM EDT' // verbose localized date and time.
   */
  fff = "fff",
  /**
   * '8/6/2014, 1:07:04 PM' // short localized date and time with seconds.
   */
  F = "F",
  /**
   * 'Aug 6, 2014, 1:07:04 PM' // less short localized date and time with seconds.
   */
  FF = "FF",
}
/**
 * Formats a date using the Luxon library.
 *
 * @param {string|Date} date - The date to format.
 * @param {LuxonDateFormat} format - The format to use.
 * @returns {string} The formatted date as string.
 */
export const formatLuxonDate = (
  date: string | Date,
  format: LuxonDateFormat,
): string => {
  if (!date) {
    return "";
  }

  const isoDate = DateTime.fromISO(date.toString());
  if (!isoDate.isValid) {
    return "";
  }

  return isoDate.toFormat(format);
};
