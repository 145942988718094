import { SurveyPreview } from "@components/surveyJS/SurveyPreview";
import { useHistory, useLocation, useParams } from "react-router";
import { SessionView } from "@parthenon-management/pillar-types";
import { useApiQuery, GetForm } from "@api";
import LoadingPage from "@components/common/LoadingPage";
import PageComponent from "@components/common/PageComponent";
import useSession from "hooks/useSession";
import { getSocietyId } from "utils/getSocietyId";
import { getProfileId } from "utils/sessionStorage/user";

interface RouteParams {
  id: string;
}

const FormPage = () => {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const location = useLocation();
  const session = useSession();
  const searchQueryParams = new URLSearchParams(location.search);
  const instanceId = searchQueryParams.get("workflowInstanceId");

  const { data: getFormResponse, isLoading: getFormIsLoading } = useApiQuery(
    GetForm,
    { societyId: getSocietyId().toString(), formId: id },
    { profileId: getProfileId() },
  );

  const form = !getFormResponse?.data
    ? undefined
    : JSON.parse(JSON.stringify(getFormResponse?.data!.body!.formDesign));

  const responseData = JSON.parse("{}");
  const assignmentId = searchQueryParams.get("assignmentId");
  const uiMsg = searchQueryParams.get("message");

  if (getFormIsLoading) {
    return <LoadingPage />;
  }
  return (
    <PageComponent>
      <PageComponent.Header>Form</PageComponent.Header>
      <PageComponent.Content>
        {uiMsg && (
          <div className="flex justify-center p-2">
            <h4 className="font-medium w-max">{uiMsg}</h4>
          </div>
        )}
        {getFormResponse?.data && (
          <SurveyPreview
            additionalInfo={{
              profileId: getProfileId(),
              workflowInstanceId: Number(instanceId),
            }}
            profile={session.profile ?? undefined}
            formDesign={form}
            formData={responseData}
            formId={Number(id)}
            //FOR SEAN form data here? idk brain no work good
            assignmentId={Number(assignmentId)}
            onComplete={async () => {
              session.refreshSession();
              history.push(`/workflow/new/${getFormResponse?.data?.body!.id}`);
            }}
            session={session as SessionView}
          />
        )}
      </PageComponent.Content>
    </PageComponent>
  );
};

export default FormPage;
