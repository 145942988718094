import React, { ComponentPropsWithoutRef } from "react";
import { FormikValues, useFormikContext } from "formik";
import NewAppInput from "./NewAppInput";
import { NewAppInputProps } from "types/newAppForm";

export type NewAppTextInputProps = NewAppInputProps &
  Omit<ComponentPropsWithoutRef<"input">, "id" | "name" | "value" | "type">;

const NewAppTextInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  onChange,
  ...props
}: NewAppTextInputProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  return (
    <NewAppInput
      name={name}
      labelClassName={labelClassName}
      additionalClasses={additionalClasses}
      label={label}
    >
      <input
        required={props.required}
        placeholder={props?.placeholder ?? undefined}
        type="text"
        value={values[name]}
        name={name}
        id={name}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          onChange?.(e);
        }}
        {...props}
      />
    </NewAppInput>
  );
};

export default NewAppTextInput;
