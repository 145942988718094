import PageComponent from "components/common/PageComponent";
import OrderSummary from "components/checkout/OrderSummary";
import React from "react";

import CheckoutInformationFormHOC from "components/checkout/CheckoutInformationForm";

const CheckoutPage = () => {
  return (
    <PageComponent>
      <PageComponent.Header>Payment</PageComponent.Header>
      <PageComponent.Content>
        <div className="flex h-full md:flex-col md:bg-neutral-light">
          <OrderSummary className="h-full w-1/3 min-w-8xl overflow-y-auto md:w-full md:overflow-y-visible" />
          <CheckoutInformationFormHOC className="overflow-y-auto md:w-full md:overflow-y-visible" />
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default CheckoutPage;
