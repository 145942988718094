import { useQuery } from "@tanstack/react-query";
import { getInvoiceById } from "api/invoiceApi";
import { Form, Formik, useFormikContext } from "formik";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CheckoutForm } from "types/checkoutPage/checkout-form";
import { CardData } from "types/payment/accept-suite";
import { paymentFormvalidationSchema } from "yupValidationSchema/payment-form-schema";
import { INVOICE_STATUS } from "../../constants/index";

import {
  AddressView,
  InvoiceLiteView,
} from "@parthenon-management/pillar-types";
import {
  CheckoutDataModel,
  PaymentRequestAcceptSuite,
} from "@parthenon-management/pillar-types/dist/src/server/types/userhub-checkout";
import { completeCheckout, submitSecuredPaymentData } from "api/paymentAPI";
import { getSetting } from "api/settings";
import { AxiosError } from "axios";
import LoadingPage from "components/common/LoadingPage";
import { society_setting_name } from "constants/settings/society_setting_name";
import useSession from "hooks/useSession";
import { AddressForm } from "./AddressForm";
import CheckPaymentForm from "./CheckPaymentForm";
import PaymentForm from "./PaymentForm";
const defaultAddress = {
  id: 0,
  line1: "",
  line2: null,
  line3: null,
  city: null,
  state: "",
  country: null,
  region: null,
  postal_code: "",
};

const defaultCardData = {
  cardNumber: "",
  cardCode: "",
  zip: "",
  fullName: "",
  expMonthYear: "",
};

const enum PaymentType {
  CHECK = "check",
  CARD = "card",
}

const initialValues: CheckoutForm = {
  cardData: {
    ...defaultCardData,
  },
  shippingAddressData: {
    ...defaultAddress,
  },
  billingAddressData: {
    ...defaultAddress,
  },
  isSameAsBilling: false,
};

type CheckoutInformationFormProps = ComponentPropsWithoutRef<"form">;

const CheckoutInformationForm = ({
  className,
  ...props
}: CheckoutInformationFormProps) => {
  const history = useHistory();
  const session = useSession();
  const { setFieldValue, values } = useFormikContext<CheckoutForm>();
  const [cardDetails, setCardDetails] = useState<CardData>({
    cardNumber: "",
    month: "",
    year: "",
    cardCode: "",
    zip: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.CARD);
  const [billingAddressData, setBillingAddressData] = useState<AddressView>({
    line1: "",
    line2: "",
    line3: "",
    city: "",
    state: "",
    country: "",
    region: "",
    postalCode: "",
  });
  const [shippingAddressData, setShippingAddressData] = useState<AddressView>({
    line1: "",
    line2: "",
    line3: "",
    city: "",
    state: "",
    country: "",
    region: "",
    postalCode: "",
  });

  const { invoiceId } = useParams<{ invoiceId: string }>();
  const checkPaymentConfig = useQuery({
    queryKey: ["checkInfo"],
    queryFn: async () => {
      return await getSetting(society_setting_name.check_payment_configuration);
    },
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const invoiceQuery = useQuery<InvoiceLiteView>({
    queryKey: ["invoice", invoiceId],
    queryFn: () => getInvoiceById(Number(invoiceId)),
    refetchOnWindowFocus: false,
    enabled: !!invoiceId,
    staleTime: 1,
  });

  useEffect(() => {
    const fetchAuthorizeNetScript = async () => {
      const script = document.createElement("script");
      script.src =
        session.society?.societySettingsPublic?.authorizeNetAcceptSuiteUrl ??
        "";
      script.async = true;
      document.body.appendChild(script);
    };

    fetchAuthorizeNetScript();
  }, []);

  const handleSubmit = async () => {
    const paymentDetail: CardData = cardDetails;
    try {
      //Sends to authorize to validate card details.
      const securedDataResp = await submitSecuredPaymentData(
        paymentDetail,
        session.society!,
      );

      if (securedDataResp?.messages?.resultCode === "Error") {
        setErrorMessage(
          String(
            securedDataResp?.messages?.message.map((m) => m.text).join(", "),
          ),
        );
        console.error(
          "There was an error sending payment info.",
          securedDataResp?.messages?.message,
        );
        return;
      }

      billingAddressData.postalCode = cardDetails.zip ?? "";
      shippingAddressData.postalCode = cardDetails.zip ?? "";

      const paymentRequest: PaymentRequestAcceptSuite = {
        transactionType: "authCaptureTransaction",
        amount: invoiceQuery?.data?.total.toString() ?? "",
        payment: {
          opaqueData: securedDataResp.opaqueData,
        },
        billTo: {
          firstName: session.societyUser?.firstName ?? "",
          lastName: session.societyUser?.lastName ?? "",
          company: "",
          address: billingAddressData?.line1 ?? "",
          city: billingAddressData?.city ?? "",
          state: billingAddressData?.state ?? "",
          zip: billingAddressData?.postalCode ?? "",
          country: "USA",
        },
        invoiceId: invoiceId?.toString() ?? "",
      };

      const checkoutData: CheckoutDataModel = {
        societyId: Number(session.society?.id),
        invoiceId: Number(invoiceId),
        shippingAddress: values.isSameAsBilling
          ? billingAddressData
          : shippingAddressData,
        paymentRequestAcceptSuite: paymentRequest,
        profileId: session.profile?.id ?? 0,
      };

      try {
        await completeCheckout(checkoutData);

        //This is intentionally not awaited so that the user can be redirected to the complete page while it refreshes.
        session.refreshSession();

        history.replace("/workflow/complete", {
          message: `Your ${session.society?.abbreviation} payment has been processed successfully. Thank you!`,
        });
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response &&
          error.response.status === 400
        ) {
          setErrorMessage(error.response.data.error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAddressDetails = (addressType: string) => {
    return session.profile?.address?.find(
      (a) => a.use?.map((u) => u.description).includes(addressType),
    );
  };

  const handleCardDetails = (details: {
    cardNumber: number;
    expirationDate: string;
    cvc: number;
    zip: number;
  }) => {
    setCardDetails({
      cardNumber: details.cardNumber?.toString(),
      month: details.expirationDate?.split("/")[0],
      year: details.expirationDate?.split("/")[1],
      cardCode: details.cvc?.toString(),
      zip: details.zip?.toString(),
      fullName: "Test User",
    });
  };

  const handleAddressDetails = (details: AddressView, addressType: string) => {
    if (addressType === "Billing") {
      setBillingAddressData(details);
    } else {
      setShippingAddressData(details);
    }
  };

  if (invoiceQuery.isLoading) {
    return <LoadingPage />;
  }

  const payBtnIsDisabled =
    invoiceQuery?.data?.status === INVOICE_STATUS.PAID ||
    (invoiceQuery?.data?.total ?? 0) === 0;

  return (
    <div className="w-full">
      <Form
        className={`flex flex-col space-y-2 p-2 pt-4.5 ${className ?? ""}`}
        {...props}
      >
        {/* <CheckoutDonation /> */}
        {checkPaymentConfig.data &&
          JSON.parse(checkPaymentConfig.data.value).enabled && (
            <div className="flex justify-center ">
              <button
                type="button"
                className={`mx-2 w-fit whitespace-nowrap ${
                  paymentType == PaymentType.CARD
                    ? "button-regular-general-filled"
                    : "text-neutral-mid-600 text-base"
                }`}
                onClick={() => setPaymentType(PaymentType.CARD)}
              >
                Card
              </button>
              <button
                type="button"
                className={`mx-2 w-fit whitespace-nowrap ${
                  paymentType == PaymentType.CHECK
                    ? "button-regular-general-filled"
                    : "text-neutral-mid-600 text-base"
                }`}
                onClick={() => setPaymentType(PaymentType.CHECK)}
              >
                Check
              </button>
            </div>
          )}
        {paymentType == PaymentType.CARD && (
          <>
            <div className="py-4">
              <AddressForm
                handleAddressDetails={handleAddressDetails}
                addressDetails={getAddressDetails("Billing")}
                addressType={"Billing"}
              />
            </div>
            <section aria-labelledby="billing-heading">
              <div className="flex items-center space-x-1 pb-6">
                <input
                  id="isSameAsBilling"
                  name="isSameAsBilling"
                  type="checkbox"
                  checked={values.isSameAsBilling}
                  onChange={(e) =>
                    setFieldValue("isSameAsBilling", e.target.checked)
                  }
                />
                <label
                  htmlFor="isSameAsBilling"
                  className="text-sm font-medium text-gray-900"
                >
                  Same as shipping information
                </label>
              </div>

              {!values.isSameAsBilling && (
                <div className="pb-6">
                  <AddressForm
                    handleAddressDetails={handleAddressDetails}
                    addressDetails={getAddressDetails("Shipping")}
                    addressType={"Shipping"}
                  />
                </div>
              )}
              <PaymentForm
                invoiceId={invoiceId}
                zip={billingAddressData?.postalCode ?? ""}
                disabled={payBtnIsDisabled}
                errorMessage={errorMessage}
                handleCardDetails={handleCardDetails}
                handleSubmit={handleSubmit}
              />
            </section>

            {invoiceQuery?.data?.status === INVOICE_STATUS.PAID && (
              <div className="text-center text-xs text-status-success">
                Paid
              </div>
            )}
          </>
        )}
        {paymentType == PaymentType.CHECK && <CheckPaymentForm />}
      </Form>
    </div>
  );
};

const CheckoutInformationFormHOC = (props: CheckoutInformationFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => undefined}
      validationSchema={paymentFormvalidationSchema}
      validateOnChange={true}
    >
      <CheckoutInformationForm {...props} />
    </Formik>
  );
};

export default CheckoutInformationFormHOC;
