import { useQuery } from "@tanstack/react-query";
import { FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ComponentPropsWithoutRef } from "react";
import NewAppInput, { NewAppInputProps } from "./NewAppInput";

export type NewAppSelectOption = {
  key: string;
  display: string;
  value?: number | string;
  disabled?: boolean;
};

//TODO: We should consider removing some of these that overlap with select props and just pass those through instead.
export type NewAppSelectMenuProps<T> = NewAppInputProps &
  Omit<ComponentPropsWithoutRef<"select">, "id" | "name" | "value"> & {
    valueProperty: string;
    displayProperty: string;
    selectOptionQuery?: () => Promise<T[]>;
    defaultOption?: NewAppSelectOption;
    localOptionsList?: NewAppSelectOption[];
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeCallbackAction?: (currentValue?: any) => void;
  };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NewAppSelectMenu = <T extends { [key: string]: any }>({
  name,
  valueProperty,
  displayProperty,
  selectOptionQuery,
  defaultOption,
  localOptionsList,
  onChangeCallbackAction,
  label,
  labelClassName,
  additionalClasses,
  onChange,
  ...props
}: NewAppSelectMenuProps<T>) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const value = get(values, name);
  const selectOptions = useQuery(
    ["selectOptions", name],
    () => selectOptionQuery?.() || Promise.resolve([]), // Use an empty array as a default value
    {
      select: (responseData): NewAppSelectOption[] => {
        if (responseData) {
          return responseData?.map((option: T) => {
            return {
              key:
                option[valueProperty ?? "id"] ??
                option[valueProperty ?? "value"],
              display: option[displayProperty ?? "display"],
              value: option[valueProperty ?? "value"],
            };
          });
        } else {
          return [];
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  if (selectOptions.isLoading || (!selectOptions.data && !localOptionsList)) {
    return <div>Loading...</div>;
  }
  return (
    <NewAppInput
      label={label}
      labelClassName={labelClassName}
      name={name}
      additionalClasses={additionalClasses}
    >
      <select
        id={name}
        name={name}
        value={value ?? defaultOption?.value ?? ""}
        onChange={(e) => {
          //TODO: We should remove onChangeCallbackAction and just passthrough the onChange instead
          setFieldValue(name, e.target.value);
          if (onChangeCallbackAction) {
            onChangeCallbackAction(e.target.value);
          } else {
            onChange?.(e);
          }
        }}
        {...props}
      >
        {defaultOption && (
          <option
            key="default"
            value={defaultOption.value ?? ""}
            disabled={
              defaultOption?.disabled === undefined
                ? false
                : defaultOption.disabled
            }
          >
            {defaultOption.display}
          </option>
        )}
        {localOptionsList
          ? localOptionsList.map((option: NewAppSelectOption) => {
              return (
                <option key={option.value} value={option.value ?? ""}>
                  {option.display}
                </option>
              );
            })
          : selectOptions?.data?.map((option: NewAppSelectOption) => (
              <option key={option.value} value={option.value ?? ""}>
                {option.display}
              </option>
            ))}
      </select>
    </NewAppInput>
  );
};

export default NewAppSelectMenu;
