import {
  ApiResponse,
  PaginatedResults,
  SocietyCustomChoiceView,
  SocietyView,
} from "@parthenon-management/pillar-types";
import { FiltersRequest } from "@parthenon-management/pillar-types/dist/src/utils/helpers/filter-where-clause";
import { ApiQueryConfig } from "api/types";
import { SocietyPublicUI } from "types/society/profile";

const GetSocietyCustomChoices: ApiQueryConfig<
  ApiResponse<PaginatedResults<SocietyCustomChoiceView>>,
  { societyId: string; choiceQuestionName: string },
  FiltersRequest
> = {
  apiPath: "/api/v1/society/{societyId}/choices/{choiceQuestionName}",
  queryKey: {
    baseQueryKey: ["GetSocietyCustomChoices"],
    dynamicQueryKey: ["choiceQuestionName"],
  },
};

export { GetSocietyCustomChoices };
