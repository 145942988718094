import { ProfileAvatar } from "@components/common/avatar";

type Props = {
  userFirstName: string;
  userLastName: string;
  userPictureUrl?: string;
  message: string;
  isCurrentUser: boolean;
};

const MessageComponent = ({
  message,
  isCurrentUser,
  userFirstName,
  userLastName,
  userPictureUrl,
}: Props) => {
  return (
    <div className="flex mb-4">
      <div
        className={`w-full flex gap-2.5 ${
          isCurrentUser ? "flex justify-end" : "flex justify-start"
        }`}
      >
        <div
          className={`${
            isCurrentUser ? "bg-primary-100" : "bg-neutral-light"
          } py-2 px-2 shadow ${
            isCurrentUser
              ? "rounded-tl-2xl rounded-tr-none rounded-br-2xl rounded-bl-2xl"
              : "rounded-tr-2xl rounded-br-2xl rounded-bl-2xl order-2"
          }`}
        >
          <p className="text-detailblock-base leading-5">{message}</p>
        </div>

        <div className="flex items-start">
          <ProfileAvatar
            firstName={userFirstName}
            lastName={userLastName}
            pictureUrl={userPictureUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageComponent;
