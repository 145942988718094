/* eslint-disable @typescript-eslint/no-explicit-any */

//any is required here since there is not a defined type for the directory responses
import { GetDirectory, GetProfile, GetProfiles, fetchApiQuery } from "@api";
import { ProfileView } from "@parthenon-management/pillar-types";
import { debounce } from "lodash";
import {
  ChoicesLazyLoadEvent,
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  PanelModel,
  Question,
  QuestionCompositeModel,
  Serializer,
  SurveyModel,
} from "survey-core";
import { getSocietyId } from "utils/getSocietyId";
import { getDisplayName } from "utils/profile/getDisplayName";
import { SjsCustomQuestion } from "../types/sjs-custom-question";

interface itemChoice {
  value: any;
  text?: string | undefined;
  imageLink?: string | undefined;
  customProperty?: any;
}
const PROFILE_NOT_FOUND_ID = "none";

const questionName = SjsCustomQuestion.profile_search;
export const profile_search = async () => {
  const profile_search_object: ICustomQuestionTypeConfiguration & {
    updateProfileHtml: (
      question: QuestionCompositeModel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: string,
    ) => Promise<void>;
  } = {
    name: questionName,
    elementsJSON: [
      {
        name: "ProfileId",
        title: "Profile Search",
        type: "dropdown",
        choicesLazyLoadEnabled: true,
        noneText: "Create new profile",
        showNoneItem: true,
        description:
          "Select and search for a profile. If there exists profiles with the same first and last name, selecting one will provide additional affiliation information to assist you in selecting the right profile. If you can't find your profile, create a new one by selecting 'Create new profile' and filling out the form.",
      },
      {
        type: "html",
        name: "ProfileHtml",
        html: "", //html set onvalueselected and onload
      },
      {
        type: "panel",
        name: "CreateNewProfilePanel",
        visible: false,
        elements: [
          {
            name: "newEmail",
            title: "Email",
            type: "text",
          },
          {
            name: "newFirstName",
            title: "First Name",
            type: "text",
          },
          {
            name: "newLastName",
            title: "Last Name",
            type: "text",
          },
          {
            name: "newProfileAffiliation",
            title: "Affiliation",
            type: "dropdown",
            showOtherItem: true,
            otherPlaceholder: "Affiliation Name...",
            otherText: "Other (Not Listed)",
            description:
              "Please search for an affiliation below, if one is not found select Other and provide the name of the university, company or affiliation.",
            choicesLazyLoadEnabled: true,
            pillar_choices_question_name: "university",
          },
          {
            name: "createNewProfile",
            title: "Create New Profile",
            type: "boolean",
            visible: false,
            defaultValueExpression: "true",
          },
        ],
      },
    ],
    onInit: function () {
      Serializer.addProperty(questionName, {
        name: "useDirectorySearch",
        displayName: "Use Directory Search (advanced)?",
        type: "boolean",
        default: false,
        category: "Search Configuration",
      });
      Serializer.addProperty(questionName, {
        name: "directoryId",
        display: "Directory ID",
        type: "number",
        dependsOn: ["useDirectorySearch"],
        visibleIf: function (obj: any) {
          return obj.useDirectorySearch;
        },
        category: "Search Configuration",
      });
      Serializer.addProperty(questionName, {
        name: "isCospeaker",
        display: "Cospeaker on event?",
        type: "boolean",
        category: "Event Properties",
      });
    },
    onLoaded(question: QuestionCompositeModel) {
      (question.survey as SurveyModel)?.onGetChoiceDisplayValue.add(
        async (sender, options) => {
          const profileId = options.values[0];
          const profileResponse = await fetchApiQuery(GetProfile, {
            societyId: getSocietyId().toString(),
            profileId: profileId,
          });
          const items = [
            {
              value: profileResponse.data.body?.id,
              text: profileResponse.data.body
                ? getDisplayName(profileResponse.data.body)
                : "UNKNOWN",
            },
          ];
          //display value is dumb, it expects an array of strings that match the array index of the selected choice ids
          const resultString = [] as string[];
          for (const value of options.values) {
            const item = items.find((item) => item.value === value);
            if (item) {
              resultString.push(item.text);
            }
          }
          options.setItems(resultString);
          this.updateProfileHtml(
            question,
            String(profileResponse.data.body?.id),
          );
        },
      );
    },
    onCreated(question: Question) {
      // throw new Error("Function not implemented.");
    },
    onAfterRender: function (
      question: QuestionCompositeModel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      const lazyloadingid = Math.random().toString(36); //this doesn't need to be cryptgraphically secure, just unique, this allows us to have multiple degree pickers on the same form
      const directoryId = question.getPropertyValue("directoryId");
      (question as any)
        .getQuestionByName("ProfileId")
        ?.setPropertyValue("lazyLoadingUniqueId", lazyloadingid);
      (question as any)
        .getQuestionByName("ProfileId")
        ?.setPropertyValue("directoryId", directoryId);
      const setChoices = async (options: ChoicesLazyLoadEvent) => {
        let items: {
          value: number;
          text: string;
        }[] = [];
        let totalResults = 0;
        if (options.question.getPropertyValue("directoryId")) {
          const profilesResult = await fetchApiQuery(
            GetDirectory,
            {
              societyId: getSocietyId().toString(),
              directoryId: options.question.getPropertyValue("directoryId"),
            },
            {
              text: options.filter.toLocaleLowerCase(),
              page: options.skip / options.take + 1,
              pageSize: options.take,
            } as any, ///not sure why this is needed, getting typescript errors without it.
            {},
            {
              staleTime: 100,
            },
          );
          const choices =
            profilesResult.data.body?.profilesPaginated.results ?? [];

          items = choices.map((choice: any) => ({
            value: choice.profile.id as number,
            text: (choice.profile.SocietyUser as any)?.first_last_computed,
          }));
          totalResults =
            profilesResult.data.body?.profilesPaginated.totalResults ?? 0;
        } else {
          const profilesResult = await fetchApiQuery(
            GetProfiles,
            {
              societyId: getSocietyId().toString(),
            },
            {
              text: options.filter,
              type: ["Society_User"],
              page: options.skip / options.take + 1,
              pageSize: options.take,
            } as any,
          );
          const choices = profilesResult.data.body?.results ?? [];
          items = choices.map((choice) => ({
            value: choice.id,
            text: getDisplayName(choice),
          }));
          totalResults = profilesResult.data.body?.totalResults ?? 0;
        }
        options.setItems(items, totalResults);
      };
      const _debouncedSetChoices = debounce(setChoices, 1000);
      (question as any)
        .getQuestionByName("ProfileId")
        ?.setPropertyValue("lazyLoadingUniqueId", lazyloadingid);
      (question.survey as SurveyModel)?.onChoicesLazyLoad.add(
        async (sender, options) => {
          if (
            options.question?.getPropertyValue("lazyLoadingUniqueId") !=
            lazyloadingid
          )
            return;
          options.setItems([], 0);
          _debouncedSetChoices(options);
        },
      );
    },
    onAfterRenderContentElement: function (
      question: QuestionCompositeModel,
      element: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
    onPropertyChanged: function (
      question: Question,
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    createElements: function (
      panel: PanelModel,
      question: QuestionCompositeModel,
    ) {
      // throw new Error("Function not implemented.");
    },
    onValueChanged: function (
      question: QuestionCompositeModel,
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      if (name === "ProfileId") {
        console.log("here");
        if (newValue === PROFILE_NOT_FOUND_ID) {
          question.contentPanel.getElementByName(
            "CreateNewProfilePanel",
          ).visible = true;
          question.contentPanel.getQuestionByName(name).visible = false;
          question.contentPanel.getQuestionByName("ProfileHtml").html = "";
        } else {
          this.updateProfileHtml(question, newValue);
        }
      }
    },
    onItemValuePropertyChanged: function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
    updateProfileHtml: async function (
      question: QuestionCompositeModel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: string,
    ): Promise<void> {
      const profileResponse = await fetchApiQuery(GetProfile, {
        societyId: getSocietyId().toString(),
        profileId: newValue,
      });
      if (profileResponse.data.body) {
        question.contentPanel.getQuestionByName("ProfileHtml").html =
          renderProfileSearchHtmlTemplate(profileResponse.data.body);
        (question.survey as SurveyModel).render();
      }
    },
  };

  if (!ComponentCollection.Instance.getCustomQuestionByName(questionName)) {
    ComponentCollection.Instance.add(profile_search_object);
  }
};

const renderProfileSearchHtmlTemplate = (profile: ProfileView) => {
  console.log("MyProfile", profile);
  return `<ul>
    <li>Membership Status: ${profile.status}</li>
    <li>Name: ${profile.societyUser?.firstName} ${profile.societyUser?.lastName}</li>
    <li>Affiliation: ${profile.societyUser?.affiliation}</li>
  </ul>`;
};
