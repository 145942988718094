import { CommonIconProps } from "../../types/icon";

// This icon is taken from ionicons package which is under MIT licence https://github.com/ionic-team/ionicons/blob/main/LICENSE

const ChevronExpandOutlineIcon = ({
  width = 22,
  height = 22,
  className,
  ...props
}: CommonIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 512 512"
    >
      <path
        d="M136 208l120-104 120 104M136 304l120 104 120-104"
        stroke="currentColor"
        fill="none"
        stroke-width="32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronExpandOutlineIcon;
