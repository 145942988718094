/* eslint-disable no-useless-catch */
import { apiClient, buildApiClient } from "@api";
import {
  InvoiceItemView,
  InvoiceLiteView,
} from "@parthenon-management/pillar-types";
import { PillarPDF } from "types/PDF";
import { getSocietyId } from "utils/getSocietyId";
import { getProfileId } from "utils/sessionStorage/user";

export const getInvoiceById = async (
  invoiceId: number,
): Promise<InvoiceLiteView> => {
  try {
    buildApiClient();

    const response = await apiClient!.get(
      `/api/v1/society/${getSocietyId()}/profiles/${getProfileId()}/invoice/${invoiceId}`,
    );

    const { body } = response!.data;

    return body;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteInvoiceItemById = async (invoiceItemId: number) => {
  buildApiClient();
  const response = await apiClient!.delete(
    `/api/v1/society/${getSocietyId()}/accounting/invoices/profile/${getProfileId()}/delete-invoice-item?invoiceItemId=${invoiceItemId}`,
  );

  return response!.data;
};

export const addInvoiceItem = async (invoiceItem: InvoiceItemView) => {
  try {
    const response = await apiClient!.post(
      `/api/v1/society/${getSocietyId()}/accounting/invoices/profile/${getProfileId()}/add-invoice-item`,
      invoiceItem,
    );

    return response!.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getInvoicePDF = async (invoiceId: number): Promise<PillarPDF> => {
  try {
    buildApiClient();
    const response = await apiClient!.get(
      `/api/v1/society/${getSocietyId()}/invoices/generate-pdf/${invoiceId}`,
    );

    const { body } = response!.data;

    return body;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
