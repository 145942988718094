import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SessionProvider } from "context/SessionContext";
import UserHubRoutes from "routes/UserHubRoutes";
import MainMenu from "components/sideMenu/MainMenu";
import MainContentWrapper from "components/mainContent/MainContentWrapper";
import { queryClient } from "api/client/query-client";

import "../styles/index.css";

setupIonicReact({
  mode: "md",
});

const App: React.FC = () => (
  <IonApp>
    <QueryClientProvider client={queryClient}>
      <IonReactRouter>
        <SessionProvider>
          {/* TODO: We should pull the min-width value from our css variables for screen size of tablet 
          to ensure it is consistent and managed in only one place. However, unsure of how to pull a css
          variable into html/js without a hacky, brittle solution.*/}
          <IonSplitPane contentId="main-content" when="(min-width: 768px)">
            <MainMenu />
            <IonRouterOutlet id="main-content">
              <MainContentWrapper className="h-full w-full">
                <UserHubRoutes />
              </MainContentWrapper>
            </IonRouterOutlet>
          </IonSplitPane>
        </SessionProvider>
      </IonReactRouter>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </IonApp>
);

export default App;
