export const getSocietyId = () => {
  const societyId = Number(process.env.REACT_APP_SOCIETY_ID);
  if (!isNaN(societyId) && societyId !== 0) {
    return societyId;
  } else {
    // Return 3 when the environment variable is not a valid number.
    // Adding this as temp solution. so I don't break TESAWG in case there's an issue that could slow down testing.
    return 3;
  }
};
