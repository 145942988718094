import {
  ProfileLiteView,
  ProfileView,
} from "@parthenon-management/pillar-types";

export const getDisplayName = (
  profile: ProfileView | ProfileLiteView,
): string => {
  if (profile.societyUser?.displayName) {
    return profile.societyUser.displayName;
  }
  if (profile.societyUser?.firstName && profile.societyUser.lastName) {
    return `${profile.societyUser.firstName} ${profile.societyUser.lastName}`;
  }
  if (profile.institution?.name) {
    return profile.institution.name;
  }
  return "UNKOWN";
};
