import {
  ProfileLiteView,
  ProfileView,
} from "@parthenon-management/pillar-types";

//TODO: This is a bit temporary,  I'd rather the default email by set by an ID in society settings instead of this string ~ GS 8/16/23
export const defaultEmailFromProfile = (
  profile: ProfileLiteView | ProfileView,
): string | null => {
  return (
    profile?.contactInformation?.find(
      (ci) => ci.use?.find((ciuse) => ciuse.description === "Mail"),
    )?.email ??
    profile?.contactInformation?.[0]?.email ??
    null
  );
};
