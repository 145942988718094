import { apiClient, buildApiClient } from "@api";
import { getSocietyId } from "utils/getSocietyId";

export const checkUsernameExists = async (username: string) => {
  try {
    buildApiClient();
    const response = await apiClient!.get(
      `/api/v1/society/${getSocietyId()}/identity?username=${username}`,
    );
    if (response) return false;
  } catch (error) {
    return true;
  }
};
