import { ApiResponse, AttendeeView } from "@parthenon-management/pillar-types";
import { ApiQueryConfig } from "api/types";

const GetConferenceAttendees: ApiQueryConfig<
  ApiResponse<AttendeeView[]>,
  { societyId: string; conferenceId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/conference/{conferenceId}/attendees",
  queryKey: {
    baseQueryKey: ["GetConferenceAttendees"],
    dynamicQueryKey: ["societyId", "conferenceId"],
  },
};

const GetConferenceSpeakers: ApiQueryConfig<
  ApiResponse<AttendeeView[]>,
  { societyId: string; conferenceId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/conference/{conferenceId}/attendees/speakers",
  queryKey: {
    baseQueryKey: ["GetConferenceAttendees"],
    dynamicQueryKey: ["societyId", "conferenceId"],
  },
};
export { GetConferenceAttendees, GetConferenceSpeakers };
