import { configureAbly } from "@ably-labs/react-hooks";
import {
  DeauthenticateUser,
  fetchApiQuery,
  GetAblyTokenCreation,
  ImpersonateProfile,
  mutateApiQuery,
  VerifyAuthenticatedUser,
} from "@api";
import AblyComponent from "@components/common/AblyComponent";
import LoadingPage from "@components/common/LoadingPage";
import { useIonRouter } from "@ionic/react";
import { SessionView } from "@parthenon-management/pillar-types";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import { getSocietyId } from "utils/getSocietyId";
import {
  clearProfileId,
  getProfileId,
  setProfileId,
} from "utils/sessionStorage/user";

import {
  initializePushNotifications,
  unregisterPushNotifications,
} from "utils/firebase/pushNotification";

interface SessionContext extends SessionView {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  timezone: string;
  setTimezone: (timezone: string) => void;
  setSession: (session: SessionView | null) => void;
  routesLoaded: boolean;
  setRoutesLoaded: (routesLoaded: boolean) => void;
  logOut: () => void;
  setAuthenticatedSession: (session: SessionView) => void;
  refreshSession: () => Promise<void>;
  impersonateProfile: (profileId: number, url?: string) => void;
}

const defaultSessionView: SessionView = {
  environment: null,
  societyUser: null,
  society: null,
  profile: null,
  societyAdmin: null,
  iat: 0,
  authInvalidBefore: "",
  societyUserId: null,
  societyAdminId: null,
  profileId: null,
  societyId: null,
  societies: [],
  profileIds: [],
  tags: [],
};
const SessionContext = createContext<SessionContext>({
  isAuthenticating: false,
  isAuthenticated: false,
  timezone: "system",
  setTimezone: () => undefined,
  setSession: () => undefined,
  routesLoaded: false,
  setRoutesLoaded: () => undefined,
  logOut: () => undefined,
  setAuthenticatedSession: () => undefined,
  refreshSession: async () => undefined,
  impersonateProfile: () => undefined,
  ...defaultSessionView,
});

type Props = {
  children: ReactNode;
};

const SessionProvider = ({ children }: Props) => {
  //Hooks
  const history = useHistory();

  //ContextState
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<string>("system");

  //Session is the SessionView object from the API
  const [session, setSession] = useState<SessionView | null>(null);

  //Loading States
  const [routesLoaded, setRoutesLoaded] = useState<boolean>(false);
  const [initalSessionDetailsLoaded, setInitalSessionDetailsLoaded] =
    useState<boolean>(false);

  //Look for token in URL
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tokenValue = params.get("token");
  const router = useIonRouter();

  //Hack to go to overview page once we have the session
  const [pushedToOverview, setPushedToOverview] = useState<boolean>(false);

  const setSocietyTheme = (theme: any) => {
    theme.forEach((theme: any) => {
      document.documentElement.style.setProperty(
        `--${theme.property}`,
        theme.value,
      );
    });
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      routesLoaded &&
      !pushedToOverview &&
      (router.routeInfo.pathname === "/login" ||
        router.routeInfo.pathname === "/") &&
      !tokenValue
    ) {
      setPushedToOverview(true);
      history.push("/overview");
    }
    if (tokenValue) {
      setPushedToOverview(true);
    }
  }, [
    isAuthenticated,
    routesLoaded,
    router.routeInfo.pathname,
    pushedToOverview,
  ]);

  //Fun-fun-fun
  const logOut = useCallback(async () => {
    try {
      const response = await mutateApiQuery(
        DeauthenticateUser,
        {
          societyId: getSocietyId().toString(),
          profileId: getProfileId().toString(),
        },
        {},
      );

      if (response.data) {
        setIsAuthenticated(false);
        await unregisterPushNotifications();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsAuthenticated(false);
      clearProfileId();
      setSession(null);
      setInitalSessionDetailsLoaded(false);
      history.push("/login");
    }
  }, [history]);
  const setAuthenticatedSession = useCallback(async (session: SessionView) => {
    if (!session.profileId || !session.profile) {
      throw new Error("Profile is not present in session");
    }
    if (!session.societyUser) {
      throw new Error("Society User is not present in session");
    }
    if (!session.society) {
      throw new Error("Society is not present in session");
    }
    setSession(session);
    setProfileId(session.profileId);
    setIsAuthenticated(true);
  }, []);
  const refreshSession = async () => {
    if (!isAuthenticated) {
      setIsAuthenticating(true);
    }
    const initalSessionDetails = await fetchApiQuery(
      VerifyAuthenticatedUser,
      {
        societyId: getSocietyId().toString(),
      },
      {},
      {},
      {
        staleTime: -1,
      },
    );
    setSession(initalSessionDetails.data.body!);
    if (initalSessionDetails.data.body!.profileId) {
      setAuthenticatedSession(initalSessionDetails.data.body!);
    }
    setInitalSessionDetailsLoaded(true);
    setIsAuthenticating(false);
  };
  const impersonateProfile = async (profileId: number, url?: string) => {
    setIsAuthenticating(true);
    //TODO: We can speed this up by get the session from the response instead of refreshing.
    await fetchApiQuery(
      ImpersonateProfile,
      {
        societyId: getSocietyId().toString(),
        profileId: profileId.toString(),
      },
      {},
      {},
      { staleTime: -1 },
    );
    await refreshSession();
    setIsAuthenticating(false);
    if (url) {
      history.push(url);
    }
  };
  //Watch Session, sometimes we set it to Null and need to refresh it.
  useEffect(() => {
    if (!session) {
      refreshSession();
    } else {
      if (session.society && session.society.theme) {
        setSocietyTheme(session.society.theme);
      }
    }
  }, [session]);

  //Ably
  const [ablyReady, setAblyReady] = useState<boolean>(false);
  useEffect(() => {
    const ably = configureAbly({
      autoConnect: false,
      authCallback: async (data, callback) => {
        try {
          const response = await fetchApiQuery(
            GetAblyTokenCreation,
            { societyId: getSocietyId().toString() },
            {},
          );
          callback(null, response.data.body!);
          setAblyReady(true);
        } catch (error) {
          // console.error(error);
          // throw new Error("Unable to get Ably's Token.");
        }
      },
    });

    if (
      isAuthenticated &&
      session?.society?.societySettingsPublic?.ablyEnabled &&
      !ablyReady
    ) {
      ably.connect();
    }

    // We want to clean up our connections,  but also this is breaking things so
    // TODO: Fix this ~ GS and Silvestre 7/21/23 1:22PM
    // return () => {
    //   console.log("unmounting");
    //   //      ably.close();
    // };
  }, [isAuthenticated, session?.society, ablyReady]);

  // Initialize FCM Push Notification
  useEffect(() => {
    if (isAuthenticated) {
      initializePushNotifications();
    }
  }, [isAuthenticated]);

  const sessionValues = {
    environment: session?.environment ?? null,
    societyUser: session?.societyUser ?? null,
    society: session?.society ?? null,
    profile: session?.profile ?? null,
    societyAdmin: session?.societyAdmin ?? null,
    iat: session?.iat ?? 0,
    authInvalidBefore: session?.authInvalidBefore ?? "",
    societyUserId: session?.societyUserId ?? null,
    societyAdminId: session?.societyAdminId ?? null,
    profileId: session?.profileId ?? null,
    societyId: session?.societyId ?? null,
    societies: session?.societies ?? [],
    profileIds: session?.profileIds ?? [],
    tags: session?.tags ?? [],
  };
  //The juice of the context
  const value = useMemo(
    () => ({
      isAuthenticating,
      isAuthenticated,
      timezone,
      setTimezone,
      setSession,
      routesLoaded,
      setRoutesLoaded,
      logOut,
      setAuthenticatedSession,
      impersonateProfile,
      refreshSession,
      ...sessionValues,
    }),
    [
      isAuthenticating,
      isAuthenticated,
      timezone,
      setTimezone,
      setSession,
      routesLoaded,
      setRoutesLoaded,
      logOut,
      setAuthenticatedSession,
      impersonateProfile,
      refreshSession,
      sessionValues,
    ],
  );
  if (!initalSessionDetailsLoaded) {
    return <LoadingPage />;
  }

  if (ablyReady) {
    return (
      <SessionContext.Provider value={value}>
        <AblyComponent>{children}</AblyComponent>
      </SessionContext.Provider>
    );
  }
  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
