import { IonButton } from "@ionic/react";
import PageComponent from "components/common/PageComponent";
import { useLocation } from "react-router";

type WorkflowCompletePageProps = {
  message?: string;
};

const WorkflowCompletePage = ({ message }: WorkflowCompletePageProps) => {
  const location = useLocation();

  // TODO: This will reset to the default message on refresh due to location state being cleared on refresh.
  const displayMessage =
    message ||
    (location.state as WorkflowCompletePageProps)?.message ||
    "Thank you! Your submission has been received.";

  return (
    <PageComponent>
      <PageComponent.Header>Submission Received</PageComponent.Header>
      <PageComponent.Content>
        <div className="flex flex-col justify-center items-center h-full w-full">
          <div className="text-base font-medium w-max">{displayMessage}</div>
          <IonButton routerLink="/overview">Go Home</IonButton>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default WorkflowCompletePage;
