/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormView } from "@parthenon-management/pillar-types";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Question,
  Serializer,
} from "survey-core";

export const buildDisclosureQuestions = (disclosures: FormView[]) => {
  const filteredDisclosures = disclosures.filter(
    (disclosure) => disclosure.title != null,
  );

  for (let i = 0; i < filteredDisclosures.length; i++) {
    const questionName = `${filteredDisclosures[i].title.toLowerCase()}`;
    const questionValues = ComponentCollection.Instance.items;
    const customQuestionNames = [];

    for (let i = 0; i < questionValues.length; i++) {
      const name = questionValues[i].name;
      customQuestionNames.push(name);
    }

    const disclosureForm = disclosures.find(
      (form) => form.title.toLowerCase() == questionName,
    );

    if (disclosureForm && disclosureForm.formDesign) {
      const formDesignJson = JSON.parse(
        JSON.stringify(disclosureForm.formDesign),
      );
      const pages = formDesignJson.pages;
      const elements: any = [];
      pages.forEach((page: any) => {
        const pageElements = page.elements;
        elements.push(...pageElements);
      });
      if (elements) {
        if (!customQuestionNames.includes(questionName)) {
          const questionObj = BuildDisclosureCustomQuestion(
            questionName,
            elements,
          );
          ComponentCollection.Instance.add(questionObj);
        }
      }
    }
  }
  return;
};

const BuildDisclosureCustomQuestion = (
  questionName: string,
  elements: any,
): ICustomQuestionTypeConfiguration => {
  const disclosure_object: ICustomQuestionTypeConfiguration = {
    name: questionName,
    title: questionName,
    elementsJSON: elements,
    onLoaded(question: Question) {
      question.name = questionName;
      Serializer.getProperty(questionName, "name").visible = false;
      Serializer.getProperty(questionName, "visible").visible = false;
      Serializer.getProperty(questionName, "isRequired").visible = false;
      Serializer.getProperty(questionName, "readOnly").visible = false;

      Serializer.getProperty(questionName, "visibleIf").visible = false;

      Serializer.getProperty(questionName, "enableIf").visible = false;
      Serializer.getProperty(questionName, "requiredIf").visible = false;
      Serializer.getProperty(questionName, "clearIfInvisible").visible = false;
      Serializer.getProperty(questionName, "defaultValueExpression").visible =
        false;
      Serializer.getProperty(questionName, "defaultValue").visible = false;
      Serializer.getProperty(questionName, "startWithNewLine").visible = false;
      Serializer.getProperty(questionName, "valueName").visible = false;
      Serializer.getProperty(questionName, "useDisplayValuesInTitle").visible =
        false;
      Serializer.getProperty(questionName, "correctAnswer").visible = false;

      Serializer.getProperty(questionName, "requiredErrorText").visible = false;

      Serializer.getProperty(questionName, "validators").visible = false;
      // throw new Error("Function not implemented.");
    },
    onInit() {
      //Override titleLocation property attributes for "shippingaddress" class by making it invisible in property grid and change its default value
    },
    onCreated(question: Question) {
      // throw new Error("Function not implemented.");
    },
    onAfterRender: function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
    onAfterRenderContentElement: function (
      question: Question,
      element: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onPropertyChanged: function (
      question: Question,
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onValueChanged: function (
      question: Question,
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
    onItemValuePropertyChanged: function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
  };
  return disclosure_object;
};
