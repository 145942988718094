import {
  ApiResponse,
  ClientDashboardView,
  PaginatedResults,
  SubmissionInstanceReviewerView,
  ClientDashboardStatView,
} from "@parthenon-management/pillar-types";
import { PaginationRequest } from "@parthenon-management/pillar-types/dist/src/ui/types/common/general-types";
import { FiltersRequest } from "@parthenon-management/pillar-types/dist/src/utils/helpers/filter-where-clause";
import { ApiMutationConfig, ApiQueryConfig } from "api/types";

const GetSubmissionInstanceReviewers: ApiQueryConfig<
  ApiResponse<PaginatedResults<ClientDashboardView>>,
  { societyId: string; profileId: string },
  FiltersRequest & PaginationRequest
> = {
  apiPath:
    "/api/v1/society/{societyId}/submission/reviewer/{profileId}/client-dashboard",
  queryKey: {
    baseQueryKey: ["GetSubmissionInstanceReviewers"],
    dynamicQueryKey: ["profileId"],
  },
};

const GetSubmissionInstanceReviewer: ApiQueryConfig<
  ApiResponse<SubmissionInstanceReviewerView>,
  {
    societyId: string;
    profileId: string;
    submissionInstanceReviewerId: string;
  },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/submission/reviewer/{profileId}/submission-review-instance/{submissionInstanceReviewerId}",
  queryKey: {
    baseQueryKey: ["GetSubmissionInstanceReviewer"],
    dynamicQueryKey: ["profileId"],
  },
};

const RecuseSubmissionInstanceReviewer: ApiMutationConfig<
  void,
  { societyId: string; profileId: string },
  { submissionProcessStepId: number; workflowInstanceId: number }
> = {
  method: "POST",
  apiPath: "/api/v1/society/{societyId}/submission/reviewer/{profileId}/recuse",
  // We do not have access in the response or in the path params to the profileId of the person who submitted the response being reviewed.
  // Since that is the key being used on the GetSubmissionInstanceReviewers route, we instead invalidate any query that is keyed
  // starting with the base query, regardless of additional keys in the array. This is accomplished by not invalidating a query key with "exact=true".
  invalidates: [
    {
      baseQueryKey: GetSubmissionInstanceReviewers.queryKey.baseQueryKey,
      exact: false,
    },
  ],
};

const GetClientDashboardStats: ApiQueryConfig<
  ApiResponse<ClientDashboardStatView[]>,
  { societyId: string; profileId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/submission/reviewer/{profileId}/client-dashboard-stats",
  queryKey: {
    baseQueryKey: ["GetClientDashboardStats"],
    dynamicQueryKey: ["societyId", "profileId"],
  },
};

export {
  GetSubmissionInstanceReviewers,
  GetSubmissionInstanceReviewer,
  RecuseSubmissionInstanceReviewer,
  GetClientDashboardStats,
};
