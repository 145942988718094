import CheckIcon from "@components/icons/CheckIcon";
import { Combobox } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { FormikValues, useFormikContext } from "formik";
import { useState } from "react";
import { ExtractProps } from "utils/prop-typing";
import ChevronExpandOutlineIcon from "@components/icons/ChevronExpandOutlineIcon";

export type NewAppComboboxOption = {
  key: string;
  display: string;
  value: number | null | string | unknown;
};

export type NewAppComboboxProps<T> = Omit<
  ExtractProps<typeof Combobox>,
  "value" | "id" | "refName" | "as" | "name" | "multiple" | "onChange"
> & {
  name: string;
  valueProperty: string;
  label: string;
  displayProperty: string;
  comboOptionQuery?: () => Promise<T[]>;
  localOptions?: NewAppComboboxOption[];
  newComboOption?: (query: string) => Promise<T>;
  onChangeCallbackAction?: (
    currentValue: (string | number | null)[],
    newOptions?: NewAppComboboxOption[],
  ) => void;
  defaultOption: NewAppComboboxOption[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NewAppCombobox = <T extends { [key: string]: any }>({
  name,
  valueProperty,
  label,
  displayProperty,
  comboOptionQuery,
  localOptions,
  newComboOption,
  onChangeCallbackAction,
  //TODO: This wasn't being used, but to avoid any unexpected breaks in a PR not related to this, left in for now. Likely needs removed.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultOption,
  ...props
}: NewAppComboboxProps<T>) => {
  const valueProp = valueProperty ?? "id";
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const handleComboboxChange = async (
    name: string,
    value: (number | string | null)[],
    query: string,
  ) => {
    if (value.includes(null)) {
      let newComboOptionResponse;
      if (newComboOptionResponse) {
        newComboOptionResponse = await newComboOption?.(query);
        await comboOptions.refetch();
      } else if (localOptions) {
        value.push(query);
      }
      value = value.filter((option) => option !== null);
      if (comboOptions.data && newComboOptionResponse) {
        value.push(newComboOptionResponse[valueProp]);
      }
    }
    const newLocalOptions = [...(localOptions ?? [])];
    if (query !== "" && value.includes(query)) {
      newLocalOptions.push({
        key: query,
        display: query,
        value: query,
      });
    }

    setFieldValue(name, value);
    if (onChangeCallbackAction) {
      onChangeCallbackAction(value, newLocalOptions);
    }
  };

  const comboOptions = useQuery(
    ["comboOptions", name],
    () => comboOptionQuery?.() || Promise.resolve(null), // Use an empty array as a default value
    {
      select: (responseData): NewAppComboboxOption[] | undefined => {
        return responseData?.map((option: T) => ({
          key: option[valueProperty ?? "value"],
          display: option[displayProperty ?? "display"],
          value: option[valueProperty ?? "value"],
        }));
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  const [query, setQuery] = useState("");
  const filteredOptions = comboOptionQuery
    ? comboOptions.isLoading || comboOptions.isError || !comboOptions.data
      ? []
      : query === ""
        ? comboOptions.data
        : comboOptions.data.filter((option: NewAppComboboxOption) => {
            return option.display.toLowerCase().includes(query.toLowerCase());
          })
    : localOptions?.filter((option: NewAppComboboxOption) => {
        return option.display.toLowerCase().includes(query.toLowerCase());
      }) ?? [];

  if (!comboOptions.data && !localOptions) {
    return null;
  }

  return (
    <Combobox
      id={name}
      refName="comboboxRef"
      as="div"
      value={values[name]}
      onChange={(value: any) => {
        handleComboboxChange(name, value, query);
      }}
      {...props}
    >
      <Combobox.Label htmlFor={name}>{label}:</Combobox.Label>
      <div className="relative">
        <Combobox.Input
          className="w-full pr-3 h-4.5"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(selectOptions: number[]) => {
            if (comboOptions.data) {
              return (
                selectOptions
                  ?.map(
                    (option: number) =>
                      comboOptions?.data?.find(
                        (o: NewAppComboboxOption) => o.value === option,
                      )?.display,
                  )
                  .join(", ") ?? ""
              );
            } else if (localOptions) {
              return (
                selectOptions
                  ?.map(
                    (option: number) =>
                      localOptions?.find(
                        (o: NewAppComboboxOption) => o.value === option,
                      )?.display,
                  )
                  .join(", ") ?? ""
              );
            } else {
              return "";
            }
          }}
          onFocus={(event: { target: { value: string } }) => {
            setQuery("");
            event.target.value = "";
          }}
          onBlur={(event: { target: { value: string } }) => {
            event.target.value =
              values[name]
                ?.map((option: number) => {
                  if (comboOptions.data) {
                    return comboOptions?.data?.find(
                      (o: NewAppComboboxOption) => o.value === option,
                    )?.display;
                  } else if (localOptions) {
                    return localOptions?.find(
                      (o: NewAppComboboxOption) => o.value === option,
                    )?.display;
                  } else {
                    return "";
                  }
                })
                .join(", ") ?? "";
          }}
        />
        <Combobox.Button className="appcombo-button-container absolute right-0.25 h-4 top-0.25">
          <ChevronExpandOutlineIcon
            className="h-2.5 w-2.5"
            aria-hidden="true"
          />
        </Combobox.Button>
        <Combobox.Options className="appcombo-options-container absolute z-10 mt-0.5 max-h-60 w-full overflow-auto">
          {query.length > 0 && filteredOptions.length === 0 && (
            <Combobox.Option
              value={null}
              className="appcombo-options-option-add"
              onClick={() => {
                setQuery("");
              }}
            >
              <span>Create new item: {query}</span>
            </Combobox.Option>
          )}
          {filteredOptions.length > 0 &&
            filteredOptions.map((option: NewAppComboboxOption) => (
              <Combobox.Option
                key={option.key}
                value={option.value}
                className="appcombo-options-option-general flex items-center"
              >
                {values[name]?.includes(option.value) && (
                  <span className="mr-0.5">
                    <CheckIcon className="h-1.5 w-1.5" aria-hidden="true" />
                  </span>
                )}

                <span>{option.display}</span>
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};

export default NewAppCombobox;
