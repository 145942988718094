// From prisma
export const society_setting_name = {
  pillar_profile_overview_society_user: "pillar_profile_overview_society_user",
  pillar_profile_overview_institution: "pillar_profile_overview_institution",
  user_hub_overview_society_user: "user_hub_overview_society_user",
  user_hub_overview_institution: "user_hub_overview_institution",
  authorize_merchant_name: "authorize_merchant_name",
  authorize_transaction_key: "authorize_transaction_key",
  fiscal_year_start_month: "fiscal_year_start_month",
  fiscal_year_start_day: "fiscal_year_start_day",
  finance_recognize_day_of_month: "finance_recognize_day_of_month",
  confirm_profile_older_than_days: "confirm_profile_older_than_days",
  invoice_pdf_template: "invoice_pdf_template",
  authorize_public_client: "authorize_public_client",
  authorize_dot_net_api_url: "authorize_dot_net_api_url",
  authorize_dot_net_api_url_accept_suite:
    "authorize_dot_net_api_url_accept_suite",
  donation_checkout_product_group: "donation_checkout_product_group",
  default_email_sender: "default_email_sender",
  default_invoice_email_template: "default_invoice_email_template",
  default_receipt_email_template: "default_receipt_email_template",
  invoice_expiration_days: "invoice_expiration_days",
  pillar_group_disclosure_word_export: "pillar_group_disclosure_word_export",
  zoom_api_client_id: "zoom_api_client_id",
  zoom_api_client_secret: "zoom_api_client_secret",
  zoom_api_account_id: "zoom_api_account_id",
  userhub_url: "userhub_url",
  check_payment_configuration: "check_payment_configuration",
  outdated_profile_navigate_to_invoice_text:
    "outdated_profile_navigate_to_invoice_text",
  outdated_profile_navigate_to_edit_text:
    "outdated_profile_navigate_to_edit_text",
} as const;

export type society_setting_name =
  (typeof society_setting_name)[keyof typeof society_setting_name];
