import { updateUserHasReadMsg } from "api/chatAPI";
import MessageComponent from "./MessageComponent";
import { getProfileId } from "utils/sessionStorage/user";
import { Participant, ReadMessage } from "types/chat";
import { useEffect, useRef } from "react";
import LoadingPage from "@components/common/LoadingPage";
import { getParticipantInfo } from "./chat-shared-logic";
import { GetMessages, useApiQuery } from "@api";
import { getSocietyId } from "utils/getSocietyId";
import useSession from "hooks/useSession";

type Props = {
  chatId: number;
};

export const ChatMessages = ({ chatId }: Props) => {
  const session = useSession();
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const { data: messagesResponse, isLoading: loadingMsgData } = useApiQuery(
    GetMessages,
    {
      societyId: getSocietyId().toString(),
      profileId: session.profileId?.toString(),
      chatId: chatId?.toString(),
    },
    {},
    {},
    {
      enabled: !!chatId,
    },
  );

  const isCurrentUser = (participant: Participant): boolean => {
    const profile = participant.profile;

    if (profile) {
      return profile.id === getProfileId();
    }

    return false;
  };

  const userMessages = messagesResponse?.data?.body?.messages ?? [];

  useEffect(() => {
    const updateUnreadMessages = async () => {
      const unReadMsgs = userMessages.reduce<ReadMessage[]>((result, msg) => {
        if (!msg.isSeen) {
          result.push({
            isAdmin: false,
            messageId: msg.id,
            chatId: chatId,
            profileId: getProfileId(),
          });
        }
        return result;
      }, []);

      if (unReadMsgs?.length) {
        try {
          await updateUserHasReadMsg(unReadMsgs);
        } catch (error) {
          console.error("Error updating unread messages:", error);
        }
      }
    };

    if (userMessages.length) {
      updateUnreadMessages();
    }
  }, [userMessages]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [userMessages]);

  if (loadingMsgData && chatId > 0) {
    return <LoadingPage />;
  }

  return (
    <>
      {userMessages.map((msg, index) => (
        <div
          key={msg.id}
          ref={index === userMessages.length - 1 ? lastMessageRef : null}
        >
          <MessageComponent
            message={msg.content}
            isCurrentUser={isCurrentUser(msg.chatSenderParticipant)}
            userFirstName={
              getParticipantInfo(msg.chatSenderParticipant).firstName!
            }
            userLastName={
              getParticipantInfo(msg.chatSenderParticipant).lastName!
            }
            userPictureUrl={
              getParticipantInfo(msg.chatSenderParticipant).pictureUrl!
            }
          />
        </div>
      ))}
    </>
  );
};
