import { getSocietyId } from "utils/getSocietyId";
import { apiClient, buildApiClient } from "@api";
import { society_setting_name } from "constants/settings/society_setting_name";

export const getSetting = async (
  settingName: society_setting_name,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    buildApiClient();
    const response = await apiClient!.get(
      `/api/v1/society/${getSocietyId()}/setting/${settingName}`,
    );
    const { body } = response!.data;

    return body;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
