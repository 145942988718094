import {
  GetSubmissionResponse,
  GetSubmissionWorkflow,
  useApiQuery,
} from "@api";
import { SurveyPreview } from "@components/surveyJS/SurveyPreview";
import { SessionView } from "@parthenon-management/pillar-types";
import LoadingPage from "components/common/LoadingPage";
import PageComponent from "components/common/PageComponent";
import useSession from "hooks/useSession";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { getSocietyId } from "utils/getSocietyId";
import { getProfileId } from "utils/sessionStorage/user";

interface RouteParams {
  id: string;
}

const SubmissionPage = () => {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState<any>();
  const [formId, setFormId] = useState<number>();
  const [form, setForm] = useState<any>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const session = useSession();
  const uiMsg = queryParams.get("message");

  const assignmentId = queryParams.get("submissionAssignmentId");
  const {
    data: getSubmissionResponseData,
    isLoading: getSubmissionResponseIsLoading,
  } = useApiQuery(
    GetSubmissionResponse,
    {
      societyId: getSocietyId().toString(),
      submissionResponseId: queryParams.get("submissionResponseId"),
    },
    {},
    {},
    { enabled: !!queryParams.get("submissionResponseId") },
  );

  const { data: getSubmissionWorkflowResponse, isLoading: isFetchingResponse } =
    useApiQuery(
      GetSubmissionWorkflow,
      { societyId: getSocietyId().toString(), submissionWorkflowId: id },
      { profileId: getProfileId() },
    );
  useEffect(() => {
    const submissionResponseId = queryParams.get("submissionResponseId");
    const responseData = getSubmissionResponseData?.data?.body?.responseData;
    setFormId(
      getSubmissionWorkflowResponse?.data?.body?.submissionProcess?.responseForm
        ?.id,
    );

    if (!submissionResponseId) {
      setResponseData({});
      setLoading(false);
      return;
    }

    if (getSubmissionResponseIsLoading && !loading) {
      return;
    }

    if (!responseData) {
      return;
    }

    setResponseData(responseData);
    setLoading(false);
  }, [getSubmissionResponseData]);

  useEffect(() => {
    if (
      getSubmissionWorkflowResponse?.data?.body?.submissionProcess?.responseForm
    ) {
      setForm(
        getSubmissionWorkflowResponse?.data?.body?.submissionProcess
          ?.responseForm.formDesign,
      );
    }
    console.log("formFromSubmission", form);
  }, [getSubmissionWorkflowResponse]);

  const getExistingResponseFormDesign = () => {
    if (!getSubmissionResponseData?.data?.body?.formDesign) {
      return null; // No existing response from design
    }

    if (getSubmissionResponseData.data.body.profileId !== getProfileId()) {
      return null; // Not for logged in user
    }
    return getSubmissionResponseData?.data?.body?.formDesign;
  };

  const getExistingResponseData = () => {
    if (!getSubmissionResponseData?.data?.body?.responseData) {
      return null; // No existing User Response Data
    }

    if (
      getSubmissionResponseData.data.body.profileId !== getProfileId() ||
      getSubmissionResponseData?.data?.body?.status === "Complete"
    ) {
      return null; // Not for logged in user
    }

    return getSubmissionResponseData?.data?.body?.responseData;
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <PageComponent>
      <PageComponent.Header>Form</PageComponent.Header>
      <PageComponent.Content>
        {uiMsg && (
          <div className="flex justify-center p-2">
            <h4 className="font-medium w-max">{uiMsg}</h4>
          </div>
        )}

        {getSubmissionWorkflowResponse?.data && form && (
          <>
            {console.log("sneaky form", form)}{" "}
            <SurveyPreview
              isReadOnly={
                getSubmissionResponseData?.data?.body?.status === "Complete"
              }
              session={session as SessionView}
              profile={session.profile!}
              additionalInfo={{
                profileId: getProfileId(),
                submissionProcessId:
                  getSubmissionWorkflowResponse?.data?.body!.submissionProcess
                    ?.id,
                submissionResponseId:
                  getSubmissionWorkflowResponse?.data?.body!.submissionProcess
                    ?.submissionResponses[0]?.id,
                workflowComplexId:
                  getSubmissionWorkflowResponse?.data?.body!.workflowComplex
                    ?.id,
              }}
              assignmentId={Number(assignmentId)} //uncertain about this!!
              formId={formId} //uncertain about this!!
              formDesign={form}
              formData={getExistingResponseData() ?? responseData}
              onComplete={async (sender, options, payload) => {
                if (
                  //eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (
                    getSubmissionWorkflowResponse?.data?.body
                      ?.submissionProcess as any
                  ).createsInvoice
                ) {
                  const payloadStuff = JSON.parse(
                    JSON.stringify(
                      payload.data.body?.workflowInstance?.payload,
                    ),
                  );
                  history.push(`/payment/${payloadStuff.invoiceId}`);
                } else {
                  history.push(
                    getSubmissionWorkflowResponse?.data?.body?.workflowComplex
                      ?.workflowProducts
                      ? getSubmissionWorkflowResponse?.data?.body
                          ?.workflowComplex?.workflowProducts?.length > 0
                        ? `/product/workflow/${getSubmissionWorkflowResponse
                            ?.data?.body!.id}`
                        : `/workflow/new/${getSubmissionWorkflowResponse?.data
                            ?.body!.id}`
                      : `/workflow/new/${getSubmissionWorkflowResponse?.data
                          ?.body!.id}`,
                  );
                }
              }}
            />
          </>
        )}
      </PageComponent.Content>
    </PageComponent>
  );
};

export default SubmissionPage;
