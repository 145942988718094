import { FormikValues } from "formik";

import React from "react";

import NewAppInput from "./NewAppInput";
import { NewAppInputProps } from "types/newAppForm";

export type NewAppCheckboxProps = NewAppInputProps & {
  formik: FormikValues;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const NewAppCheckbox = (props: NewAppCheckboxProps) => {
  return (
    <NewAppInput
      name={props.name}
      labelClassName={props.labelClassName}
      label={props.label}
    >
      <input
        type="checkbox"
        className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 ${
          props.formik.errors[props.name] &&
          "border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500"
        }`}
        checked={props.formik.values[props.name]}
        name={props.name}
        id={props.name}
        onChange={props.onChange}
      />
    </NewAppInput>
  );
};

export default NewAppCheckbox;
