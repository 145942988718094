import {
  IonBadge,
  IonContent,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { GetClientDashboardStats, useApiQuery } from "@api";
import { SocietyLogo } from "@components/common/SocietyLogo";
import NewAppButton from "@components/common/newform/NewAppButton";
import {
  ClientDashboardStatView,
  RouteConfigView,
  TagView,
} from "@parthenon-management/pillar-types";
import { useQuery } from "@tanstack/react-query";
import { getChats } from "api/chatAPI";
import useSession from "hooks/useSession";
import { useHistory, useLocation } from "react-router";
import { Chat } from "types/chat";
import { getSocietyId } from "utils/getSocietyId";
import { mapPathParamsToUrl } from "utils/mapPathParamsToUrl";
import { getProfileId } from "utils/sessionStorage/user";
import useDeviceInfo from "hooks/useDeviceInfo";
import { FunctionComponent } from "react";
import BookOutlineIcon from "@components/icons/BookOutlineIcon";
import CashOutlineIcon from "@components/icons/CashOutlineIcon";
import ChatBubblesOutlineIcon from "@components/icons/ChatBubblesOutlineIcon";
import EaselOutlineIcon from "@components/icons/EaselOutlineIcon";
import FolderOpenOutlineIcon from "@components/icons/FolderOpenOutlineIcon";
import ListOutlineIcon from "@components/icons/ListOutlineIcon";
import MailOutlineIcon from "@components/icons/MailOutlineIcon";
import PeopleOutlineIcon from "@components/icons/PeopleOutlineIcon";
import RibbonOutlineIcon from "@components/icons/RibbonOutlineIcon";
import IdCardOutlineIcon from "@components/icons/IdCardOutlineIcon";
import TodayOutlineIcon from "@components/icons/TodayOutlineIcon";
import FileTrayStackedOutlineIcon from "@components/icons/FileTrayStackedOutlineIcon";
import ChevronBackIcon from "@components/icons/ChevronBackIcon";
import LogOutOutlineIcon from "@components/icons/LogOutOutlineIcon";
import { CommonIconProps } from "../../types/icon";
import FileTrayFullOutlineIcon from "@components/icons/FileTrayFullOutlineIcon";
import SparklesIcon from "@components/icons/SparklesIcon";

const iconMapper: Record<string, FunctionComponent<CommonIconProps>> = {
  bookOutline: BookOutlineIcon,
  cashOutline: CashOutlineIcon,
  chatbubblesOutline: ChatBubblesOutlineIcon,
  easelOutline: EaselOutlineIcon,
  folderOpenOutline: FolderOpenOutlineIcon,
  listOutline: ListOutlineIcon,
  mailOutline: MailOutlineIcon,
  peopleOutline: PeopleOutlineIcon,
  ribbonOutline: RibbonOutlineIcon,
  idCardOutline: IdCardOutlineIcon,
  fileTrayFullOutline: FileTrayFullOutlineIcon,
  todayOutline: TodayOutlineIcon,
  fileTrayStackedOutline: FileTrayStackedOutlineIcon,
};

const MainMenu = () => {
  const session = useSession();
  const location = useLocation();
  const history = useHistory();
  const device = useDeviceInfo();

  const getUnreadChatCount = (data: Chat[]): number => {
    return data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.unreadMsgCount;
    }, 0);
  };

  const unReadChatCountQuery = useQuery({
    queryKey: ["chats"],
    queryFn: () => getChats(),
    select: (data: Chat[]) => {
      return getUnreadChatCount(data);
    },
    refetchOnWindowFocus: false,
    retry: 0,
    enabled:
      session.isAuthenticated &&
      session.society?.societySettingsPublic?.ablyEnabled,
  });

  const { data: statsResp } = useApiQuery(
    GetClientDashboardStats,
    {
      societyId: getSocietyId().toString(),
      profileId: getProfileId().toString(),
    },
    {},
    {},
    {
      staleTime: -1,
      enabled: !!getProfileId(),
    },
  );

  // Had to add an extra check here.
  // There's a weird bug where the response is not coming back as an array.
  // TODO: Need to look into this more. Not able to recreate after clearing all caches.
  const newReviewStats = (Array.isArray(statsResp?.data?.body) &&
    // For review, we only care about New review counts.
    statsResp?.data?.body?.find((stat) => stat.statusName === "New")) as
    | ClientDashboardStatView
    | undefined;

  const getMenuItemBadge = (routeComponentName: string) => {
    // Map badge data based on the component's name from route.
    // Make sure to use component names that match the names stored in the database.
    // Similar to how we handle UserHub Routes.

    const badgeData: Record<string, number | undefined> = {
      chatsPage: unReadChatCountQuery.data,
      submissionWorkflowReviewsPage: newReviewStats?.statusCount ?? 0,
      submissionAssignmentPage: session.profile?.submissionAssignments?.filter(
        (submissionAssignment) => {
          return submissionAssignment.isActive;
        },
      ).length,
    };

    const badgeValue = badgeData[routeComponentName];

    // Display a badge if there's a value.
    if (badgeValue) {
      return <IonBadge className="ml-4">{badgeValue}</IonBadge>;
    }

    return null;
  };

  const renderMenuItems = (routeConfigs: RouteConfigView[]) => {
    return routeConfigs.map((route) => {
      // When using the native app, only load the routes
      // for the native app
      if (device.isNativeApp && !route.forConferenceApp) {
        return null; // Skip rendering this route
      }

      const IconComponent = iconMapper[route.iconName];
      return (
        <div key={`route-nav-${route.label}`} className="pl-2">
          <button
            onClick={() =>
              history.push(mapPathParamsToUrl(route.url, route.pathParam))
            }
            className={`sidebar-menu-selector flex flex-row w-full rounded-lg items-center text-start group  ${
              route.label === "Communication" && "sm:hidden"
            }`}
          >
            {IconComponent && (
              <IconComponent className="sidebar-menu-svg ion-like-icon" />
            )}
            <div
              className={`sidebar-menu-title ${
                route.label === "Communication" && "sm:hidden"
              }`}
            >
              {route.label}
            </div>
            {getMenuItemBadge(route.componentName)}
          </button>
        </div>
      );
    });
  };

  const userRoutes = session.society!.routes!.filter((route) => {
    if (!route.checkPermissionTags && route.displayInNav) {
      return true;
    }

    return route.tags.find(
      (tag) =>
        session.profile?.tags?.some(
          (userTag: TagView) => userTag.id === tag.id && route.displayInNav,
        ),
    );
  });

  // Sort the filtered userRoutes based on the "navMenuOrderNum" property
  userRoutes.sort((a, b) => a.navMenuOrderNum - b.navMenuOrderNum);

  return (
    <IonMenu
      type="overlay"
      contentId="main-content"
      disabled={!session.isAuthenticated}
    >
      <IonContent className="border-r border-neutral-mid-100 ios-side-menu-wrapper">
        <div className="flex flex-col justify-between h-full">
          <IonList>
            <div className="flex ml-3.5 items-center">
              <SocietyLogo className="h-9.5 w-9.5 mb-2 !border-society" />
              <h2 className="mb-1.5 ml-2 text-title">
                {session.society?.abbreviation}
              </h2>
            </div>
            {renderMenuItems(userRoutes)}
          </IonList>

          <div key="special-action-bank" className="menu-action-bank">
            <div className="menu-section-border" />
            {location.pathname.includes("conference") && (
              <div className="w-full ml-1.5">
                <select
                  onChange={(e) => {
                    session.setTimezone(e.target.value);
                  }}
                >
                  <option value="system">Local Time</option>
                  <option value="conference">Conference Venue Time</option>
                </select>{" "}
              </div>
            )}
            <NewAppButton
              className="menu-action-bank-button"
              onClick={() => session.logOut()}
            >
              <LogOutOutlineIcon className="menu-action-bank-icon ion-like-icon" />
              Sign Out
            </NewAppButton>
          </div>
          <div key="special-action-close" className="fixed bottom-2 right-2">
            <IonMenuToggle>
              <NewAppButton className="menu-button-close h-3 w-3">
                <ChevronBackIcon className="ion-like-icon" />
              </NewAppButton>
            </IonMenuToggle>
          </div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default MainMenu;
