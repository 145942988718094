import { apiClient, buildApiClient } from "@api";
import { SocietyView } from "@parthenon-management/pillar-types";
import { NewInvoice } from "types/Invoice";
import { CheckoutDataModel } from "types/checkoutPage/checkout-data-model";
import {
  AuthData,
  CardData,
  DispatchDataResponse,
  SecureData,
} from "types/payment/accept-suite";
import { getSocietyId } from "utils/getSocietyId";
import { getProfileId } from "utils/sessionStorage/user";

export const submitSecuredPaymentData = async (
  cardData: CardData,
  society: SocietyView,
): Promise<DispatchDataResponse> => {
  try {
    buildApiClient();

    const authData: AuthData = {
      clientKey: society.societySettingsPublic?.authorizeNetPublicClientId!,
      apiLoginID:
        society.societySettingsPublic?.authorizeNetMerchantNameLoginId!,
    };

    const secureData: SecureData = {
      authData,
      cardData,
    };

    const response = await new Promise<DispatchDataResponse>((resolve) => {
      window.Accept.dispatchData(
        secureData,
        (response: DispatchDataResponse) => {
          resolve(response);
        },
      );
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const completeCheckout = async (checkoutData: CheckoutDataModel) => {
  buildApiClient();
  const response = await apiClient!.post(
    `api/v1/society/${getSocietyId()}/accounting/payments/process-payment`,
    checkoutData,
  );
  return response;
};

export const createNewInvoice = async (newInvoice: NewInvoice) => {
  buildApiClient();
  const response = await apiClient!.post(
    `/api/v1/society/${getSocietyId()}/accounting/invoices/${getProfileId()}/new`,
    newInvoice,
  );
  return response;
};
