import { fetchApiQuery } from "@api";
import { SocietyView } from "@parthenon-management/pillar-types";
import { GetSocietyCustomChoices } from "api/routes/custom-choices";
import { uniqueId } from "lodash";
import { Serializer, ChoicesRestful, SurveyModel } from "survey-core";
import { getSocietyId } from "utils/getSocietyId";
export const form_category = {
  //TODO: Use prisma types
  Conference_Submission: "Conference_Submission",
  Travel_Award: "Travel_Award",
  Conference_Registration: "Conference_Registration",
  Profile: "Profile",
  Scorecard: "Scorecard",
  Disclosure: "Disclosure",
  Other: "Other",
};

export const custom_properties = (society: SocietyView): void => {
  Serializer.addProperty("survey", {
    name: "archived",
    type: "boolean",
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "locked",
    type: "boolean",
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "category",
    type: "dropdown",
    category: "general",
    choices: form_category,
  });
  Serializer.addProperty("survey", {
    name: "workflowComplexId",
    type: "number",
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "workflowInstanceId",
    type: "number",
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "conferenceId",
    type: "number",
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "society_id",
    type: "number",
    displayName: "Society ID",
    readOnly: true,
    category: "general",
  });
  Serializer.addProperty("survey", {
    name: "form_id",
    type: "number",
    displayName: "Form ID",
    readOnly: true,
  });
  Serializer.addProperty("survey", {
    name: "created_at",
    type: "date",
    displayName: "Created At",
    readOnly: true,
  });
  Serializer.addProperty("survey", {
    name: "created_by",
    type: "string",
    displayName: "Created By",
    readOnly: true,
  });
  Serializer.addProperty("survey", {
    name: "modified_by",
    type: "string",
    displayName: "Modified By",
    readOnly: true,
  });
  Serializer.addProperty("question", {
    name: "isEventDetail",
    type: "boolean",
    category: "general",
  });
  Serializer.addProperty("question", {
    name: "eventField",
    type: "string",
    category: "general",
  });
  Serializer.addProperty("question", {
    name: "isReportedInScore",
    displayName: "Is reported in on scorecard",
    type: "boolean",
    category: "general",
  });
  Serializer.addProperty("question", {
    name: "shortTitle",
    type: "string",
    category: "general",
  });
  Serializer.addProperty("question", {
    name: "Submission Title Question",
    type: "boolean",
    category: "general",
  });
  Serializer.addProperty("question", {
    name: "pillar_choices_question_name",
    type: "string",
    category: "Choices from Pillar",
  });
  Serializer.addProperty("question", {
    displayName: "Submission Title Question",
    name: "SubmissionTitle",
    type: "boolean",
    category: "general",
  });
  Serializer.addProperty("question", {
    name: "lazyLoadingUniqueId",
    type: "string",
  });
  Serializer.addProperty("question", {
    name: "willUpdateProfile",
    type: "boolean",
  });
  Serializer.addProperty("question", {
    name: "profileField",
    type: "string",
  });
  Serializer.addProperty("question", {
    name: "isDisclosureQuestion",
    type: "boolean",
  });
};
