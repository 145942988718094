/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  IQuestion,
  Question,
  Serializer,
} from "survey-core";
import { SjsCustomQuestion } from "../types/sjs-custom-question";
export const SocietyUserScalarFieldEnum = {
  //TODO: Use Prisma Types
  id: "id",
  profile_id: "profile_id",
  degree: "degree",
  display_name: "display_name",
  first_name: "first_name",
  job_title: "job_title",
  last_name: "last_name",
  middle_name: "middle_name",
  prefix: "prefix",
  profession: "profession",
  research: "research",
  surname: "surname",
  affiliation: "affiliation",
  auth_invalid_before: "auth_invalid_before",
  deceased_date: "deceased_date",
};
export const InstitutionScalarFieldEnum = {
  //TODO: Use Prisma Types
  id: "id",
  profile_id: "profile_id",
  type_id: "type_id",
  name: "name",
  slot_number: "slot_number",
};
const show_hide = (
  user_type_question: IQuestion,
  field: string,
  show: boolean,
) => {
  if (field === "id") {
    return;
  }
  const question = user_type_question.survey.getQuestionByName(field);
  if (!question) {
    return;
  }
  if (question.getPropertyByName("force_hide")?.value) {
    return;
  }
  question.visible = show;
};

const question_name = SjsCustomQuestion.profile_type;
export const profile_type_question = async () => {
  const login_details_object: ICustomQuestionTypeConfiguration = {
    name: question_name,
    title: "Profile Type",
    questionJSON: {
      type: "dropdown",
      choices: [
        { value: "Society_User", text: "Society User" },
        { value: "Institution", text: "Institution" },
      ],
      required: true,
    },
    onLoaded(question: Question) {
      question.name = question_name;
      Serializer.addProperty(question_name, {
        name: "changePasswordTitle",
        type: "text",
        default: "Change Password",
        category: "general",
      });

      Serializer.getProperty(question_name, "name").visible = false;
      Serializer.getProperty(question_name, "visible").visible = false;
      Serializer.getProperty(question_name, "isRequired").visible = false;
      Serializer.getProperty(question_name, "readOnly").visible = false;

      Serializer.getProperty(question_name, "visibleIf").visible = false;

      Serializer.getProperty(question_name, "enableIf").visible = false;
      Serializer.getProperty(question_name, "requiredIf").visible = false;
      Serializer.getProperty(question_name, "clearIfInvisible").visible = false;
      Serializer.getProperty(question_name, "defaultValueExpression").visible =
        false;
      Serializer.getProperty(question_name, "defaultValue").visible = false;
      Serializer.getProperty(question_name, "startWithNewLine").visible = false;
      Serializer.getProperty(question_name, "valueName").visible = false;
      Serializer.getProperty(question_name, "useDisplayValuesInTitle").visible =
        false;
      Serializer.getProperty(question_name, "correctAnswer").visible = false;

      Serializer.getProperty(question_name, "requiredErrorText").visible =
        false;

      Serializer.getProperty(question_name, "validators").visible = false;
      // throw new Error("Function not implemented.");
    },
    onInit() {
      //Override titleLocation property attributes for "shippingaddress" class by making it invisible in property grid and change its default value
    },
    onCreated(question: Question) {
      // throw new Error("Function not implemented.");
    },
    onAfterRender: function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
    onAfterRenderContentElement: function (
      question: Question,
      element: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onPropertyChanged: function (
      question: Question,
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      //  throw new Error("Function not implemented.");
    },
    onValueChanged: function (
      question: Question,
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      Object.keys({
        ...SocietyUserScalarFieldEnum,
        login_details: "login_details",
      }).forEach((societyUserField) => {
        show_hide(question, societyUserField, newValue === "Society_User");
      });
      Object.keys(InstitutionScalarFieldEnum).forEach((institutionField) => {
        show_hide(question, institutionField, newValue === "Institution");
      });
      // throw new Error("Function not implemented.");
    },
    onItemValuePropertyChanged: function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: any,
    ): void {
      // throw new Error("Function not implemented.");
    },
  };
  if (!ComponentCollection.Instance.getCustomQuestionByName(question_name)) {
    ComponentCollection.Instance.add(login_details_object);
  }
};
