import { ApiQueryConfig } from "@api";
import {
  ApiResponse,
  InvoiceLiteView,
  InvoiceView,
} from "@parthenon-management/pillar-types";
const GetInvoice: ApiQueryConfig<
  ApiResponse<InvoiceLiteView | InvoiceView>,
  {
    societyId: string;
    profileId: string;
    invoiceId: string;
  },
  Record<string, unknown>
> = {
  queryKey: {
    baseQueryKey: ["GetInvoices"],
    dynamicQueryKey: ["invoiceId"],
  },
  apiPath:
    "/api/v1/society/{societyId}/profiles/{profileId}/invoice/{invoiceId}",
};

export { GetInvoice };
