import NativeAppPDFViewer from "@components/common/pdf/NativeAppPDFViewer";
import PageComponent from "components/common/PageComponent";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ScreenOrientation } from "@capacitor/screen-orientation";

const NativeAppPDFViewerPage = () => {
  const { pdfData } = useParams<{ pdfData: string }>();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    const getPdfLocalUrl = async () => {
      try {
        if (!pdfData) {
          throw new Error("PDF data is missing");
        }

        const decodedPdfData = decodeURIComponent(pdfData);
        setPdfUrl(decodedPdfData);
      } catch (error) {
        console.error("Error decoding PDF data:", error);
      }
    };

    getPdfLocalUrl();
  }, [pdfData]);

  useEffect(() => {
    const lockOrientation = async () => {
      await ScreenOrientation.lock({
        orientation: "landscape",
      });
    };

    const unlockOrientation = async () => {
      await ScreenOrientation.unlock();
    };

    lockOrientation();

    return () => {
      unlockOrientation();
    };
  }, []);

  return (
    <PageComponent>
      <PageComponent.Header></PageComponent.Header>
      <PageComponent.Content>
        <div className="h-full w-full">
          {pdfUrl && <NativeAppPDFViewer url={pdfUrl} />}
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default NativeAppPDFViewerPage;
