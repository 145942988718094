import {
  ApiResponse,
  PaginatedResults,
  ProfileScoreHistoryReport,
  ProfileView,
  TagView,
} from "@parthenon-management/pillar-types";
import { ApiQueryConfig } from "api/types";
import { FiltersRequest } from "@parthenon-management/pillar-types/dist/src/utils/helpers/filter-where-clause";

// TODO: This should be using a Profile view not the old types
// If it isn't imported from @types, it's old and shouldn't be used.
// Only views following the new pattern should go into the exports for @types
// They should be added to the index at /types/index.ts.
const GetProfile: ApiQueryConfig<
  ApiResponse<ProfileView>,
  { societyId: string; profileId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/profiles/{profileId}",
  queryKey: { baseQueryKey: ["GetProfile"], dynamicQueryKey: ["profileId"] },
};

// TODO: This should be using a Profile view not the old types
// If it isn't imported from @types, it's old and shouldn't be used.
// Only views following the new pattern should go into the exports for @types
// They should be added to the index at /types/index.ts.
const GetProfiles: ApiQueryConfig<
  ApiResponse<PaginatedResults<ProfileView>>,
  { societyId: string },
  FiltersRequest
> = {
  apiPath: "/api/v1/society/{societyId}/profiles",
  queryKey: { baseQueryKey: ["GetProfiles"], dynamicQueryKey: ["societyId"] },
};

const GetProfileTags: ApiQueryConfig<
  ApiResponse<TagView[]>,
  { societyId: string; profileId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/tags/profile/{profileId}",
  queryKey: {
    baseQueryKey: ["GetProfileTags"],
    dynamicQueryKey: ["profileId"],
  },
};

const GetScoreHistoryReport: ApiQueryConfig<
  ApiResponse<ProfileScoreHistoryReport>,
  { societyId: string; profileId: string; conferenceId?: string },
  Record<string, number>
> = {
  apiPath:
    "/api/v1/society/{societyId}/profiles/{profileId}/scoreHistoryReport",
  queryKey: {
    baseQueryKey: ["GetScoreHistoryReport"],
    dynamicQueryKey: ["profileId", "conferenceId"],
  },
};

export { GetProfile, GetProfiles, GetProfileTags, GetScoreHistoryReport };
