import { ApiResponse, SessionView } from "@parthenon-management/pillar-types";
import { ApiMutationConfig, ApiQueryConfig } from "api/types";
const ImpersonateProfile: ApiQueryConfig<
  ApiResponse<SessionView>,
  { societyId: string; profileId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/auth?profileId={profileId}",
  queryKey: {
    baseQueryKey: ["ImpersonateProfile"],
    dynamicQueryKey: ["profileId"],
  },
};

const VerifyAuthenticatedUser: ApiQueryConfig<
  ApiResponse<SessionView>,
  { societyId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/auth",
  queryKey: {
    baseQueryKey: ["VerifyAuthenticatedUser"],
  },
};

const RecoverAccount: ApiMutationConfig<
  ApiResponse<SessionView>,
  { societyId: string },
  { email: string }
> = {
  method: "POST",
  apiPath: "/api/v1/society/{societyId}/auth/recover",
};

const AuthenticateUser: ApiMutationConfig<
  ApiResponse<SessionView>,
  { societyId: string },
  { urlParamaterizedAuth: { username: string; password: string } }
> = {
  method: "POST",
  apiPath: "/api/v1/society/{societyId}/auth",
};

const DeauthenticateUser: ApiMutationConfig<
  { loggedOut: boolean },
  { societyId: string; profileId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/profiles/{profileId}/logout",
  method: "POST",
};

export {
  AuthenticateUser,
  DeauthenticateUser,
  ImpersonateProfile,
  VerifyAuthenticatedUser,
  RecoverAccount,
};
