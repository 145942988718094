import { Switch } from "@headlessui/react";
import { FormikValues, useFormikContext } from "formik";

import React from "react";

import NewAppInput from "./NewAppInput";
import { joinClassNames } from "utils/joinClassNames";
import { NewAppInputProps } from "types/newAppForm";

export type NewAppSwitchProps = NewAppInputProps & {
  name: string;
};

const NewAppSwitch = (props: NewAppSwitchProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  return (
    <NewAppInput
      name={props.name}
      label={props.label}
      labelClassName={props.labelClassName}
    >
      <Switch
        checked={values[props.name] || false}
        onChange={(bool: boolean) => {
          setFieldValue(props.name, bool);
        }}
        className={joinClassNames(
          values[props.name] ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
        )}
      >
        <span className="sr-only">{props.label}</span>
        <span
          aria-hidden="true"
          className={joinClassNames(
            values[props.name] ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
          )}
        />
      </Switch>
    </NewAppInput>
  );
};

export default NewAppSwitch;
