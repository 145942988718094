import { ApiMutationConfig, GetInvoice } from "@api";
import { ApiResponse, InvoiceView } from "@parthenon-management/pillar-types";
const ApplyPromoCode: ApiMutationConfig<
  ApiResponse<InvoiceView>,
  {
    societyId: string;
    profileId: string;
    productId: string;
    invoiceItemId: string;
    promoCodeKey: string;
  },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/accounting/products/{productId}/invoice-item/{invoiceItemId}/apply-promo-code?promoCodeKey={promoCodeKey}",
  method: "POST",
  invalidates: [
    {
      baseQueryKey: GetInvoice.queryKey.baseQueryKey,
      dynamicQueryKey: ["societyId", "profileId", "invoiceId"],
      exact: false,
    },
  ],
};

export { ApplyPromoCode };
