import PageComponent from "components/common/PageComponent";

const NewWorkflowPage = () => {
  return (
    <PageComponent>
      <PageComponent.Header>Form</PageComponent.Header>
      <PageComponent.Content>
        <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto text-center">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Thank You
            </h2>
            <a href="/my-submissions">You can view your submission here</a>
          </div>
        </div>
      </PageComponent.Content>
    </PageComponent>
  );
};

export default NewWorkflowPage;
