import { ComponentPropsWithoutRef } from "react";
import Mustache from "mustache";

type MustacheTemplateComponentProps<T> = Omit<
  ComponentPropsWithoutRef<"div">,
  "dangerouslySetInnerHtml"
> & {
  template: string;
  data: T;
};

const MustacheTemplateComponent = <T extends object>({
  template,
  data,
  ...props
}: MustacheTemplateComponentProps<T>) => {
  const html = Mustache.render(template, data);
  return <div dangerouslySetInnerHTML={{ __html: html }} {...props} />;
};

export default MustacheTemplateComponent;
