import { useApiQuery, VerifyToken } from "@api";
import LoadingPage from "@components/common/LoadingPage";
import ProtectedRoute from "components/auth/ProtectedRoute";
import useDeviceInfo from "hooks/useDeviceInfo";
import useSession from "hooks/useSession";
import AccessDeniedPage from "pages/AccessDeniedPage";
import AccountRecoverPage from "pages/AccountRecoverPage";
import ChatPage from "pages/chat/ChatPage";
import CheckoutPage from "pages/CheckoutPage";
import FormPage from "pages/FormPage";
import LoginPage from "pages/LoginPage";
import NativeAppPDFViewerPage from "pages/NativeAppPDFViewerPage";
import NewProfilePage from "pages/NewProfilePage";
import PageNotFound from "pages/PageNotFound";
import ProductPage from "pages/ProductPage";
import SubmissionPage from "pages/SubmissionPage";
import NewWorkflowPage from "pages/workflow/new/complexWorkflowId";
import WorkflowCompletePage from "pages/WorkflowCompletePage";
import { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import { getSocietyId } from "utils/getSocietyId";

// Lazy loading the dynamic routes
const pageComponentMapper: Record<string, React.ComponentType> = {
  overviewPage: lazy(() => import("pages/OverviewPage")),
  conferenceSubmissionPage: lazy(
    () => import("pages/ConferenceSubmissionPage"),
  ),
  conferenceRegistrationPage: lazy(
    () => import("pages/ConferenceRegistrationPage"),
  ),
  myGroupsPage: lazy(() => import("pages/MyGroupsPage")),
  myCertificatesPage: lazy(() => import("pages/MyCertificatesPage")),
  conferencePage: lazy(() => import("pages/conference/ConferencePage")),
  educationPage: lazy(() => import("pages/EducationPage")),
  financePage: lazy(() => import("pages/finance/FinancePage")),
  invoicePage: lazy(() => import("pages/finance/InvoicePage")),
  communicationPage: lazy(() => import("pages/CommunicationPage")),
  myFilesPage: lazy(() => import("pages/MyFilesPage")),
  chatsPage: lazy(() => import("pages/chat/ChatsPage")),
  directoriesPage: lazy(() => import("pages/DirectoriesPage")),
  editUserDetailPage: lazy(() => import("pages/user/EdituserDetailPage")),
  submissionWorkflowReviewsPage: lazy(
    () => import("pages/SubmissionWorkflowReviewsPage"),
  ),
  submissionWorkflowReviewPage: lazy(
    () => import("pages/SubmissionWorkflowReviewPage"),
  ),
  submissionAssignmentPage: lazy(
    () => import("@components/submission/SubmissionAssignment"),
  ),
  submissionWorkflowDecisionPage: lazy(
    () => import("pages/SubmissionWorkflowDecisionPage"),
  ),
  newEventPage: lazy(() => import("pages/conference/NewEventPage")),
  eventDetailsPage: lazy(() => import("pages/conference/EventDetailsPage")),
  conferenceSchedulePage: lazy(
    () => import("pages/conference/ConferenceSchedulePage"),
  ),
  rosterPage: lazy(() => import("pages/RosterPage")),
  mySubmissionsPage: lazy(() => import("pages/MySubmissionsPage")),
  decisionMeetingPage: lazy(() => import("pages/DecisionMeetingPage")),
  exhibitHallPage: lazy(() => import("pages/conference/ExhibitHallPage")),
  posterGalleryPage: lazy(() => import("pages/conference/PosterGalleryPage")),
};

const UserHubRoutes = () => {
  const session = useSession();
  const location = useLocation();
  const device = useDeviceInfo();

  const params = new URLSearchParams(location.search);
  const tokenValue = params.get("token");
  const { data: tokenData, isLoading: tokenIsLoading } = useApiQuery(
    VerifyToken,
    {
      societyId: getSocietyId().toString(),
      tokenValue: tokenValue,
    },
    {},
    {},
    { staleTime: -1, enabled: !!tokenValue },
  );
  useEffect(() => {
    if (!session.routesLoaded && session.society?.routes) {
      session.setRoutesLoaded(true);
    }
  }, [session.society?.routes, session.routesLoaded]);

  if (!session.society || (tokenIsLoading && !!tokenValue)) {
    return <LoadingPage />;
  }

  if (session?.societyAdmin || session.societyAdminId) {
    return (
      <>
        You are currently logged into admin at this same address. Please use
        incognito or the impersonation feature to access hub.
      </>
    );
  }
  if (tokenData) {
    if (tokenData.data.body?.usage.login) {
      const loginUsage = tokenData.data.body.usage.login;
      let url = loginUsage.routePath;
      for (const key in loginUsage.pathParam) {
        url = url.replace(`:${key}`, loginUsage.pathParam[key].toString());
      }
      session.impersonateProfile(loginUsage.profileId, url);
    } else {
      throw new Error("Token is not a login token only login tokens supported");
    }
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        {!session.isAuthenticated && (
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
        )}
        <Route exact path="/access-denied">
          <AccessDeniedPage />
        </Route>

        {/* Sorting here to ensure paths with dynamic params are rendered last. Paths match top down, so we want to go from specific to broad in order. */}
        {session
          .society!.routes!.slice()
          .sort((a) => {
            if (Object.keys(a.pathParam).length === 0) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((route) => {
            const Component = pageComponentMapper[route.componentName];
            const isPublic = route.isPublic;

            if (isPublic) {
              return (
                <Route
                  exact
                  path={route.url}
                  render={() => {
                    return <Component />;
                  }}
                  key={route.id}
                />
              );
            } else {
              return (
                <ProtectedRoute
                  exact
                  path={route.url}
                  key={route.id}
                  checkPermission={route.checkPermissionTags}
                  permissionTags={route.tags}
                  render={() => {
                    return <Component />;
                  }}
                />
              );
            }
          })}

        {session.society?.societySettingsPublic?.ablyEnabled && (
          <ProtectedRoute exact path="/chats/:id">
            <ChatPage />
          </ProtectedRoute>
        )}
        <ProtectedRoute exact path="/submission/:id">
          <SubmissionPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/form/:id">
          <FormPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/product/workflow/:id">
          <ProductPage />
        </ProtectedRoute>

        {device.isWeb && (
          <ProtectedRoute exact path="/payment/:invoiceId">
            <CheckoutPage />
          </ProtectedRoute>
        )}

        <ProtectedRoute exact path="/workflow/new/:workflowId">
          <NewWorkflowPage />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path="/workflow/complete"
          render={() => {
            return <WorkflowCompletePage />;
          }}
        />
        <Route exact path="/account/new">
          <NewProfilePage />
        </Route>
        <Route exact path="/account/recover">
          <AccountRecoverPage />
        </Route>

        {/* I intentionally added this route here since this will be a system route and not society-specific */}
        <Route exact path="/pdf-viewer/:pdfData">
          <NativeAppPDFViewerPage />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default UserHubRoutes;
