import { useQuery } from "@tanstack/react-query";
import { getSetting } from "api/settings";
import { society_setting_name } from "constants/settings/society_setting_name";

const CheckPaymentForm = () => {
  //todo remove and pass in, need to get help here.
  const checkPaymentConfig = useQuery({
    queryKey: ["checkInfo"],
    queryFn: async () => {
      return await getSetting(society_setting_name.check_payment_configuration);
    },
    refetchOnWindowFocus: false,
    enabled: true,
  });

  return (
    <section aria-labelledby="payment-heading">
      <div id="payment-heading" className="text-title pb-1">
        Check Payment Details
      </div>
      <div className="flex flex-col space-y-2">
        {checkPaymentConfig.data && (
          <p>{JSON.parse(checkPaymentConfig.data.value).instructions}</p>
        )}
      </div>
    </section>
  );
};
export default CheckPaymentForm;
