import { useChannel } from "@ably-labs/react-hooks";
import { useIonToast } from "@ionic/react";
import { useQueryClient } from "@tanstack/react-query";
import { ChannelEventType, ChannelName } from "constants/AblysChannel";
import useSession from "hooks/useSession";
import { arrowForwardOutline } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";

type Props = {
  children: React.ReactNode;
};

// TODO: Due to the new design, I will refactor this in the next PR and update all pages that are using it.
// We will no longer use this as the page header text.
// Added a new component in common/page/header to be used as the page header.

const AblyComponent = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [presentToast] = useIonToast();

  const userIsOnChatRoute = () => {
    const chatsPath = "/chats";

    if (
      history?.location?.pathname === chatsPath ||
      history?.location?.pathname?.startsWith(chatsPath + "/")
    ) {
      return true;
    }

    return false;
  };
  const session = useSession();
  useChannel(
    `${ChannelName.SOCIETY_ALERT}${session.societyId}`,
    ChannelEventType.NOTIFICATION,
    (message) => {
      presentToast({
        message: message.data,
        duration: 5000,
        position: "top",
      });
    },
  );

  useChannel(
    `Society:${session.societyId}:Chat:ProfileId:${session.profileId}`,
    ChannelEventType.NEW_MESSAGE,
    () => {
      queryClient.invalidateQueries(["chats"]);

      if (userIsOnChatRoute()) {
        return;
      }

      presentToast({
        message: "You have a new message!",
        duration: 5000,
        position: "top",
        color: "secondary",
        buttons: [
          {
            text: "",
            icon: arrowForwardOutline,
            handler: () => {
              history.push("/chats");
            },
          },
        ],
      });
    },
  );
  return <>{children}</>;
};

export default AblyComponent;
