import { useApiMutation } from "@api";
import NewAppForm from "@components/common/newform/NewAppForm";
import NewAppSubmitButton from "@components/common/newform/NewAppSubmitButton";
import NewAppTextInput from "@components/common/newform/NewAppTextInput";
import NewAppModalBody, {
  NewAppModalHandle,
} from "@components/common/newmodal/NewAppModalBody";
import { InvoiceItemView } from "@parthenon-management/pillar-types";
import { ApplyPromoCode } from "api/routes/promo-code";
import { FormikValues } from "formik";
import { useState } from "react";
import { getSocietyId } from "utils/getSocietyId";
import { getProfileId } from "utils/sessionStorage/user";

interface props {
  modalRef: React.RefObject<NewAppModalHandle>;
  invoiceItem: InvoiceItemView | undefined;
}

const ApplyPromoCodeModal = ({ modalRef, invoiceItem }: props) => {
  const [promoCodeKey, setPromoCodeKey] = useState("");

  const { mutateAsync: applyPromoCodeAsync } = useApiMutation(ApplyPromoCode, {
    societyId: getSocietyId().toString(),
    profileId: getProfileId().toString(),
    invoiceItemId: String(invoiceItem?.id),
    promoCodeKey: promoCodeKey,
    productId: String(invoiceItem?.product?.id),
  });

  const handleApplyPromoCode = async (values: FormikValues) => {
    setPromoCodeKey(values.promoCodeKey);
    await applyPromoCodeAsync({});
    modalRef.current?.close();
  };
  return (
    <NewAppModalBody ref={modalRef} title="Apply Promo Code">
      <NewAppForm
        className="mt-1"
        handleSubmit={handleApplyPromoCode}
        handleChanged={function (changedValues: FormikValues): void {
          // throw new Error("Function not implemented.");
        }}
      >
        <div className="flex flex-col items-center ">
          <NewAppTextInput name={"promoCodeKey"} label="Enter Promo Code" />
          <div className="flex w-full justify-end mt-2">
            <NewAppSubmitButton label="Apply" />
          </div>
        </div>
      </NewAppForm>
    </NewAppModalBody>
  );
};

export default ApplyPromoCodeModal;
