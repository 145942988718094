import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  ItemValue,
  Question,
  QuestionCheckboxModel,
  QuestionCompositeModel,
  QuestionDropdownModel,
  Serializer,
} from "survey-core";
import { SurveyModel } from "survey-core";

import { formatPropertyName } from "../propertyHelpers";
import elements from "./elements.json";
import { IProfileAddressContactInformationQuestion } from "./iquestion";
import {
  AddressContactInformationTypeView,
  AddressContactInformationUseView,
  SocietyView,
} from "@parthenon-management/pillar-types";
import {
  ContactInformationUI,
  ProfileAddressUI,
} from "@parthenon-management/pillar-types/dist/src/ui/types/profile/profile-address-contact-information";
import { SjsCustomQuestion } from "@components/surveyJS/types/sjs-custom-question";

export const question_name = SjsCustomQuestion.profileaddresscontactinformation;
type UseTypeTuple = {
  chosenUses: string[];
  chosenTypes: string[];
};
export const profileAddressContactInformationQuestion = async (
  society?: SocietyView,
) => {
  const contact_info_object: ICustomQuestionTypeConfiguration &
    IProfileAddressContactInformationQuestion = {
    name: question_name,
    title: "Address",
    elementsJSON: elements,
    onItemValuePropertyChanged: () => {
      //null
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCreated(question: QuestionCompositeModel): void {
      //null
    },
    onInit() {
      Serializer.addProperty(question_name, {
        name: "country_default",
        type: "singlevalue",
        displayName: formatPropertyName("Country"),
        category: "Default",
      });
      Serializer.addProperty(question_name, {
        name: "region_default",
        type: "singlevalue",
        displayName: formatPropertyName("Region"),
        category: "Default",
      });
      Serializer.addProperty(question_name, {
        name: "type_default",
        type: "dropdown",
        displayName: formatPropertyName("Type"),
        category: "Default",
      });
      Serializer.addProperty(question_name, {
        name: "type_invisible",
        type: "multiplevalues",
        displayName: formatPropertyName("Hide Type"),
        category: "Hide Use & Type",
      });
      Serializer.addProperty(question_name, {
        name: "use_default",
        type: "multiplevalues",
        displayName: formatPropertyName("Use"),
        category: "Default",
      });
      Serializer.addProperty(question_name, {
        name: "use_invisible",
        type: "multiplevalues",
        displayName: formatPropertyName("Hide Use"),
        category: "Hide Use & Type",
      });
      Serializer.addProperty("Survey", {
        name: question_name + "_use",
        type: "itemvalues",
        displayName: question_name + "_use",
        visible: false,
        category: question_name,
      });
      Serializer.addProperty("Survey", {
        name: question_name + "_type",
        type: "itemvalues",
        displayName: question_name + "_type",
        visible: false,
        category: question_name,
      });
      contact_info_object.elementsJSON.forEach((element: Question) => {
        if (
          element.name === "contact_information_id" ||
          element.name === "profile_address_id"
        ) {
          return;
        }
        Serializer.addProperty(question_name, {
          name: `${element.name}_required`,
          type: "boolean",
          displayName: formatPropertyName(element.name + " Required"),
          default: false,
          category: "Required Fields",
        });
        Serializer.addProperty(question_name, {
          name: `${element.name}_visible`,
          type: "boolean",
          displayName: formatPropertyName(element.name + "  Visible"),
          default: true,
          category: "Visible Fields",
        });
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSurveyVisibleChanged(sender: SurveyModel, options: any) {
      if (options.question.getType() === question_name) {
        contact_info_object.setValuesFromProperties(options.question);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    onAfterRender(question: QuestionCompositeModel, htmlElement: any): void {
      //null
    },
    onLoaded(question: QuestionCompositeModel) {
      if (!question.getSurvey()) {
        return;
      }
      question.clearIfInvisible = "none";
      this.updateUseAndTypeLists(question);
      this.setValuesFromProperties(question);
      const survey = question.getSurvey() as SurveyModel;
      if (survey.isDesignMode) {
        this.hideProperties();
      }
      if (!survey.onVisibleChanged.hasFunc(this.onSurveyVisibleChanged)) {
        survey.onVisibleChanged.add(this.onSurveyVisibleChanged);
      }
    },
    updateUseAndTypeLists: (question: QuestionCompositeModel) => {
      if (society) {
        const use_list = society?.profileContactInformationUse!.map(
          (value: AddressContactInformationUseView) => {
            return new ItemValue(value.id, value.description);
          },
        );
        const type_list = society?.profileContactInformationType!.map(
          (value: AddressContactInformationTypeView) => {
            return new ItemValue(value.id, value.description);
          },
        );
        Serializer.setObjPropertyValue(
          question.getSurvey(),
          question_name + "_type",
          type_list,
        );
        Serializer.setObjPropertyValue(
          question.getSurvey(),
          question_name + "_use",
          use_list,
        );
      }
      const use_list = Serializer.getObjPropertyValue(
        question.getSurvey(),
        question_name + "_use",
      );
      question.getPropertyByName("use_default").setChoices(use_list);
      question.getPropertyByName("use_invisible").setChoices(use_list);
      const type_list = Serializer.getObjPropertyValue(
        question.getSurvey(),
        question_name + "_type",
      );
      question.getPropertyByName("type_default").setChoices(type_list);
      question.getPropertyByName("type_invisible").setChoices(type_list);
    },
    setValuesFromProperties(question: QuestionCompositeModel) {
      this.elementsJSON.forEach((element: Question) => {
        if (
          element.name === "contact_information_id" ||
          element.name === "profile_address_id"
        ) {
          return;
        }
        this.setVisibilityValue(question, element.name + "_visible");
        this.setDefaultValue(question, element.name + "_default");
        this.setRequiredValue(question, element.name + "_required");
      });
    },
    hideProperties() {
      [
        "name",
        "visible",
        "isRequired",
        "readOnly",
        "enableIf",
        "requiredIf",
        "clearIfInvisible",
        "defaultValueExpression",
        "defaultValue",
        "startWithNewLine",
        "valueName",
        "useDisplayValuesInTitle",
        "correctAnswer",
        "requiredErrorText",
        "validators",
      ].forEach((prop) => {
        Serializer.getProperty(question_name, prop).visible = false;
      });
    },
    onAfterRenderContentElement(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: QuestionCompositeModel,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      element: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      htmlElement: any,
    ): void {
      //null
    },
    getAllUseOrTypeChoices(
      question: QuestionCompositeModel,
      useOrType: string,
    ): Array<ItemValue> {
      const items = Serializer.getObjPropertyValue(
        question.getSurvey(),
        question_name + "_" + useOrType,
      );
      return items;
    },
    getFilteredUseOrTypeChoices(
      question: QuestionCompositeModel,
      useOrType: string,
    ): Array<ItemValue> {
      const choices = this.getAllUseOrTypeChoices(question, useOrType);
      const invisibleUseOrType =
        question.getPropertyValue(useOrType + "_invisible") ?? [];
      const filtered_choices = choices.filter(
        (value: ItemValue) => !invisibleUseOrType.includes(value.value),
      );
      return filtered_choices;
    },
    setVisibilityValue(question: QuestionCompositeModel, propertyName: string) {
      const propertyValue = question.getPropertyValue(propertyName);
      if (propertyName.startsWith("use_") || propertyName.startsWith("type_")) {
        const useOrType = propertyName.startsWith("use_") ? "use" : "type";
        const target_question = question.contentPanel.getQuestionByName(
          useOrType,
        ) as QuestionCheckboxModel;
        const filtered_choices = this.getFilteredUseOrTypeChoices(
          question,
          useOrType,
        );
        target_question.choices = filtered_choices;
        target_question.visible =
          question.getPropertyValue(useOrType + "_visible") &&
          target_question.choices.length > 0;
        return;
      }
      const targetQuestion = question.contentPanel.getQuestionByName(
        propertyName.replace("_visible", ""),
      );
      if (targetQuestion.name === "us_state") {
        targetQuestion.visible =
          propertyValue &&
          question.contentPanel.getQuestionByName("country").value ===
            "United States";
        return;
      }
      if (targetQuestion.name === "state") {
        targetQuestion.visible =
          propertyValue &&
          question.contentPanel.getQuestionByName("country").value !==
            "United States";
        return;
      }
      if (targetQuestion) {
        targetQuestion.setPropertyValue("visible", propertyValue);
      }
      //maintenance note, visibility for email_options is set in the element json
    },
    setRequiredValue(question: QuestionCompositeModel, propertyName: string) {
      const questionName = propertyName.replace("_required", "");
      const targetQuestion =
        question.contentPanel.getQuestionByName(questionName);
      targetQuestion.setPropertyValue(
        "isRequired",
        question.isVisible && question.getPropertyValue(propertyName),
      );
    },
    disableDefaultValuesFromSimilarQuestions() {
      //TODO: disable default values from other questions
    },
    setDefaultValue(question: QuestionCompositeModel, propertyName: string) {
      if (
        !propertyName.startsWith("use_") &&
        !propertyName.startsWith("type_") &&
        !propertyName.endsWith("_default")
      ) {
        return;
      }
      if (
        propertyName === "country_default" ||
        propertyName === "region_default"
      ) {
        const target_question = question.contentPanel.getQuestionByName(
          propertyName.replace("_default", ""),
        ) as QuestionDropdownModel;
        const value = Serializer.getObjPropertyValue(question, propertyName);
        target_question.defaultValue = value;
        return;
      }
      const useOrType = propertyName.startsWith("use_") ? "use" : "type";
      const target_question = question.contentPanel.getQuestionByName(
        useOrType,
      ) as QuestionCheckboxModel;
      const value = Serializer.getObjPropertyValue(question, propertyName);
      target_question.defaultValue = value;
    },
    onPropertyChanged(question: QuestionCompositeModel, propertyName: string) {
      if (
        propertyName.endsWith("_visible") ||
        propertyName.endsWith("_invisible")
      ) {
        this.setVisibilityValue(question, propertyName);
      } else if (propertyName.endsWith("_required")) {
        this.setRequiredValue(question, propertyName);
      } else if (propertyName.endsWith("_default")) {
        this.setDefaultValue(question, propertyName);
      }
    },
    getSimilarQuestions(
      question: QuestionCompositeModel,
    ): Array<QuestionCompositeModel> {
      const surveyModel = question.getSurvey() as unknown as SurveyModel;
      if (surveyModel.isDesignMode) {
        return [question];
      }
      const similarQuestion = surveyModel
        .getAllQuestions()
        .filter((similarQuestion: Question) => {
          if (
            similarQuestion.getType().toLocaleLowerCase() !==
            question_name.toLocaleLowerCase()
          ) {
            return false;
          }
          return similarQuestion.isVisible;
        }) as QuestionCompositeModel[];
      return similarQuestion;
    },
    getChosenUsesAndTypes(question: QuestionCompositeModel): UseTypeTuple {
      const questions = this.getSimilarQuestions(question);
      const chosenTypes: string[] = [];
      const chosenUses: string[] = [];
      questions.forEach((question: QuestionCompositeModel) => {
        const chosen_use = question.contentPanel.getQuestionByName("use").value;
        if (chosen_use) {
          chosen_use.forEach((use: string) => {
            if (!chosenUses.includes(use)) {
              chosenUses.push(use);
            }
          });
        }
        const chosen_type =
          question.contentPanel.getQuestionByName("type").value;
        if (chosen_type && !chosenTypes.includes(chosen_type)) {
          chosenTypes.push(chosen_type);
        }
      });
      const response = { chosenTypes: chosenTypes, chosenUses: chosenUses };
      return response;
    },
    onValueChanged: function (
      question: QuestionCompositeModel,
      name: string,
    ): void {
      if (name === "profileAddress" || name === "contactInformation") {
        return;
      }
      if (
        name === "email" &&
        !question.contentPanel.getValue().contact_information_id
      ) {
        //if this is your first time here, set all the choices "checked"
        question.contentPanel.getQuestionByName("email_options").value = [
          "Billing",
          "Conference",
          "Society News",
          "Education",
        ];
      }
      // THIS NEEDS TO BE ABSTRACTED OUT AND REFACTORED IF THE SOCIETY DOES NOT HAVE IT ENABLED
      // if (name === "postal_code") {
      //   question.survey.runExpression(
      //     `geo_code(${question.contentPanel.getQuestionByName(name).value})`,
      //   );
      // }
      const similarQuestions = this.getSimilarQuestions(question);
      similarQuestions.forEach((singleQuestion: QuestionCompositeModel) => {
        this.onValueChangedUseType(singleQuestion);
        this.onValueChangedContactInformation(singleQuestion);
        this.onValueChangedProfileAddress(singleQuestion);
      });
    },
    onValueChangedUseType(singleQuestion: QuestionCompositeModel) {
      const allUses: ItemValue[] = this.getAllUseOrTypeChoices(
        singleQuestion,
        "use",
      );
      const allTypes: ItemValue[] = this.getAllUseOrTypeChoices(
        singleQuestion,
        "type",
      );
      const { chosenTypes, chosenUses } =
        this.getChosenUsesAndTypes(singleQuestion);
      const type_question = singleQuestion.contentPanel.getQuestionByName(
        "type",
      ) as QuestionDropdownModel;
      type_question.choices = allTypes.filter((choice: ItemValue) => {
        return (
          !chosenTypes.includes(choice.value) ||
          choice.value === type_question.value
        );
      });
      type_question.visible =
        singleQuestion.getPropertyValue("type_visible") &&
        type_question.choices.length >= 1;
      const use_question = singleQuestion.contentPanel.getQuestionByName(
        "use",
      ) as QuestionCheckboxModel;
      use_question.choices = allUses.filter((choice: ItemValue) => {
        return (
          !chosenUses.includes(choice.value) ||
          use_question.value.includes(choice.value)
        );
      });
      use_question.visible =
        singleQuestion.getPropertyValue("use_visible") &&
        use_question.choices.length >= 1;
    },
    onValueChangedContactInformation: (
      singleQuestion: QuestionCompositeModel,
    ): void => {
      const surveyModel = singleQuestion.getSurvey() as unknown as SurveyModel;
      const surveyData = surveyModel.getSurveyData();
      let contactInformationData: ContactInformationUI[] =
        surveyData.getValue("contactInformation");
      contactInformationData = contactInformationData ?? [];
      let singleQuestionContactInformationData:
        | ContactInformationUI
        | { question_name: string };
      if (
        !contactInformationData.find(
          (data) => data.question_name === singleQuestion.name,
        )
      ) {
        singleQuestionContactInformationData = {
          question_name: singleQuestion.name,
        };
        contactInformationData.push(singleQuestionContactInformationData);
      }
      singleQuestionContactInformationData = contactInformationData.find(
        (data) => data.question_name === singleQuestion.name,
      ) ?? {
        question_name: "ERROR",
      };
      const questionValues = singleQuestion.contentPanel.getValue();
      singleQuestionContactInformationData.email = questionValues.email;
      singleQuestionContactInformationData.phone = questionValues.phone;
      singleQuestionContactInformationData.fax = questionValues.fax;
      singleQuestionContactInformationData.phone = questionValues.phone;
      singleQuestionContactInformationData.type = questionValues.type;
      singleQuestionContactInformationData.use = questionValues.use;
      // singleQuestionContactInformationData.emailContactPreference =
      //   questionValues.email_options;
      if (questionValues.contact_information_id) {
        singleQuestionContactInformationData.id =
          questionValues.contact_information_id;
      }
      surveyData.setValue(
        "contactInformation",
        contactInformationData.filter(
          (data) => Object.values(data).filter((v) => v).length > 3,
        ),
        false,
        false,
      );
    },
    onValueChangedProfileAddress: (
      singleQuestion: QuestionCompositeModel,
    ): void => {
      const surveyModel = singleQuestion.getSurvey() as unknown as SurveyModel;
      const surveyData = surveyModel.getSurveyData();
      let profileAddressData: ProfileAddressUI[] =
        surveyData.getValue("profileAddress");
      profileAddressData = profileAddressData ?? [];
      let singleQuestionProfileAddressData: ProfileAddressUI;
      if (
        !profileAddressData.find(
          (data) => data.question_name === singleQuestion.name,
        )
      ) {
        singleQuestionProfileAddressData = {
          question_name: singleQuestion.name,
        };
        profileAddressData.push(singleQuestionProfileAddressData);
      }
      singleQuestionProfileAddressData = profileAddressData.find(
        (data) => data.question_name === singleQuestion.name,
      ) ?? {
        question_name: "ERROR",
      };
      const questionValues = singleQuestion.contentPanel.getValue();
      if (questionValues.country === "United States") {
        const us_state_question =
          singleQuestion.contentPanel.getQuestionByName("us_state");
        us_state_question.visible =
          singleQuestion.getPropertyValue("us_state_visible");
        const state_question =
          singleQuestion.contentPanel.getQuestionByName("state");
        state_question.visible = false;
      } else {
        const us_state_question =
          singleQuestion.contentPanel.getQuestionByName("us_state");
        us_state_question.visible = false;
        const state_question =
          singleQuestion.contentPanel.getQuestionByName("state");
        state_question.visible =
          singleQuestion.getPropertyValue("state_visible");
      }
      singleQuestionProfileAddressData.line1 = questionValues.line1;
      singleQuestionProfileAddressData.line2 = questionValues.line2;
      singleQuestionProfileAddressData.line3 = questionValues.line3;
      singleQuestionProfileAddressData.city = questionValues.city;
      singleQuestionProfileAddressData.state =
        singleQuestionProfileAddressData.country === "United States"
          ? questionValues.us_state
          : questionValues.state;
      singleQuestionProfileAddressData.country = questionValues.country;
      singleQuestionProfileAddressData.region = questionValues.region;
      singleQuestionProfileAddressData.postal_code = questionValues.postal_code;
      singleQuestionProfileAddressData.type = questionValues.type;
      singleQuestionProfileAddressData.use = questionValues.use;
      if (questionValues.profile_address_id) {
        singleQuestionProfileAddressData.id = questionValues.profile_address_id;
      }
      surveyData.setValue(
        "profileAddress",
        profileAddressData.filter(
          (data) => Object.values(data).filter((v) => v).length > 3,
        ),
        false,
        false,
      );
    },
  };

  if (!ComponentCollection.Instance.getCustomQuestionByName(question_name)) {
    ComponentCollection.Instance.add(contact_info_object);
  }
  return contact_info_object;
};
