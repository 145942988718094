import NewAppButton from "@components/common/newform/NewAppButton";
import NewAppExpirationDateInput from "@components/common/newform/NewAppExpirationDateInput";
import NewAppForm from "@components/common/newform/NewAppForm";
import NewAppLoadingSubmitButton from "@components/common/newform/NewAppLoadingSubmitButton";
import NewAppTextInput from "@components/common/newform/NewAppTextInput";
import { useIonRouter } from "@ionic/react";
import { FormikValues } from "formik";
import useSession from "hooks/useSession";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import * as Yup from "yup";

type CardDetails = {
  cardNumber: number;
  expirationDate: string;
  cvc: number;
  zip: number;
};

const PaymentForm = (props: {
  invoiceId: string;
  zip: string;
  disabled?: boolean;
  errorMessage?: string;
  handleCardDetails: (details: CardDetails) => void;
  handleSubmit: () => void;
}) => {
  const session = useSession();
  const router = useIonRouter();
  const isProfileExpired = !(
    Math.floor(
      DateTime.fromISO(session.profile?.updated ?? "").diffNow("days").days,
    ) >=
    Number(
      session.society?.societySettingsPublic?.confirmProfileOlderThanDays,
    ) *
      -1
  );
  const [formValues, setFormValues] = useState<FormikValues>({
    fullName:
      session.profile?.societyUser?.displayName ??
      session.profile?.institution?.name,
    cardNumber: null,
    expirationDate: null,
    cvc: null,
    zip: null,
  });

  useEffect(() => {
    props.handleCardDetails({
      cardNumber: formValues.cardNumber,
      expirationDate: formValues.expirationDate ?? "",
      cvc: formValues.cvc,
      zip: formValues.zip,
    });
  }, [formValues]);

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full name is required"),
    cardNumber: Yup.string().required("Card number is required"),
    expirationDate: Yup.string().required("Expiration date is required"),
    cvc: Yup.string().required("CVC is required"),
    zip: Yup.string().required("Zip code is required"),
  });

  return (
    <section aria-labelledby="payment-heading">
      <div id="payment-heading" className="text-subheader pb-1">
        Payment Details
      </div>

      <NewAppForm
        handleSubmit={props.handleSubmit}
        handleChanged={setFormValues}
        overrideInitialValues={{ ...formValues, zip: props.zip }}
        yupValidation={validationSchema}
      >
        <div className="pb-2">
          <NewAppTextInput
            label="Full Name"
            name="fullName"
            placeholder="Enter Full Name"
          />
        </div>
        <NewAppTextInput
          label="Card Number"
          name="cardNumber"
          placeholder="0000 0000 0000 0000"
          className="pt-2"
        />
        <div className="grid grid-cols-3 gap-1 pt-2">
          <NewAppExpirationDateInput
            label="Exp. Date"
            name="expirationDate"
            placeholder="MM/YY"
          />
          <NewAppTextInput label="CVC" name="cvc" placeholder="CVC" />
          <NewAppTextInput
            additionalClasses="rounded-md "
            name="zip"
            label="Zip"
            placeholder="Zip Code"
          />
        </div>
        {props.errorMessage && (
          <div className="text-status-danger">{props.errorMessage}</div>
        )}
        <div className="mt-4">
          {isProfileExpired ? (
            <NewAppButton
              disabled={props.disabled}
              className="button-regular-general-filled w-full"
              onClick={() => {
                router.push(`/edit-user-detail?invoiceId=${props.invoiceId}`);
              }}
            >
              {
                session.society?.societySettingsPublic
                  ?.outdatedProfileNavigateToEditText
              }
            </NewAppButton>
          ) : (
            <NewAppLoadingSubmitButton
              disabled={props.disabled}
              className="button-regular-general-filled w-full"
            >
              Pay
            </NewAppLoadingSubmitButton>
          )}
        </div>
      </NewAppForm>
    </section>
  );
};
export default PaymentForm;
