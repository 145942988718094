import { FormikValues, useFormikContext } from "formik";

import React from "react";

import NewAppInput from "./NewAppInput";
import { NewAppInputProps } from "types/newAppForm";

export type NewAppNumberInputProps = NewAppInputProps & {
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const NewAppNumberInput = (props: NewAppNumberInputProps) => {
  const { values, errors } = useFormikContext<FormikValues>();

  return (
    <NewAppInput
      name={props.name}
      labelClassName={props.labelClassName}
      label={props.label}
    >
      <input
        required={props.required}
        type="number"
        className={`h-10 mt-2 block w-full rounded-md border-0 py-1.5 px-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6 ${
          errors[props.name] &&
          "border-red-500 focus:outline-none focus:ring-red-500 focus:border-red-500"
        }`}
        value={values[props.name]}
        name={props.name}
        id={props.name}
        onChange={props.onChange}
      />
    </NewAppInput>
  );
};

export default NewAppNumberInput;
