import { SocietyCustomQuestionView } from "@parthenon-management/pillar-types";
import { ApiResponse } from "@parthenon-management/pillar-types";
import { ApiMutationConfig, ApiQueryConfig } from "api/types";

const GetCustomQuestion: ApiQueryConfig<
  ApiResponse<SocietyCustomQuestionView>,
  { societyId: string; questionName: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/surveyJS/question?questionName={questionName}",
  queryKey: {
    baseQueryKey: ["GetCustomQuestion"],
    dynamicQueryKey: ["societyId", "questionName"],
  },
};

const UpdateCustomChoice: ApiMutationConfig<
  ApiResponse<SocietyCustomQuestionView>,
  { societyId: string; questionId: string; choiceId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/surveyJS/{questionId}/choice/{choiceId}",
  method: "PUT",
};

const UpdateCustomChoices: ApiMutationConfig<
  ApiResponse<SocietyCustomQuestionView>,
  { societyId: string; questionId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/surveyJS/{questionId}",
  method: "PUT",
};

export { GetCustomQuestion, UpdateCustomChoice, UpdateCustomChoices };
