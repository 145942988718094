import { GetDirectory, GetProfile, GetProfiles, fetchApiQuery } from "@api";
import { debounce } from "lodash";
import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Question,
  QuestionCompositeModel,
  SurveyModel,
  QuestionHtmlModel,
  Serializer,
  ChoicesLazyLoadEvent,
} from "survey-core";
import { getSocietyId } from "utils/getSocietyId";
import { getDisplayName } from "utils/profile/getDisplayName";
import { SjsCustomQuestion } from "../types/sjs-custom-question";

export const coauthors = () => {
  const question_name = SjsCustomQuestion.coauthors;
  const matrix_question_name = "coauthors_matrix";
  const questionTitle = "Co-Authors";

  const questionObject: ICustomQuestionTypeConfiguration & {
    updateCoauthors: any;
  } = {
    name: question_name,
    title: questionTitle,
    elementsJSON: [
      {
        title: "Add Authors",
        name: matrix_question_name,
        type: "matrixdynamic",
        allowRowsDragAndDrop: true,
        columns: [
          {
            name: "profile_id",
            title: "Author",
            cellType: "dropdown",
            choicesLazyLoadEnabled: true,
            isRequired: true,
            otherText: "New Profile",
            showOtherItem: true,
            otherPlaceholder: "First, Last, Affiliation",
          },
        ],
      },
      {
        name: "block",
        type: "html",
      },
    ],
    onInit: function () {
      Serializer.addProperty(question_name, {
        name: "useDirectorySearch",
        displayName: "Use Directory Search (advanced)?",
        type: "boolean",
        default: false,
        category: "Search Configuration",
      });
      Serializer.addProperty(question_name, {
        name: "directoryId",
        display: "Directory ID",
        type: "number",
        dependsOn: ["useDirectorySearch"],
        visibleIf: function (obj: any) {
          return obj.useDirectorySearch;
        },
        category: "Search Configuration",
      });
    },
    onLoaded: function (question: QuestionCompositeModel): void {
      (question.survey as SurveyModel)?.onGetChoiceDisplayValue.add(
        async (sender, options) => {
          const societyId = getSocietyId();
          const profileResponse = await fetchApiQuery(GetProfile, {
            societyId: getSocietyId().toString(),
            profileId: options.question.name,
          });

          const items = [
            {
              text: profileResponse.data.body
                ? getDisplayName(profileResponse.data.body)
                : "Profile not found",
            },
          ];

          //display value is dumb, it expects an array of strings that match the array index of the selected choice ids
          const resultString = [] as string[];
          for (const value of options.values) {
            const item = items.find((item) => item.text === value);
            if (item) {
              resultString.push(item.text);
            }
          }
          options.setItems(resultString);
        },
      );
    },
    onAfterRender: function (question: QuestionCompositeModel): void {
      const lazyloadingid = Math.random().toString(36); //this doesn't need to be cryptgraphically secure, just unique, this allows us to have multiple degree pickers on the same form
      const directoryId = question.getPropertyValue("directoryId");

      //here we pass the directory id to the matrix question so it is easy to find later.
      (question as any)
        .getQuestionByName(matrix_question_name)
        ?.setPropertyValue("directoryId", directoryId);

      const setChoices = async (options: ChoicesLazyLoadEvent) => {
        let items: {
          value: number;
          text: string;
        }[] = [];
        let totalResults = 0;
        if (options.question.parentQuestion?.getPropertyValue("directoryId")) {
          const profilesResult = await fetchApiQuery(
            GetDirectory,
            {
              societyId: getSocietyId().toString(),
              directoryId:
                options.question.parentQuestion?.getPropertyValue(
                  "directoryId",
                ),
            },
            {
              text: options.filter,
              page: options.skip / options.take + 1,
              pageSize: options.take,
            } as any, ///not sure why this is needed, getting typescript errors without it.
            {},
            {
              staleTime: 100,
            },
          );

          const choices =
            profilesResult.data.body?.profilesPaginated.results ?? [];

          items = choices.map((choice) => ({
            value: choice.profile.id as number,
            text: (choice.profile.SocietyUser as any)?.first_last_computed,
          }));
          totalResults =
            profilesResult.data.body?.profilesPaginated?.totalResults ?? 0;
        } else {
          const profilesResult = await fetchApiQuery(
            GetProfiles,
            {
              societyId: getSocietyId().toString(),
            },
            {
              text: options.filter,
              type: ["Society_User"],
              page: options.skip / options.take + 1,
              pageSize: options.take,
            } as any, ///not sure why this is needed, getting typescript errors without it. },
          );
          const choices = profilesResult.data.body?.results ?? [];

          items = choices.map((choice) => ({
            value: choice.id,
            text: getDisplayName(choice),
          }));
          totalResults = profilesResult.data.body?.totalResults ?? 0;
        }

        options.setItems(items, totalResults);
      };
      const _debouncedSetChoices = debounce(setChoices, 1000);

      (question as any)
        .getQuestionByName(matrix_question_name)
        ?.setPropertyValue("lazyLoadingUniqueId", lazyloadingid);
      (question.survey as SurveyModel)?.onChoicesLazyLoad.add(
        async (sender, options) => {
          if (
            options.question.parentQuestion?.getPropertyValue(
              "lazyLoadingUniqueId",
            ) != lazyloadingid &&
            options.question.parentQuestion?.name !== question_name
          )
            return;

          options.setItems([], 0);
          _debouncedSetChoices(options);
        },
      );
      this.updateCoauthors(question.value.coauthors_matrix ?? [], question);
    },
    onValueChanged: async function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      newValue: any,
    ): Promise<void> {
      this.updateCoauthors(newValue, question as QuestionCompositeModel);
    },
    updateCoauthors: async function (rows: any[], question: Question) {
      // get html block for output
      const htmlBlock = question.contentPanel.getQuestionByName(
        "block",
      ) as QuestionHtmlModel;
      htmlBlock;
      const nameArray = [];
      const affiliationMap: { [key: string]: any } = {};

      // affiliation map is used to store an institution to a number that represents which superscript that institution is assigned
      // ex: {"parthenon management group": 1}

      let higestSuperScript = 1;

      /* 
      Loop
        1. Pull name and affiliation out of coAuthor row
        2. Pull superscript assigned to affiliation out of map
        3. Create names for names section with correct superscripts
      After
        4. Create affiliations with assigned superscript from the map.
        5. Insert names and affiliations to the html.
      */
      for (const coAuthorRow of rows) {
        const author = coAuthorRow.profile_id;
        let result: null | { name: string; affiliation: string } = null;
        // if the user has selected an author
        if (author) {
          const orderNumber = rows.indexOf(coAuthorRow);
          if (author === "other") {
            result = {
              name: `${
                coAuthorRow["profile_id-Comment"]?.split(",")[0] ?? "First"
              }  ${coAuthorRow["profile_id-Comment"]?.split(",")[1] ?? "Last"}`,
              affiliation: `${
                coAuthorRow["profile_id-Comment"]?.split(",")[2] ??
                "No affiliation entered"
              }`,
            };
          } else {
            const profileResponse = await fetchApiQuery(GetProfile, {
              societyId: getSocietyId().toString(),
              profileId: author,
            });
            const profile = profileResponse.data.body;
            result = {
              name: profile ? getDisplayName(profile) : "Profile not found",
              affiliation: profile?.societyUser?.affiliation
                ? profile.societyUser?.affiliation
                : "No affiliation",
            };
          }

          // gets superscript assigned to that affiliation
          if (!(result.affiliation in affiliationMap)) {
            affiliationMap[result.affiliation] = higestSuperScript;
            higestSuperScript += 1;
          }
          // create names section
          nameArray[Number(orderNumber)] = `${result.name}<sup>${
            affiliationMap[result.affiliation]
          }</sup>`;
        }
      }
      const affiliationArray = Object.keys(affiliationMap).map(
        (key) => `${key}<sup>${affiliationMap[key]}</sup>`,
      );
      const html = `<div>${nameArray.join(", ")}</div><div>
      ${affiliationArray.join(", ")}</div>`;
      htmlBlock.html = html;
      (question.survey as SurveyModel).render();
    },
  };
  if (!ComponentCollection.Instance.getCustomQuestionByName(question_name)) {
    ComponentCollection.Instance.add(questionObject);
  }
};
function getDirectoryCallout(
  arg0: any,
  arg1: { text: string },
  arg2: { pageSize: number; page: number },
) {
  throw new Error("Function not implemented.");
}
