import {
  ComponentCollection,
  ICustomQuestionTypeConfiguration,
  Question,
} from "survey-core";
import { SjsCustomQuestion } from "../types/sjs-custom-question";

export const speakerColumnName = "Speaker";

export const cospeakers = () => {
  const questionName = SjsCustomQuestion.cospeakers;
  const questionTitle = "Co-Speakers";

  const cospeakersObject: ICustomQuestionTypeConfiguration = {
    name: questionName,
    title: questionTitle,
    questionJSON: {
      type: "paneldynamic",
      name: "CospeakersContent",
      title: "Cospeakers",
      templateElements: [
        {
          type: "text",
          name: "ProfileIdSearch",
          title: "Profile ID",
        },
        {
          type: "dropdown",
          name: "Role",
          startWithNewLine: false,
          title: "Role",
          choices: ["Chair", "Co-Chair", "Speaker"],
        },
        {
          type: "panel",
          name: "SubmissionPanel",
          elements: [
            {
              type: "text",
              name: "AbstractTitle",
              title: "Title",
              isEventDetail: true,
              eventField: "event_name",
            },
            {
              type: "comment",
              name: "Full Abstract",
              title: "Full Abstract",
              isEventDetail: true,
              eventField: "description",
            },
            {
              type: "dropdown",
              name: "primaryCategory",
              title: "Primary Category",
              isEventDetail: true,
              eventField: "primary_category",
              pillar_choices_question_name: "primary_category",
              choicesLazyLoadEnabled: true,
            },
            {
              type: "dropdown",
              name: "secondaryCategory",
              startWithNewLine: false,
              title: "Secondary Category",
              isEventDetail: true,
              eventField: "secondary_category",
              pillar_choices_question_name: "secondary_category",
              choicesLazyLoadEnabled: true,
            },
          ],
          title: "Submission Abstract",
          state: "collapsed",
        },
      ],
    },

    onInit: async () => {
      //notempty
    },
    onCreated: function (question: Question): void {
      question.title = questionTitle;

      //   throw new Error("Function not implemented.");
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onLoaded: function (question: Question): void {
      //   throw new Error("Function not implemented.");
      // question.survey.onChoicesLazyLoad.add(onChoicesLazyLoad);
      //   getting this error here when attempting to scope the choices lazy load to the questionTypeError: Cannot read properties of undefined (reading 'onChoicesLazyLoad')
      // question.survey.onGetChoiceDisplayValue.add(onGetChoiceDisplayValue);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onAfterRender: function (question: Question, htmlElement: unknown): void {
      //   throw new Error("Function not implemented.");
    },
    onAfterRenderContentElement: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      question: Question & any,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      element: Question,
    ): void {
      //   throw new Error("Function not implemented.");
    },
    onPropertyChanged: async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      question: Question & any,
      propertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newValue: any,
    ) => {
      // if (propertyName === rolePropName) {
      //   // first, set the ID's from the db into the item table and the ID's
      //   if (!pageLoaded) {
      //     for (let i = 0; i < newValue.length; i++) {
      //       const choice = newValue[i];
      //       Serializer.setObjPropertyValue(choice, "choiceId", choices[i].id);
      //     }
      //     highestId = Number(choices[choices.length - 1].id);
      //     pageLoaded = true;
      //   }
      //   // get the current id of what's being added/deleted
      //   const workingId = Serializer.getObjPropertyValue(
      //     newValue[newValue.length - 1],
      //     "choiceId",
      //   );
      //   // if there's no ID, it's a new row that needs a new ID
      //   // this gives the last row (most recently added row) the highest
      //   if (!workingId) {
      //     Serializer.setObjPropertyValue(
      //       newValue[newValue.length - 1],
      //       "choiceId",
      //       highestId,
      //     );
      //   }
      //   // prep choices for update
      //   const mappedChoices = newValue.map(
      //     (choice: { [key: string]: string | number }) => {
      //       return {
      //         id: choice.choiceId,
      //         text: choice.text,
      //         value: choice.value,
      //       };
      //     },
      //   );
      //   // increment highest ID in case they add even more rows
      //   highestId += 1;
      //   question.customQuestion.json.questionJSON.columns[1].choices = newValue;
      //   await updateCustomChoices(questionId, mappedChoices);
      // }
    },
    onValueChanged: function (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
      newValue: any,
    ): void {
      //   throw new Error("Function not implemented.");
    },
    onItemValuePropertyChanged: function (
      question: Question,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: any,
    ): void {
      //todo update in future cospeakers update
    },
  };
  if (!ComponentCollection.Instance.getCustomQuestionByName(questionName)) {
    ComponentCollection.Instance.add(cospeakersObject);
  }
};
