import { Serializer } from "survey-core";

export const formatPropertyName = (str: string) => {
  str = str.replace(/([A-Z0-9])/g, " $1");
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const choicesOptions = ["Home", "Work", "Other"];

export const setDefaultType = (question_name: string) => {
  Serializer.addProperty(question_name, {
    name: "setDefaultType",
    displayName: "Set Default Type",
    choices: () => {
      return choicesOptions;
    },
    category: "Defaults",
  });
};
