export const ConvertToDate = (dateValue: string): Date => {
  return new Date(dateValue);
};

export const GetCurrentDate = (): Date => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return ConvertToDate(`${year}-${month}-${day}`);
};

export const GetCurrentYear = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  return `${year}`;
};

export const GetCurrentMonth = (): string => {
  const date = new Date();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  return `${month}`;
};

export const GetCurrentDay = (): string => {
  const date = new Date();
  const day = ("0" + date.getDate()).slice(-2);
  return `${day}`;
};

export const GetStartOfCurrentYear = (): Date => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + 1).slice(-2);
  const day = ("0" + 1).slice(-2);
  return ConvertToDate(`${year}-${month}-${day}`);
};

export const GetEndOfCurrentYear = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("0" + 12).slice(-2);
  const day = ("0" + 31).slice(-2);
  return ConvertToDate(`${year}-${month}-${day}`);
};

export const GetPreviousYear = (): string => {
  const date = new Date();
  const year = date.getFullYear() - 1;
  return `${year}`;
};

export const GetStartOfPreviousYear = (): Date => {
  const date = new Date();
  const year = date.getFullYear() - 1;
  const month = ("0" + 1).slice(-2);
  const day = ("0" + 1).slice(-2);
  return ConvertToDate(`${year}-${month}-${day}`);
};

export const GetEndOfPreviousYear = () => {
  const date = new Date();
  const year = date.getFullYear() - 1;
  const month = ("0" + 12).slice(-2);
  const day = ("0" + 31).slice(-2);
  return ConvertToDate(`${year}-${month}-${day}`);
};
