import {
  ApiResponse,
  SubmissionProcessView,
} from "@parthenon-management/pillar-types";
import { ApiQueryConfig } from "api/types";

const GetSubmissionProcess: ApiQueryConfig<
  ApiResponse<SubmissionProcessView>,
  { societyId: string; submissionProcessId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/submission/process/{submissionProcessId}",
  queryKey: {
    baseQueryKey: ["GetSubmissionProcess"],
    dynamicQueryKey: ["submissionProcessId"],
  },
};

export { GetSubmissionProcess };
