import {
  ApiResponse,
  AttendeeView,
  ConferenceScheduleView,
  ConferenceView,
  EventView,
  PaginatedResults,
} from "@parthenon-management/pillar-types";
import { ApiMutationConfig, ApiQueryConfig } from "api/types";
import { PillarPDF } from "types/PDF";
import { FiltersRequest } from "@parthenon-management/pillar-types/dist/src/utils/helpers/filter-where-clause";

const GetConferenceEvents: ApiQueryConfig<
  ApiResponse<PaginatedResults<EventView>>,
  { societyId: string; conferenceId: string },
  FiltersRequest
> = {
  apiPath: "/api/v1/society/{societyId}/conference/{conferenceId}/event",
  queryKey: {
    baseQueryKey: ["GetConferenceEvents"],
    dynamicQueryKey: ["societyId", "conferenceId"],
  },
};

const GetConferenceSchedule: ApiQueryConfig<
  ApiResponse<ConferenceScheduleView>,
  { societyId: string; conferenceId: string },
  FiltersRequest
> = {
  apiPath: "/api/v1/society/{societyId}/conference/{conferenceId}/schedule",
  queryKey: {
    baseQueryKey: ["GetConferenceSchedule"],
    dynamicQueryKey: ["societyId", "conferenceId"],
  },
};

const GetConferenceSchedulePdf: ApiQueryConfig<
  ApiResponse<PillarPDF>,
  { societyId: string; conferenceId: string },
  FiltersRequest
> = {
  apiPath:
    "/api/v1/society/{societyId}/conference/{conferenceId}/schedule/download",
  queryKey: {
    baseQueryKey: ["GetConferenceSchedule"],
    dynamicQueryKey: ["societyId", "conferenceId"],
  },
};

const CreateConferenceEvent: ApiMutationConfig<
  ApiResponse<EventView>,
  { societyId: string; conferenceId: string },
  // Type of NewEvent from "types/conference";
  // Had to inline type this due to an issue with typescript and ApiMutationConfig
  {
    id?: number;
    day_number: number;
    description: string;
    type: string | null;
    conference_id: number;
    is_parent_event: boolean;
    start_time: Date | null;
    end_time: Date | null;
    parent_event_id: number | null;
    additional_info: JSON | null;
    event_name: string;
    created_by_user_id?: number;
    last_modified_by_user_id?: number;
    start_buffer_minutes: number;
    end_buffer_minutes: number;
    display_buffer_on_schedule: boolean;
    is_private: boolean;
    is_all_day: boolean;
    expected_attendance: string;
  }
> = {
  apiPath: "/api/v1/society/{societyId}/conference/{conferenceId}/event",
  method: "POST",
  invalidates: [
    {
      baseQueryKey: GetConferenceSchedule.queryKey.baseQueryKey,
      dynamicQueryKey: ["societyId", "conferenceId"],
    },
  ],
};

const GetConferenceEventById: ApiQueryConfig<
  ApiResponse<EventView>,
  { societyId: string; conferenceId: string; eventId: string },
  { filter?: FiltersRequest }
> = {
  apiPath:
    "api/v1/society/{societyId}/conference/{conferenceId}/event/{eventId}",
  queryKey: {
    baseQueryKey: ["GetConferenceEventById"],
    dynamicQueryKey: ["societyId", "conferenceId", "eventId"],
  },
};

const AddOrRemoveFavoriteEvent: ApiMutationConfig<
  ApiResponse<void>,
  {
    societyId: string;
    conferenceId: string;
    eventId: string;
    attendeeId: string;
  },
  {
    is_favorite: boolean;
  }
> = {
  apiPath:
    "/api/v1/society/{societyId}/conference/{conferenceId}/event/{eventId}/attendee/{attendeeId}",
  method: "POST",
  invalidates: [
    {
      baseQueryKey: GetConferenceEventById.queryKey.baseQueryKey,
      exact: false,
    },
    {
      baseQueryKey: GetConferenceSchedule.queryKey.baseQueryKey,
      dynamicQueryKey: GetConferenceSchedule.queryKey.dynamicQueryKey,
      exact: false,
    },
  ],
};

const GetConferences: ApiQueryConfig<
  ApiResponse<ConferenceView[]>,
  { societyId: string },
  Record<string, string>
> = {
  apiPath: "/api/v1/society/{societyId}/conference",
  queryKey: {
    baseQueryKey: ["GetConferences"],
    dynamicQueryKey: ["societyId"],
  },
};

const GetConferenceById: ApiQueryConfig<
  ApiResponse<ConferenceView>,
  { societyId: string; conferenceId: string },
  Record<string, string>
> = {
  apiPath: "/api/v1/society/{societyId}/conference/{conferenceId}",
  queryKey: {
    baseQueryKey: ["GetConferenceById"],
    dynamicQueryKey: ["societyId", "conferenceId"],
  },
};

const GetConferenceAttendee: ApiQueryConfig<
  ApiResponse<AttendeeView>,
  { societyId: string; conferenceId: string; profileId: string },
  Record<string, string>
> = {
  apiPath:
    "/api/v1/society/{societyId}/conference/{conferenceId}/get-attendee?profileId={profileId}",
  queryKey: {
    baseQueryKey: ["GetConferenceAttendee"],
    dynamicQueryKey: ["societyId", "conferenceId", "profileId"],
  },
};

export {
  GetConferenceEvents,
  AddOrRemoveFavoriteEvent,
  CreateConferenceEvent,
  GetConferenceAttendee,
  GetConferenceById,
  GetConferenceEventById,
  GetConferenceSchedule,
  GetConferenceSchedulePdf,
  GetConferences,
};
