import { FormikValues, useFormikContext } from "formik";
import { ComponentPropsWithoutRef } from "react";
import { Spinner, SpinnerSize } from "../Spinner";
type NewAppLoadingSubmitButtonProps = ComponentPropsWithoutRef<"button">;

const NewAppLoadingSubmitButton = ({
  ...props
}: NewAppLoadingSubmitButtonProps) => {
  const { handleSubmit, isSubmitting } = useFormikContext<FormikValues>();

  return (
    <button
      type="button"
      className="button-regular-general-filled w-10"
      disabled={isSubmitting}
      onClick={() => handleSubmit()}
      {...props}
    >
      {isSubmitting ? (
        <Spinner spinnerSize={SpinnerSize.Small} />
      ) : (
        props.children
      )}
    </button>
  );
};

export default NewAppLoadingSubmitButton;
