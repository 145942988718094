import { ErrorMessage, FormikValues, useFormikContext } from "formik";
import React from "react";

//This is one of the few cases we may NOT want to do ComponentPropsWithoutRef.
//The reason is we use this as a wrapper around other inputs where those inputs
//should really be the ones taking the ComponentPropsWithoutRef<"input">.
export type NewAppInputProps = {
  name: string;
  label?: string;
  labelClassName?: string;
  additionalClasses?: string;
  children?: React.ReactNode;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder?: any; //TODO: STYLING Remove this. It is not used here and should not be defined here.
  required?: boolean; //TODO: STYLING Remove this. It is not used here and should not be defined here.
};

const NewAppInput = ({
  name,
  label,
  labelClassName,
  additionalClasses,
  children,
}: NewAppInputProps) => {
  const { errors } = useFormikContext<FormikValues>();
  return (
    <div
      className={`flex flex-col ${additionalClasses ? additionalClasses : ""}`}
    >
      {label && (
        <label
          className={`mb-0.25 ${labelClassName ? labelClassName : ""}`}
          htmlFor={name}
        >
          {label}:
        </label>
      )}
      {children}
      {errors[name] && (
        <ErrorMessage
          name={name}
          component="span"
          className="text-danger-small"
        />
      )}
    </div>
  );
};

export default NewAppInput;
