import {
  ApiResponse,
  PaginatedResults,
  SubmissionResponseInput,
  SubmissionResponseView,
  SubmissionWorkflowInstanceView,
  UserSubmissionDashboardView,
} from "@parthenon-management/pillar-types";
import { ApiMutationConfig, ApiQueryConfig } from "api/types";
import {
  GetSubmissionWorkflow,
  GetSubmissionWorkflows,
} from "./submission-workflow";
import { GetInvoice } from "./invoice";
import { GetInvoices } from "./invoices";
import { GetOpenSubmissions } from "./open-submissions";

const GetSubmissionResponse: ApiQueryConfig<
  ApiResponse<SubmissionResponseView>,
  { societyId: string; submissionResponseId: string },
  Record<any, any>
> = {
  queryKey: {
    baseQueryKey: ["GetSubmissionResponse"],
    dynamicQueryKey: ["submissionResponseId"],
  },
  apiPath:
    "/api/v1/society/{societyId}/workflow/complex/submissions/{submissionResponseId}",
};

const GetMostRecentSubmissionResponse: ApiQueryConfig<
  ApiResponse<SubmissionResponseView>,
  {
    societyId: string;
    profileId: string;
    submissionProcessId: string;
  },
  Record<string, unknown>
> = {
  queryKey: { baseQueryKey: ["GetMostRecentSubmissionWorkflow"] },
  apiPath:
    "/api/v1/public/society/{societyId}/userHub/submission/response/{profileId}/{submissionProcessId}",
};

const CreateSubmissionResponse: ApiMutationConfig<
  ApiResponse<SubmissionWorkflowInstanceView>,
  { societyId: string },
  {
    newSubmissionResponse?: SubmissionResponseInput;
    newWorkflowInstance?: { workflowComplexId: number; payload?: JSON };
  }
> = {
  apiPath: "/api/v1/society/{societyId}/workflow/complex/submissions",
  method: "POST",
  invalidates: [
    {
      baseQueryKey: GetOpenSubmissions.queryKey.baseQueryKey,
    },
    {
      baseQueryKey: GetSubmissionResponse.queryKey.baseQueryKey,
    },
    {
      baseQueryKey: GetInvoice.queryKey.baseQueryKey,
    },
    {
      baseQueryKey: GetInvoices.queryKey.baseQueryKey,
    },
    {
      baseQueryKey: GetSubmissionWorkflows.queryKey.baseQueryKey,
    },
  ],
};

const UpsertSubmissionResponse: ApiMutationConfig<
  ApiResponse<SubmissionResponseView>,
  { societyId: string },
  {
    id?: number;
    profile_id: number;
    submission_process_id: number;
    form_schema: JSON;
    submission_response: JSON;
  }
> = {
  method: "POST",
  apiPath: "/api/v1/society/{societyId}/submission/response",
  invalidates: [
    {
      baseQueryKey: GetSubmissionWorkflow.queryKey.baseQueryKey,
      dynamicQueryKey: ["id"],
    },
    {
      baseQueryKey: GetSubmissionWorkflows.queryKey.baseQueryKey,
    },
  ],
};

const GetUserSubmissionDashboard: ApiQueryConfig<
  ApiResponse<PaginatedResults<UserSubmissionDashboardView>>,
  { societyId: string; profileId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/submission/response/user-submission-dashboard/{profileId}",
  queryKey: {
    baseQueryKey: ["GetUserSubmissionDashboard"],
    dynamicQueryKey: ["societyId", "profileId"],
  },
};

export {
  GetSubmissionResponse,
  GetMostRecentSubmissionResponse,
  CreateSubmissionResponse,
  UpsertSubmissionResponse,
  GetUserSubmissionDashboard,
};
