import { mutateApiQuery } from "@api";
import { Capacitor } from "@capacitor/core";
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { SaveFCMToken } from "api/routes/fcm-token";
import { getSocietyId } from "utils/getSocietyId";

const registerTokenListener = () => {
  PushNotifications.addListener("registration", async (token: Token) => {
    // Save FCM token in Db
    await mutateApiQuery(
      SaveFCMToken,
      {
        societyId: getSocietyId()?.toString(),
      },
      { fcmToken: token.value, platform: Capacitor.getPlatform() },
    );
  });

  PushNotifications.addListener("registrationError", (error) => {
    console.error("Error registering token: ", JSON.stringify(error));
  });
};

const registerPushNotificationListeners = () => {
  PushNotifications.addListener(
    "pushNotificationReceived",
    (notification: PushNotificationSchema) => {
      console.log("Push notification received: ", notification);
      // Handle push notification on received
    },
  );

  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification: ActionPerformed) => {
      console.log("Push notification action performed: ", notification);
      // Handle push notification on tap
    },
  );
};

const requestPushNotificationPermission = async () => {
  const permissionResponse = await PushNotifications.requestPermissions();

  const test = await PushNotifications.register();

  return permissionResponse.receive === "granted";
};

export const initializePushNotifications = async () => {
  const device = Capacitor.getPlatform();

  if (device === "web") {
    return;
  }

  const permissionGranted = await requestPushNotificationPermission();
  if (permissionGranted) {
    registerTokenListener();
    registerPushNotificationListeners();
    await PushNotifications.register();
  }
};

// Unregister push notifications
export const unregisterPushNotifications = async () => {
  await PushNotifications.unregister();
};
