import { ApiResponse } from "@parthenon-management/pillar-types";
import { Product } from "@types";
import { ApiQueryConfig } from "api/types";

const GetCheckoutDonationProducts: ApiQueryConfig<
  ApiResponse<Product[]>,
  { societyId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/accounting/products/checkout-donation",
  queryKey: {
    baseQueryKey: ["GetCheckoutDonationProducts"],
    dynamicQueryKey: ["societyId"],
  },
};

export { GetCheckoutDonationProducts };
