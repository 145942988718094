import {
  ApiResponse,
  SubmissionWorkflowView,
} from "@parthenon-management/pillar-types";
import { ApiQueryConfig } from "api/types";

const GetOpenSubmissions: ApiQueryConfig<
  ApiResponse<SubmissionWorkflowView[]>,
  { societyId: string; type: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/workflow/complex/submissions?type={type}",
  queryKey: {
    baseQueryKey: ["GetOpenSubmissions"],
    dynamicQueryKey: ["societyId", "type"],
  },
};

export { GetOpenSubmissions };
