import useSession from "hooks/useSession";
import { Redirect, Route, RouteProps } from "react-router";
import { TagView } from "@parthenon-management/pillar-types";

type Props = RouteProps & {
  children?: React.ReactNode;
  permissionTags?: TagView[];
  checkPermission?: boolean;
};

const ProtectedRoute: React.FC<Props> = ({
  children,
  render,
  permissionTags,
  checkPermission,
  ...props
}) => {
  const { isAuthenticated, profile } = useSession();

  if (checkPermission && isAuthenticated) {
    const hasPermission = permissionTags?.some(
      (permissionTag) =>
        profile?.tags?.some((tag: TagView) => tag.id === permissionTag.id),
    );

    if (!hasPermission) {
      return <Redirect to="/access-denied" />;
    }
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...props}
      render={(props) => {
        if (isAuthenticated) {
          return render ? render(props) : children;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
