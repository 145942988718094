import { ComponentPropsWithoutRef, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteInvoiceItemById, getInvoicePDF } from "api/invoiceApi";
import { useParams } from "react-router-dom";
import usePDFDownload from "hooks/usePDFDownload";
import { IonSpinner } from "@ionic/react";
import XMarkIcon from "components/icons/XMarkIcon";
import NewAppButton from "@components/common/newform/NewAppButton";
import {
  InvoiceItemView,
  PromoCodeView,
} from "@parthenon-management/pillar-types";
import { NewAppModalHandle } from "@components/common/newmodal/NewAppModalBody";
import ApplyPromoCodeModal from "./ApplyPromoCodeModal";

import MenuComponent from "@components/common/menu/MenuComponent";
import { useApiQuery, GetInvoice } from "@api";
import useSession from "hooks/useSession";

type OrderSummaryProps = ComponentPropsWithoutRef<"div">;

const OrderSummary = ({ className, ...props }: OrderSummaryProps) => {
  const queryClient = useQueryClient();

  const { invoiceId } = useParams<{ invoiceId: string }>();
  const session = useSession();

  const invoicePdf = usePDFDownload(getInvoicePDF);
  const applyPromoCodeRef = useRef<NewAppModalHandle>(null);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState<
    InvoiceItemView | undefined
  >();

  const { data: invoiceQuery, isLoading: invoiceQueryIsLoading } = useApiQuery(
    GetInvoice,
    {
      societyId: session.societyId!.toString(),
      profileId: session.profileId!.toString(),
      invoiceId: invoiceId,
    },
    {},
  );

  const deleteInvoiceItem = useMutation({
    mutationFn: (invoiceItemId: number) => deleteInvoiceItemById(invoiceItemId),
    onSuccess: () => {
      queryClient.invalidateQueries(["invoice", invoiceId]);
    },
  });

  const openPromoCodeModal = (invoiceItem: InvoiceItemView) => {
    setSelectedInvoiceItem(invoiceItem);
    applyPromoCodeRef.current?.open();
  };

  return (
    <>
      <ApplyPromoCodeModal
        modalRef={applyPromoCodeRef}
        invoiceItem={selectedInvoiceItem}
      />
      <div
        className={`container-light border-r border-neutral-mid-50 pt-4.5 ${
          className ?? ""
        }`}
        {...props}
      >
        <h1 className="sr-only">Order information</h1>
        <section aria-labelledby="summary-heading">
          {/* ANCHOR: Header */}
          <div className="flex justify-between">
            <div className="text-title">Order Summary</div>
            {invoicePdf.isLoading ? (
              <IonSpinner className="h-5 w-5" />
            ) : (
              <NewAppButton
                className="button-regular-general-filled w-fit whitespace-nowrap"
                onClick={() => invoicePdf.downloadPDF(Number(invoiceId))}
              >
                Print Invoice
              </NewAppButton>
            )}
          </div>
          <div className="flex flex-col space-y-2 pt-3">
            <div className="flex flex-col !max-h-30 overflow-y-auto">
              {invoiceQuery &&
                invoiceQuery?.data?.body?.invoiceItems.map(
                  (invoiceItem: InvoiceItemView, index) => (
                    <div
                      key={`invoice-item-${invoiceItem.id}`}
                      className={
                        "py-1 pl-1 flex justify-between rounded-lg " +
                        (index % 2 == 0
                          ? "bg-neutral-light"
                          : "bg-neutral-light-550")
                      }
                    >
                      <div className="flex flex-col space-y-0.5">
                        <p className="text-detailblock-title !capitalize">
                          {invoiceItem?.product?.name?.toLowerCase()}
                        </p>
                        <div className="flex space-x-1">
                          <div>
                            <span className="text-detailblock-base">
                              Quantity:&nbsp;
                            </span>
                            <span className="text-detailblock-subtle">
                              {invoiceItem?.quantity}
                            </span>
                          </div>
                          <div>
                            <span className="text-detailblock-base">
                              Total Price:&nbsp;
                            </span>
                            <span className="text-detailblock-subtle">
                              {invoiceItem?.total && invoiceItem.total > 0
                                ? `$${invoiceItem?.total}`
                                : "$0"}
                            </span>
                          </div>
                        </div>
                      </div>
                      {invoiceItem?.product?.type?.description ===
                      "Donation" ? (
                        <NewAppButton
                          className="button-regular-destructive-unfilled"
                          onClick={() => {
                            invoiceItem?.id &&
                              deleteInvoiceItem.mutateAsync(invoiceItem.id);
                          }}
                        >
                          <XMarkIcon className="h-2.5 w-2.5" />
                        </NewAppButton>
                      ) : null}
                      <div className="flex pt-1">
                        <div className="pt-1">
                          {invoiceItem.promoCode.length > 0 ? (
                            <div>Promo code applied</div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <MenuComponent
                          menuItems={[
                            {
                              label: "Apply Promo Code",
                              onSelect: () => openPromoCodeModal(invoiceItem),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  ),
                )}
            </div>
            <div className="container-divider-border pb-2 flex flex-col">
              <div>
                <span className="text-detailblock-title text-neutral-dark">
                  Total:&nbsp;
                </span>
                <span className="text-detailblock-title text-neutral-dark">
                  {Number(invoiceQuery?.data?.body?.total) > 0
                    ? `$${invoiceQuery?.data?.body?.total}`
                    : "$0"}
                </span>
              </div>
              <div className="text-subtle">
                Promo Codes Applied:
                <div className="flex flex-col">
                  {invoiceQuery?.data?.body?.invoiceItems.flatMap(
                    (invoiceItem) => {
                      return invoiceItem.promoCode.flatMap(
                        (promoCode: PromoCodeView) => {
                          return (
                            <div
                              className="
                              bg-neutral-light-600
                          rounded-md my-1 p-1
                              w-full"
                            >
                              {promoCode.name}
                            </div>
                          );
                        },
                      );
                    },
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrderSummary;
