import {
  ApiResponse,
  ProfileLiteView,
} from "@parthenon-management/pillar-types";
import { ApiMutationConfig, ApiQueryConfig } from "api/types";
import {
  Chat,
  ChatMessage,
  NewMessage,
  Participant,
  StartChatInput,
} from "types/chat";

const GetChatParticipants: ApiQueryConfig<
  ApiResponse<Participant[]>,
  { societyId: string; chatId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/chat/{chatId}/get-participants",
  queryKey: {
    baseQueryKey: ["GetChatParticipants"],
    dynamicQueryKey: ["societyId", "chatId"],
  },
};

const GetMessages: ApiQueryConfig<
  ApiResponse<Chat>,
  { societyId: string; profileId: string; chatId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/chat/{chatId}/get-messages?isAdmin=false&profileId={profileId}",
  queryKey: {
    baseQueryKey: ["GetMessages"],
    dynamicQueryKey: ["societyId", "profileId", "chatId"],
  },
};

const SendChatMsg: ApiMutationConfig<
  ApiResponse<ChatMessage>,
  {
    societyId: string;
  },
  NewMessage
> = {
  apiPath: "/api/v1/society/{societyId}/chat/message",
  method: "POST",
  invalidates: [
    {
      baseQueryKey: GetMessages.queryKey.baseQueryKey,
    },
  ],
};

const StartChat: ApiMutationConfig<
  ApiResponse<Chat>,
  {
    societyId: string;
  },
  StartChatInput
> = {
  apiPath: "api/v1/society/{societyId}/chat/start-chat",
  method: "POST",
  invalidates: [],
};

const GetAvailableChatProfiles: ApiQueryConfig<
  ApiResponse<ProfileLiteView[]>,
  { societyId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/chat/participant?{conferenceId}",
  queryKey: {
    baseQueryKey: ["GetAvailableChatProfiles"],
    dynamicQueryKey: ["societyId"],
  },
};

export {
  GetChatParticipants,
  GetMessages,
  SendChatMsg,
  StartChat,
  GetAvailableChatProfiles,
};
