import PageComponent from "components/common/PageComponent";
import { SurveyPreview } from "components/surveyJS/SurveyPreview";
import useSession from "hooks/useSession";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { SessionView } from "@parthenon-management/pillar-types";
import { SurveyModel } from "survey-core";
import useDeviceInfo from "hooks/useDeviceInfo";
const NewProfilePage = () => {
  const history = useHistory();
  const session = useSession();
  const device = useDeviceInfo();

  useEffect(() => {
    if (session.profile) {
      history.replace("/overview");
    }
  }, [session.profile]);

  return (
    <PageComponent>
      {device.isNativeApp && (
        <PageComponent.Header showBackButton={true}></PageComponent.Header>
      )}
      <PageComponent.Content>
        <SurveyPreview
          formDesign={session.society?.profileForm!.formDesign!}
          session={session as SessionView}
          onComplete={(
            sender: SurveyModel,
            options: {
              showSaveSuccess: (text?: string) => void;
            },
          ) => {
            session.refreshSession();
            history.push("/login");
          }}
        />
      </PageComponent.Content>
    </PageComponent>
  );
};

export default NewProfilePage;
