import { ApiMutationConfig, ApiQueryConfig } from "@api";
import { ApiResponse } from "@parthenon-management/pillar-types";
import {
  discussionForumDto,
  forumPostInput,
  sentimentInput,
} from "types/forum";

const GetConferenceEventDiscussionForum: ApiQueryConfig<
  ApiResponse<discussionForumDto>,
  { societyId: string; conferenceId: string; eventId: string },
  Record<string, unknown>
> = {
  apiPath:
    "/api/v1/society/{societyId}/conference/{conferenceId}/event/{eventId}/forum",

  queryKey: {
    baseQueryKey: ["GetConferenceEventDiscussionForum"],
    dynamicQueryKey: ["societyId", "conferenceId", "eventId"],
  },
};

const GetConferenceDiscussionForum: ApiQueryConfig<
  ApiResponse<discussionForumDto>,
  { societyId: string; conferenceId: string },
  Record<string, unknown>
> = {
  apiPath: "/api/v1/society/{societyId}/conference/{conferenceId}/forum",

  queryKey: {
    baseQueryKey: ["GetConferenceDiscussionForum"],
    dynamicQueryKey: ["societyId", "conferenceId"],
  },
};

const UpdatePostSentiment: ApiMutationConfig<
  ApiResponse<void>,
  {
    societyId: string;
    discussionForumId: string;
    forumPostId: string;
  },
  sentimentInput
> = {
  apiPath:
    "api/v1/society/{societyId}/forum/{discussionForumId}/posts/{forumPostId}/sentiment",
  method: "POST",
  invalidates: [
    {
      baseQueryKey: GetConferenceEventDiscussionForum.queryKey.baseQueryKey,
      exact: false,
    },
    {
      baseQueryKey: GetConferenceDiscussionForum.queryKey.baseQueryKey,
      exact: false,
    },
  ],
};

const PostForumMessage: ApiMutationConfig<
  ApiResponse<void>,
  {
    societyId: string;
    discussionForumId: string;
  },
  forumPostInput
> = {
  apiPath: "/api/v1/society/{societyId}/forum/{discussionForumId}/posts",
  method: "POST",
  invalidates: [
    {
      baseQueryKey: GetConferenceEventDiscussionForum.queryKey.baseQueryKey,
      exact: false,
    },
    {
      baseQueryKey: GetConferenceDiscussionForum.queryKey.baseQueryKey,
      exact: false,
    },
  ],
};

export {
  GetConferenceEventDiscussionForum,
  UpdatePostSentiment,
  PostForumMessage,
  GetConferenceDiscussionForum,
};
